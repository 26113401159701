import React from 'react';
import PropTypes from 'prop-types';
import { TitleDate, ContentBlock } from '@Components/customers/customer-styles';
import CustomerBookingEvent from '@Components/customers/customer-detail/bookings/customer-booking-event';
import { groupBookingsFuture } from '@Utils/customer-util';
import { colors } from '@Components/ui/styled/variables';
import { getTimeByFormat } from '@Utils/time-util';
import { txt } from '@Utils/i18n-util';
import msg from './customer-future-bookings.msg';

const { customerTitleRed: titleBookingRed, customerTitleGreen: titleBookingGreen } = colors;

const CustomerFutureBookings = ({
  items = [], onSelectEvent, selectedEventId, isSelectable = false
}) => {
  const {
    today, tomorrow, dayAfterTomorrow, thisWeek, nextWeek, months
  } = groupBookingsFuture(items);

  const renderEventsBlock = (events, title, color = 'inherit') => (
    <>
      <TitleDate color={color}>{title}</TitleDate>
      <ContentBlock>
        {events.map((event) => (
          <div key={`${title}_${event?.id}`}>
            <CustomerBookingEvent
              isSelectable={isSelectable}
              selectedEventId={selectedEventId}
              onSelectEvent={onSelectEvent}
              {...event}
            />
          </div>
        ))}
      </ContentBlock>
    </>
  );

  return (
    <>
      {today.length > 0 && renderEventsBlock(today, txt(msg.lblToday), titleBookingRed)}
      {tomorrow.length > 0 && renderEventsBlock(tomorrow, txt(msg.lblTomorrow), titleBookingRed)}
      {dayAfterTomorrow.length > 0 && renderEventsBlock(dayAfterTomorrow, txt(msg.lblAfterTomorrow), titleBookingGreen)}
      {thisWeek.length > 0 && renderEventsBlock(thisWeek, txt(msg.lblThisWeek), titleBookingGreen)}
      {nextWeek.length > 0 && renderEventsBlock(nextWeek, txt(msg.lblNextWeek), titleBookingGreen)}
      {months.length > 0 && months.reverse()
        .map(([date, groupedEvents]) => (
          <div key={date}>
            {renderEventsBlock(groupedEvents, getTimeByFormat(date), titleBookingGreen)}
          </div>
        ))}
    </>
  );
};

CustomerFutureBookings.propTypes = {
  items: PropTypes.array,
  handlerOnClick: PropTypes.func
};

export default CustomerFutureBookings;
