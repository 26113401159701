import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { colors } from '@Components/ui/styled/variables';

const EventCardStyled = styled.div`
  white-space: pre-line;
  cursor: ${({ isSelectable }) => isSelectable && 'pointer'};
  display: flex;
  p {
    font-weight: 600;
    margin-bottom: 0;
  };
  p:first-child {
    margin-top: -1px;
  };
`;

const EventCardBody = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 10px;
  color: ${colors.darkGray};
  background-color: ${p => p.bgColor || colors.softGray20};
  border-width: 1px 1px 1px 5px;
  border-style: solid;
  border-left-color: ${p => p.borderColor || colors.softGray20};
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  margin-bottom: ${({ time }) => time ? '3px' : '10px'};
  font-size: 13px;
  > i {
    padding-top: 1px;
    padding-right: 8px;
    font-size: 15px;
    color: ${p => p?.style?.color || '#000'};
  };
  div {
    flex: 1;
  }
  ${({ isSelected }) => isSelected && css`
    border-width: 1px 1px 1px 5px;
    border-style: solid;
    border-top-color: ${p => p.borderColor};
    border-right-color: ${p => p.borderColor};
    border-bottom-color: ${p => p.borderColor};
  `};
`;

const TimeStyled = styled.div`
  font-size: 12px;
  text-align: right;
  color: ${colors.darkGray40};
`;

const EventCard = ({
  time,
  bgColor,
  borderColor,
  icon,
  children,
  isSelected,
  isSelectable,
  handler
}) => (
  <EventCardStyled isSelectable={isSelectable} onClick={isSelectable ? handler : null}>
    <EventCardBody
      borderColor={borderColor}
      time={time}
      bgColor={bgColor}
      isSelected={isSelected}
    >
      {icon && <i className={icon} />}
      <div>
        {children}
      </div>
    </EventCardBody>
    {time && <TimeStyled>{time}</TimeStyled>}
  </EventCardStyled>
);

EventCard.propTypes = {
  time: PropTypes.string,
  color: PropTypes.string
};

export default EventCard;
