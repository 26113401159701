import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateBooking } from '@State/bkf/actions';
import { saveCustomerComment } from '@State/customer-actions';
import { getSectionUrl, navigate } from '@Utils/navigate';
import { changeBookingAttribute, changeBookingStatus } from '@State/booking-actions';
import { queueBookingPayment } from '@State/pos-actions';
import Loader from '@Components/ui/loader';
import Panel from '@Components/ui/styled/panel';
import Button from '@Components/ui/button';
import { dataURLtoFile, getBookingDataWithService, getCustomer, getStatus } from './ongoing-helpers';
import WebcamModal from './webcam-modal';
import ConfirmShowModal from './confirm-show-modal';
import OngoingPopover from './ongoing-popover';
import OngoingExtendPopover from './ongoing-extend-popover';

const OngoingBooking = ({ booking, time, routeParams }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [showWebcam, setShowWebcam] = useState(false);
  const [confirmShow, setConfirmShow] = useState(false);

  const { icon, background, status } = useMemo(() => getStatus(booking), [booking, time]);

  const customer = useMemo(() => getCustomer(booking), [booking]);

  const onSendToPos = (addServiceId) => {
    dispatch(queueBookingPayment({
      bookingId: booking.id,
      customer,
      attributes: {
        returnUrl: getSectionUrl(routeParams, 'ongoing'),
        addServiceId
      }
    }));
    setTimeout(() => navigate(getSectionUrl(routeParams, 'pos')), 0);
  };

  const onSetShow = () => {
    setConfirmShow(false);
    return dispatch(changeBookingStatus({
      bookingId: booking.id,
      customerIds: [customer.customerId],
      status: 'Show'
    }));
  };

  const onAddImage = (image) => {
    setLoading(true);
    setShowWebcam(false);
    return dispatch(saveCustomerComment(
      customer.customerId,
      'Bild tagen med webbkamera',
      [dataURLtoFile(image, `webcam-image-${customer.customerId}.jpg`)],
      false
    ))
      .then((log) => dispatch(changeBookingAttribute({
        bookingId: booking.id,
        customerIds: [customer.customerId],
        attributes: { imageUrl: log.files[0].url }
      })))
      .finally(() => setLoading(false));
  };

  const onExtend = (service) => {
    return dispatch(updateBooking(getBookingDataWithService(booking, service)))
      .then(() => onSendToPos(service.id));
  };

  const hasSales = booking.sales?.length > 0;
  const hasImage = !!customer?.bookingAttributes.imageUrl;
  const isShow = booking.status === 'Show';
  const isNoShow = booking.status === 'NoShow';
  const hasStatus = isShow || isNoShow;
  const isOngoing = !hasStatus && hasImage && hasSales;
  const slots = booking.resources.length;

  return (
    <Panel small bgColor={background} key={booking.id}>
      {loading && <Loader />}
      <div className="ongoing-booking">
        <div className="ongoing-booking-content">
          <div className="text-muted pull-right">
            {slots === 1 ? '1 plats' : `${slots} platser`}
          </div>
          <h4 className="mb1">
            {customer.name}
          </h4>
          <p>
            <strong>
              {booking.startTime.format('HH:mm')}{' - '}
              {booking.endTime.format('HH:mm')}
              <span className="text-muted ml1">{status}</span>
            </strong><br />
            {booking.customFields?.map(({ value }) => value).filter(v => v).join(', ')}
          </p>
        </div>
        <div className="ongoing-booking-footer">
          <div>
            {isOngoing && (
              <span className="mr2">
                <i className={icon} />
                <strong>Pågår</strong>
              </span>
            )}
            {isNoShow && (
              <span className="mr2">
                <i className={icon} />
                <strong>No show</strong>
              </span>
            )}
            {!hasStatus && !isOngoing && (
              <span className="mr2">
                {hasImage ? (
                  <span>
                    <i className="fa fa-check-circle text-success mr1" />
                    <strong>Bild</strong>
                  </span>
                ) : (
                  <Button tiny white onClick={() => setShowWebcam(true)}>Ta bild</Button>
                )}
              </span>
            )}
            {!hasStatus && hasImage && !hasSales && (
              <span className="mr2">
                <Button tiny primary onClick={() => onSendToPos()}>Ta betalt</Button>
              </span>
            )}
            {isOngoing && (
              <span className="mr2">
                <OngoingExtendPopover
                  booking={booking}
                  onSubmit={onExtend}
                />
              </span>
            )}
            {isOngoing && (
              <Button tiny primary onClick={() => setConfirmShow(true)}>Avsluta</Button>
            )}
          </div>
          <OngoingPopover id={booking.id} routeParams={routeParams} />
        </div>
        {showWebcam && (
          <WebcamModal
            onClose={() => setShowWebcam(false)}
            onSubmit={onAddImage}
          />
        )}
        {confirmShow && (
          <ConfirmShowModal
            customer={customer}
            onClose={() => setConfirmShow(false)}
            onConfirm={onSetShow}
          />
        )}
      </div>
    </Panel>
  );
};

export default OngoingBooking;
