import React, { Component } from 'react';
import { connect } from 'react-redux';
import { txt } from '@Utils/i18n-util';
import { classes } from '@Components/ui/utils';
import { getAllRulesFulfilled } from '@State/calendar-selectors';
import msg from './booking-resource.msg';

class BookingResource extends Component {
  componentDidMount() {
    this.props.resources.forEach((resource) => {
      if (resource.isNew) {
        // Remove class after animation is done to prevent repeated animations
        this.resetResource = setTimeout(() => {
          this.props.onResetNewResource(resource);
        }, 3000);
      }
    });
  }

  componentWillUnmount() {
    if (this.resetResource) {
      clearTimeout(this.resetResource);
    }
  }

  handleClick = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    this.props.onSearchStart();
  };

  render() {
    const { resources, status, allRulesFulfilled } = this.props;
    const multiResource = resources.size > 1;
    const isCancelled = status === 'Cancelled';
    const className = classes({
      'booking-form-block': true,
      'one-line': true,
      cancelled: isCancelled
    });

    return (
      <div className={className} onClick={!isCancelled ? this.handleClick : null}>
        {!isCancelled && (
          <div className="block-buttons" onClick={ev => ev.stopPropagation()}>
            <button className="btn-label" tabIndex={-1} onClick={this.handleClick}>
              {multiResource ? txt(msg.btnChange) : txt(msg.btnAdd)}
            </button>
          </div>
        )}
        <div className="booking-form-resources">
          {resources.valueSeq().sortBy(r => !r.primary).map(({ id, name, primary, isNew }) => {
            const resourceClass = classes({
              resource: true, primary, highlight: isNew
            });
            return (
              <div key={id} className={resourceClass}>{name}</div>
            );
          })}
        </div>
        {!allRulesFulfilled && (
          <div className="missing-resources">
            <i className="fa fa-exclamation-circle" /> Resurser saknas för valda tjänster
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { bkf } = state;

  return {
    resources: bkf.get('resources'),
    allRulesFulfilled: getAllRulesFulfilled(state)
  };
};

export default connect(mapStateToProps)(BookingResource);
