import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getIsVersionMismatch, getLocationName, getTrialStatus } from '@State/selectors';
import { isMobile } from '@Utils/browser-util';

const intercomAppId = 'ycy80csi';

const hasIntercom = () => typeof window.Intercom === 'function';

const Chat = ({ location, match }) => {
  const { org, loc } = match.params;
  const trialStatus = useSelector(getTrialStatus);
  const locationName = useSelector(getLocationName);
  const authState = useSelector(state => state.authState);
  const chatEnabled = useSelector(state => !!state.locationFeatures.get('EnableSupportChat'));
  const isVersionMismatch = useSelector(getIsVersionMismatch);
  const theme = useSelector(state => state.appState.get('theme'));
  const showChat = chatEnabled && !isVersionMismatch && theme !== 'yabie';

  useEffect(() => {
    if (!hasIntercom() && !isMobile() && showChat) {
      const script = document.createElement('script');
      script.src = `https://widget.intercom.io/widget/${intercomAppId}`;
      script.async = true;
      script.onload = initialize;
      document.head.appendChild(script);
    } else if (hasIntercom() && !showChat) {
      window.Intercom('shutdown');
    }
  }, [showChat]);

  useEffect(() => {
    if (hasIntercom()) {
      window.Intercom('update', getSettings());
    }
  }, [authState, locationName]);

  useEffect(() => {
    if (hasIntercom()) {
      window.Intercom('update');
    }
  }, [location.pathname]);

  const getSettings = () => {
    return {
      user_id: authState.get('loginId'),
      name: authState.get('profileName'),
      email: authState.get('username'),
      company: {
        id: `${org}/${loc}`,
        name: locationName,
        Trial: trialStatus === 'Trial'
      }
    };
  };

  const initialize = () => {
    if (hasIntercom()) {
      window.Intercom('boot', { app_id: intercomAppId, ...getSettings() });
    }
  };

  return null;
};

export default Chat;
