import React from 'react';
import CustomerVehicle from '@Components/customers/customer-detail/vehicles/customer-vehicle';
import { TitleDate } from '@Components/customers/customer-styles';

const CustomerVehicleList = ({
  items = [], onSelectEvent, selectedEventId, isSelectable = false
}) => {
  return (
    <div>
      <TitleDate>Fordon</TitleDate>
      {items.map((vehicle) => (
        <div key={vehicle.otherCustomerId}>
          <CustomerVehicle
            isSelectable={isSelectable}
            selectedEventId={selectedEventId}
            onSelectEvent={onSelectEvent}
            {...vehicle}
          />
        </div>
      ))}
    </div>
  );
};

export default CustomerVehicleList;
