import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change, Field, formValueSelector, initialize, reduxForm } from 'redux-form';
import useCustomer from '@Components/customers/useCustomer';
import { formatPhoneNumber } from '@Utils/phone-util';
import { email, required } from '@Utils/validation';
import TextInput from '@Components/inputs/text-input';
import FormButtonsFooter from '@Components/ui/form-buttons-footer';
import { getPosOrgInvoicePrefs } from '@State/pos-config-selectors';
import { getInvoiceUseFortnoxService } from '@Utils/invoice-util';
import { pos } from '@Utils/preference-keys';

export const defaultValues = {
  type: 'Company',
  country: 'SE',
  invoiceMethod: 'Post'
};

const formSelector = formValueSelector('invoice-customer-form');

const InvoiceCustomerForm = ({
  pristine, submitting, submitSucceeded, handleSubmit, onClose, form, posOrg
}) => {
  const dispatch = useDispatch();
  const { selectedCustomer } = useCustomer();
  const hasSelectedCustomer = !!selectedCustomer?.id;

  const invoicePrefs = useSelector(state => getPosOrgInvoicePrefs(state, { posOrg }));
  const useFortnoxService = invoicePrefs && getInvoiceUseFortnoxService(invoicePrefs);
  const useFortnox = invoicePrefs && invoicePrefs[pos.invoiceUseFortnox];

  const type = useSelector(state => formSelector(state, 'type'));
  const invoiceMethod = useSelector(state => formSelector(state, 'invoiceMethod'));
  const isEmail = invoiceMethod === 'Email';
  const isCompany = type === 'Company';
  const requireDetails = isCompany || useFortnoxService;

  const handleType = (ev, type) => {
    dispatch(change(form, 'type', type));
  };

  const copySelectedCustomer = (ev) => {
    ev.preventDefault();
    ev.target.blur();

    dispatch(initialize(form, {
      type: 'Private',
      customerName: selectedCustomer.name,
      orgOrPno: selectedCustomer.officialIdNo,
      phone: formatPhoneNumber(selectedCustomer.phoneNumber),
      email: selectedCustomer.email,
      address1: selectedCustomer.addressLine1,
      address2: selectedCustomer.addressLine2,
      postCode: selectedCustomer.postCode,
      area: selectedCustomer.area,
      country: selectedCustomer.country || 'SE',
      invoiceMethod: defaultValues.invoiceMethod
    }));
  };

  return (
    <form autoComplete="off" className="dialog" onSubmit={handleSubmit}>
      <div className="mb4">
        {hasSelectedCustomer && (
          <div className="pull-right">
            <a href="#" onClick={copySelectedCustomer}>Kopiera från kund</a>
          </div>
        )}
        <div className="radio-inline">
          <label>
            <input type="radio" checked={type === 'Company'} onChange={ev => handleType(ev, 'Company')} />
            Företag
          </label>
        </div> &nbsp;
        <div className="radio-inline">
          <label>
            <input type="radio" checked={type === 'Private'} onChange={ev => handleType(ev, 'Private')} />
            Privatperson
          </label>
        </div>
      </div>
      {isCompany ? (
        <>
          <div className="row">
            <div className="col-xs-6">
              <Field
                type="text"
                label="Företagsnamn"
                name="customerName"
                component={TextInput}
                validate={[required]}
              />
            </div>
            <div className="col-xs-6">
              <Field
                type="text"
                label="Er referens"
                name="customerRef"
                component={TextInput}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-6">
              <Field
                type="text"
                label="Organisationsnummer"
                name="orgOrPno"
                component={TextInput}
                validate={useFortnoxService ? [required] : null}
              />
            </div>
            <div className="col-xs-6">
              <Field
                type="text"
                label="VAT-nummer"
                name="companyVatNo"
                component={TextInput}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="row">
            <div className="col-xs-6">
              <Field
                type="text"
                label="Namn"
                name="customerName"
                component={TextInput}
                validate={[required]}
              />
            </div>
            <div className="col-xs-6">
              <Field
                type="text"
                label="Er referens"
                name="customerRef"
                component={TextInput}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-6">
              <Field
                type="text"
                label="Personnummer"
                name="orgOrPno"
                component={TextInput}
                validate={requireDetails ? [required] : null}
              />
            </div>
          </div>
        </>
      )}
      <div className="row">
        <div className="col-xs-6">
          <Field
            type="text"
            label="Telefon"
            name="phone"
            component={TextInput}
          />
        </div>
        <div className="col-xs-6">
          <Field
            type="text"
            label="E-post"
            name="email"
            component={TextInput}
            validate={isEmail ? [required, email] : [email]}
          />
        </div>
      </div>
      <br />
      <Field
        type="text"
        label="Adress"
        name="address1"
        component={TextInput}
        validate={requireDetails ? [required] : null}
      />
      <Field
        type="text"
        name="address2"
        component={TextInput}
      />
      <div className="row">
        <div className="col-xs-4">
          <Field
            type="text"
            label="Postnummer"
            name="postCode"
            component={TextInput}
            validate={requireDetails ? [required] : null}
          />
        </div>
        <div className="col-xs-8">
          <Field
            type="text"
            label="Postort"
            name="area"
            component={TextInput}
            validate={requireDetails ? [required] : null}
          />
        </div>
      </div>
      <div className="form-group">
        <label className="control-label">Leveranssätt</label>
        <div>
          <div className="radio-inline">
            <label>
              <Field name="invoiceMethod" value="Post" component="input" type="radio" />
              Brev
            </label>
          </div> &nbsp;
          <div className="radio-inline">
            <label>
              <Field name="invoiceMethod" value="Email" component="input" type="radio" />
              E-post
            </label>
          </div> &nbsp;
          {useFortnox && (
            <div className="radio-inline">
              <label>
                <Field name="invoiceMethod" value="EFaktura" component="input" type="radio" />
                E-faktura
              </label>
            </div>
          )}
        </div>
      </div>
      <FormButtonsFooter
        onClose={onClose}
        pristine={pristine}
        submitting={submitting}
        submitSucceeded={submitSucceeded}
        handleSubmit={handleSubmit}
        submitText="Spara mottagare"
        alwaysEnabled
      />
    </form>
  );
};

export default reduxForm({
  form: 'invoice-customer-form'
})(InvoiceCustomerForm);
