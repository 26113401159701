/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import { colors } from '@Components/ui/styled/variables';

export const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  margin: ${props => props.margin ? props.margin : props.phoneMode ? '0 3px' : '0 4px'};
  cursor: ${props => props.disabled ? 'default' : 'pointer'};

  .label-news {
    left: -10px;
    top: -7px;
  }
`;

export const DropdownContainer = styled.div`
  position: absolute;
  top: 100%;
  margin-top: 3px;
  right: 0;
`;

function getButtonColor({ active, disabled }) {
  if (active) {
    return '#fff';
  }
  if (disabled) {
    return colors.darkGray80;
  }
  return colors.textColor;
}

function getButtonBackground({ active, disabled }) {
  if (active) {
    return colors.primaryDark;
  }
  if (disabled) {
    return colors.softGray80;
  }
  return colors.softGray80;
}

export const ButtonStyle = styled.button`
  display: inline-block;
  margin: 0 auto;
  text-align: center;
  border-radius: 4px;
  font-size: ${props => props.phoneMode ? '11px' : '12px'};
  line-height: 10px;
  border: 0;
  padding: 6px;
  width: ${props => props.width || 'fit-content'};
  min-width: ${props => props.phoneMode ? '28px' : '45px'};
  height: 26px;
  outline: none;
  background: ${getButtonBackground};
  color: ${getButtonColor};
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};
  white-space: nowrap;

  &:active {
    background: ${colors.primaryDark};
    color: #fff;
  }
`;

export const Label = styled.span`
  font-size: 11px;
  font-weight: 500;
  user-select: none;
  white-space: nowrap;
  width: ${props => props.width || 'fit-content'};
  color: ${props => props.disabled ? colors.darkGray80 : colors.textColor};
  margin-top: 4px;
  text-align: center;

`;

const DropDown = styled.span`
  margin-left: 8px;

  @media (max-width: 360px) {
    display: none;
  }
`;

const styleWrapButtons = { position: 'relative' };

const TB = React.forwardRef(({
  style,
  label,
  buttonStyle,
  labelStyle,
  icon,
  iconStyle,
  dropDown,
  onClick,
  active,
  disabled,
  children,
  phoneMode,
  showNewsLabel
}, ref) => (
  <Wrapper disabled={disabled} phoneMode={phoneMode} ref={ref} style={style}>
    {showNewsLabel && <span className="label-news">NY</span>}
    <div style={styleWrapButtons}>
      <ButtonStyle
        as="button"
        style={buttonStyle}
        onClick={onClick}
        active={active}
        disabled={disabled}
        phoneMode={phoneMode}
      >
        <span>
          <i className={`fas ${icon}`} style={iconStyle} />
          {dropDown && (
            <DropDown>
              <i className="fas fa-chevron-down" />
            </DropDown>
          )}
        </span>
      </ButtonStyle>
      {children && (
        <DropdownContainer>
          {children}
        </DropdownContainer>
      )}
    </div>
    {!phoneMode && label && <Label disabled={disabled} onClick={onClick} style={labelStyle}>{label}</Label>}
  </Wrapper>
));

export default TB;
