import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { colors } from '@Components/ui/styled/variables';
import {
  resetPaymentProgress, cancelPayment, confirmPinBypass, confirmSignature, fetchPaymentStatus
} from '@State/pos-actions';
import { getPrinterProgress } from '@State/pos-selectors';
import { fetchTerminalsByVunitId, reconcileTerminal } from '@State/pos-config-actions';
import CurrencyUtil from '@Utils/currency-util';
import ModalDialog from '@Components/dialogs/modal-dialog';
import SetAmountModal from '@Components/pos/payment/set-amount-modal';
import ExternalPayment from '@Components/pos/payment/external-payment';
import TerminalDisconnectError from '@Components/pos/dialogs/terminal-disconnect-error';
import AlertWithIcon from '@Components/ui/alert-with-icon';
import {
  DialogTitle,
  DialogLabel,
  DialogButtonsVertical,
  DialogButton,
  DialogButtonsHorizontal,
  DialogCenterRowWrap
} from '@Components/dialogs/dialog-styles';
import { Text } from '@Components/ui/styled/main';
import CalcDisplay from '@Components/ui/calc-display';
import PaymentStatusUpdate from './payment-status-update';

const Message = styled(DialogLabel)`
  height: 60px;
  font-size: 18px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.textColor};
  white-space: pre-line;
`;

const SubText = styled.div`
  font-size: 13px;
  line-height: 1.5;
  text-align: ${({ left }) => left ? 'left' : 'center'};
  margin-bottom: ${({ error }) => error ? '0' : '15px'};
  color: ${({ error }) => error ? colors.textMuted : colors.textMuted};

  ul {
    margin: 0 10px;
    padding-left: 20px;
  }
`;

const Loader = styled.div`
  float: left;
  font-size: 20px;
  margin: 16px 0px 0 -32px;
  color: ${colors.textMuted};
`;

class CardPayment extends Component {
  state = {
    terminal: null,
    progress: false,
    error: null,
    showConfirmPinBypass: false,
    showConfirmSignature: false,
    amount: this.props.totalAmount,
    showSetAmount: false
  };

  componentDidMount() {
    const { posUnit } = this.props;
    this.props.fetchTerminals(posUnit.get('vunitId'));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      paymentStatus, posTerminals, isRejected, rejectionReason, txProgress, totalAmount
    } = nextProps;
    const { terminal, retry } = this.state;
    const paymentStatusChanged = this.props.paymentStatus !== paymentStatus;
    const isAwaitingConfirm = paymentStatus === 'AwaitingConfirm';

    if (paymentStatusChanged && isAwaitingConfirm) {
      this.setState({ showConfirmPinBypass: true });
    }
    if (!terminal && posTerminals) {
      this.initTerminal(posTerminals, txProgress);
    }
    if (!this.props.isRejected && isRejected && rejectionReason) {
      this.onError(rejectionReason);
    }
    if (this.props.isRejected && !isRejected && !retry) {
      this.onReset(false);
    }
    if (this.props.totalAmount !== totalAmount) {
      this.setState({ amount: totalAmount });
    }
  }

  initTerminal = (posTerminals, txProgress) => {
    const terminalId = txProgress?.get('terminalId');
    if (terminalId) {
      this.setState({
        terminal: posTerminals.find(t => t.get('id') === terminalId),
        progress: true
      });
    }
  };

  onReconcile = () => {
    const { posUnit } = this.props;
    const { terminal } = this.state;
    this.setState({ progress: true });
    this.props.reconcileTerminal(terminal.get('id'), posUnit.get('vunitId'))
      .then(() => this.onReset())
      .catch(() => this.setState({ progress: false }));
  };

  onReset = (resetGlobalState = true) => {
    if (resetGlobalState) {
      this.props.resetPaymentProgress();
    }
    this.setState({ terminal: null, error: null, progress: false });
  };

  onError = (error) => {
    this.setState({ error, progress: false });
  };

  onShowSetAmount = () => {
    this.setState({ showSetAmount: true });
  };

  onHideSetAmount = () => {
    this.setState({ showSetAmount: false });
  };

  onSetAmount = (amount) => {
    this.setState({ amount, showSetAmount: false });
  };

  onSelectTerminal = (terminal) => {
    this.setState({ terminal, progress: true, retry: false });
    return this.props.onSubmit(terminal.get('id'), this.state.amount)
      .catch(this.onError);
  };

  onRetry = () => {
    const { terminal, amount } = this.state;
    this.setState({ progress: true, retry: true });
    return this.props.onSubmit(terminal.get('id'), amount, true)
      .then(() => this.setState({ retry: false, error: null }))
      .catch(this.onError);
  };

  onConfirmPinBypass = (allow) => {
    this.setState({ showConfirmPinBypass: false });
    return this.props.confirmPinBypass(this.props.saleId, allow)
      .catch(this.onError);
  };

  onClose = () => {
    if (this.state.progress && !this.props.isRejected) {
      this.props.cancelPayment(this.props.saleId)
        .then(() => this.setState({ progress: false }));
    } else {
      this.props.onClose();
    }
  };

  getErrorMessage = (error) => {
    if (typeof error === 'string') {
      return error;
    }

    const { data } = error.response || {};
    const { code } = data || {};

    switch (code) {
      case 1001:
        return 'Terminalen är upptagen, försök igen senare';
      case 1002:
        return 'Terminalen är offline';
      case 1003:
        return 'Kunde inte ansluta till terminalen';
      default:
        return 'Ett oväntat fel uppstod, vänligen försök igen';
    }
  }

  getPaymentMessage = () => {
    const { terminal } = this.state;
    const { txProgress, paymentMessage } = this.props;
    const terminalProgress = terminal && txProgress && txProgress.get('terminalId') === terminal.get('id');

    return !paymentMessage && terminalProgress
      ? terminal.getIn(['terminalStatus', 'displayText'])
      : paymentMessage;
  };

  getDisplayMessage = (paymentMessage) => {
    const { printerProgress, isRejected, reconcileRequired } = this.props;

    if (printerProgress) {
      return 'Skriver ut kvitto...';
    }
    if (isRejected && !paymentMessage) {
      return reconcileRequired ? 'Dagsavslut krävs' : 'Terminalfel';
    }
    return paymentMessage || 'Väntar på terminal...';
  };

  render() {
    const {
      terminal, progress, error, showConfirmPinBypass, amount, showSetAmount
    } = this.state;
    const {
      totalAmount, isRefund, isRejected, printerProgress, posTerminals, reconcileRequired
    } = this.props;
    const paymentMessage = this.getPaymentMessage();
    const showLoader = (!paymentMessage && !isRejected) || printerProgress;
    const showTerminals = !progress && !terminal && posTerminals;
    const noTerminals = showTerminals && posTerminals.isEmpty();

    if (showConfirmPinBypass) {
      return (
        <ModalDialog closeOnClickOutside={false}>
          <DialogTitle>Vill du godkänna signaturköp?</DialogTitle>
          <DialogButtonsHorizontal>
            <DialogButton confirm onClick={() => this.onConfirmPinBypass(true)}>Godkänn</DialogButton>
            <DialogButton deny onClick={() => this.onConfirmPinBypass(false)}>Neka</DialogButton>
          </DialogButtonsHorizontal>
        </ModalDialog>
      );
    }

    if (noTerminals) {
      return (
        <ExternalPayment
          totalAmount={totalAmount}
          onSubmit={this.props.onSubmitExternal}
          onClose={this.onClose}
          title="Betala med kort"
          text="Slå in beloppet i kortterminalen och genomför betalning"
        />
      );
    }

    if (showSetAmount) {
      return (
        <SetAmountModal
          totalAmount={totalAmount}
          onSubmit={this.onSetAmount}
          onClose={this.onHideSetAmount}
        />
      );
    }

    if (error === 'TERMINAL_DISCONNECTED') {
      return (
        <TerminalDisconnectError
          onRetry={this.onRetry}
        />
      );
    }

    const title = showTerminals
      ? `${isRefund ? 'Återköp till kort' : 'Betala med kort'}`
      : `${isRefund ? 'Återköp' : 'Att betala'} ${CurrencyUtil.accountCurrency(amount, 2)}`;

    return (
      <ModalDialog
        title={title}
        onClose={this.onClose}
        closeOnClickOutside={false}
      >
        {progress && terminal && <PaymentStatusUpdate terminalId={terminal.get('vendorId')} {...this.props} />}
        {progress && paymentMessage && !printerProgress && !isRejected && (
          <div className="text-center">
            <SubText>Be kund följa instruktioner i kortterminalen</SubText>
          </div>
        )}
        {showTerminals ? (
          <>
            <DialogCenterRowWrap>
              <Text marginBottom fs={30}>{CurrencyUtil.accountCurrency(amount, 2)}</Text>
            </DialogCenterRowWrap>
            <DialogButtonsVertical>
              {posTerminals.map((terminal, index) => (
                <DialogButton primary key={index} onClick={() => this.onSelectTerminal(terminal)}>
                  {posTerminals.size > 1 ? terminal.get('name') : 'Skicka till terminal'}
                </DialogButton>
              ))}
              {!isRefund && (
                <DialogButton gray onClick={this.onShowSetAmount}>Dela upp betalning</DialogButton>
              )}
            </DialogButtonsVertical>
          </>
        ) : (
          <>
            <CalcDisplay large align="center">
              {showLoader && <Loader><i className="fas fa-spin fa-circle-notch" /></Loader>}
              <Message>{this.getDisplayMessage(paymentMessage)}</Message>
            </CalcDisplay>
            {error && !paymentMessage && !reconcileRequired && (
              <>
                <SubText error>{this.getErrorMessage(error)}</SubText><br />
                <AlertWithIcon info icon="fa fa-info-circle">
                  <strong>Vid återkommande terminalfel:</strong><br />
                  Starta om betalterminalen genom att hålla in den gula Clear-knappen och punkt/komma-knappen samtidigt.<br /><br />
                  Om problemet kvarstår efter omstart, vänligen kontakta support.
                </AlertWithIcon>
              </>
            )}
            {error && !paymentMessage && reconcileRequired && (
              <AlertWithIcon info icon="fa fa-info-circle">
                Betalterminalen kräver ett dagsavslut innan en betalning kan genomföras.<br /><br />
                Du kan göra dagsavslut genom att trycka på knappen nedan, eller genom att göra ett manuellt dagsavslut på terminalen genom att trycka Menu och därefter på Dagsavslut.
              </AlertWithIcon>
            )}
            {isRejected && (
              <DialogButtonsVertical>
                {reconcileRequired ? (
                  <DialogButton primary disabled={progress} onClick={() => this.onReconcile()}>
                    Gör dagsavslut
                  </DialogButton>
                ) : (
                  <DialogButton primary disabled={printerProgress} onClick={() => this.onReset()}>
                    Försök igen
                  </DialogButton>
                )}
              </DialogButtonsVertical>
            )}
          </>
        )}
      </ModalDialog>
    );
  }
}

const mapStateToProps = (state) => {
  const { pos, posSale, posTerminals } = state;

  return {
    posUnit: pos.get('posUnit'),
    paymentStatus: posSale.get('paymentStatus'),
    paymentMessage: posSale.get('paymentMessage'),
    printerProgress: getPrinterProgress(state),
    isRejected: posSale.get('transactionStatus') === 'Rejected',
    rejectionReason: posSale.get('rejectionReason'),
    reconcileRequired: posSale.get('responseCode') === 87,
    posTerminals
  };
};

const mapDispatchToProps = dispatch => ({
  fetchTerminals: id => dispatch(fetchTerminalsByVunitId(id)),
  resetPaymentProgress: () => dispatch(resetPaymentProgress()),
  cancelPayment: saleId => dispatch(cancelPayment(saleId)),
  confirmPinBypass: (saleId, allow) => dispatch(confirmPinBypass(saleId, allow)),
  confirmSignature: (saleId, allow) => dispatch(confirmSignature(saleId, allow)),
  fetchPaymentStatus: (saleId, terminalId) => dispatch(fetchPaymentStatus(saleId, terminalId)),
  reconcileTerminal: (terminalId, posUnitId) => dispatch(reconcileTerminal(terminalId, posUnitId))
});

export default connect(mapStateToProps, mapDispatchToProps)(CardPayment);
