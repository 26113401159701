import moment from 'moment';
import React, { useEffect, useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ScheduleCreateModal from '@Components/schedule/schedule-create-modal';
import ScheduleWeeksToggle from '@Components/schedule/schedule-weeks-toggle';
import ScheduleDay from '@Components/schedule/schedule-day';

import { saveSchedule, createSchedule } from '@State/schedule-actions';
import { getCurrentSchedule, getUpdatedSchedule } from '@Utils/schedule-utils';
import { classes } from '@Components/ui/utils';
import ScheduleHeaderSlider from '@Components/schedule/schedule-header-slider';
import Button from '@Components/ui/button';
import AlertWithIcon from '@Components/ui/alert-with-icon';

const Schedule = ({ resourceId, schedules }) => {
  const dispatch = useDispatch();
  const newScheduleKey = useSelector(state => state.adminSchedules.get('newScheduleKey'));
  const customScheduleUsed = useSelector(state => state.resourcesById.some(r => !r.useLocationSchedule));

  if (schedules && schedules.length > 1) {
    schedules.sort((a, b) => new Date(a.validFrom).getTime() - new Date(b.validFrom).getTime());
  }

  const [currentSchedule, setCurrentSchedule] = useState();
  const [currentWeek, setCurrentWeek] = useState(1);
  const [isShowDuplicateModal, setIsShowDuplicateModal] = useState(false);

  useEffect(() => {
    if (newScheduleKey) {
      handleSetSchedule(newScheduleKey);
    } else if (schedules?.length > 0 && !schedules.find(s => s.key === currentSchedule?.key)) {
      const schedule = getCurrentSchedule(schedules);
      setScheduleAndWeek(schedule);
    }
  }, [schedules, newScheduleKey]);

  const handleSetSchedule = (key) => {
    if (schedules) {
      const schedule = schedules.find((schedule) => schedule.key === key);
      setScheduleAndWeek(schedule);
    }
  };

  const setScheduleAndWeek = (schedule) => {
    setCurrentSchedule(schedule);

    if (schedule.weeks < currentWeek) {
      setCurrentWeek(1);
    }
  };

  const getCurrentWeekDays = () => {
    if (currentSchedule && currentSchedule.blocks) {
      // creating a new array for week
      const blocks = currentSchedule.weeks > 1
        ? currentSchedule.blocks.filter((b) => b.week === currentWeek)
        : currentSchedule.blocks;

      return Array.from({ length: 7 }, (x, i) => {
        const isoWeekday = moment().weekday(i).isoWeekday();
        return {
          day: isoWeekday,
          week: currentWeek,
          blocks: blocks.filter((block) => block.day === isoWeekday)
        };
      });
    }
    return [];
  };

  const onSaveSchedule = (update) => {
    const schedule = getUpdatedSchedule(currentSchedule, update);
    onUpdateSchedule(schedule);
  };

  const onUpdateSchedule = (schedule) => {
    const data = { resourceId, ...schedule };
    return dispatch(saveSchedule(data)).then(() => {
      setCurrentSchedule({ ...schedule });
    });
  };

  const onDeleteScheduleDay = (block, day, week, isRemoveAllDay) => {
    if (isRemoveAllDay) {
      currentSchedule.blocks = currentSchedule.blocks.filter((b) => b.week === week && b.day === day ? null : b);
    } else {
      currentSchedule.blocks = currentSchedule.blocks
        .filter((b) => b.start === block.start && b.end === block.end && b.week === week && b.day === day ? null : b);
    }

    return onUpdateSchedule(currentSchedule);
  };

  const handleToggleModalCopySchedule = () => setIsShowDuplicateModal(!isShowDuplicateModal);

  const handleCreateSchedule = (data) => dispatch(createSchedule({ ...data }, resourceId))
    .then(() => handleToggleModalCopySchedule());

  const days = getCurrentWeekDays();
  const isRenderCalendarItem = schedules && schedules.length > 0 && currentSchedule;
  const isShowWeeks = currentSchedule && currentSchedule.weeks > 1;

  const classListScheduleTable = classes({
    'schedule-table': true,
    'schedule-table-weeks': isShowWeeks,
    'schedule-table-weeks-first-tab-active': currentWeek === 1 && currentSchedule && currentSchedule.weeks > 1
  });

  const classListScheduleTableWrap = classes({
    'schedule-table-disabled': currentSchedule?.isPast
  });

  const nowSchedule = getCurrentSchedule(schedules);
  return (
    <>
      {isShowDuplicateModal && (
      <ScheduleCreateModal
        currentSchedule={currentSchedule}
        onClose={handleToggleModalCopySchedule}
        onSubmit={handleCreateSchedule}
      />
      )}
      {customScheduleUsed && !resourceId && (
        <div className="schedule-alert-container">
          <AlertWithIcon warning icon="fa fa-exclamation-triangle">
            En eller flera resurser har individuella scheman. Välj resurs i listan för att ändra schema för respektive resurs.
          </AlertWithIcon>
        </div>
      )}
      {isRenderCalendarItem
        && (
        <ScheduleHeaderSlider
          resourceId={resourceId}
          currentSchedule={currentSchedule}
          schedules={schedules}
          nowSchedule={nowSchedule}
          handleToggleModalCopySchedule={handleToggleModalCopySchedule}
          handleSetSchedule={handleSetSchedule}
        />
        )}
      {currentSchedule
        && (
        <>
          {isShowWeeks
            && (
            <ScheduleWeeksToggle
              currentWeek={currentWeek}
              setCurrentWeek={setCurrentWeek}
              weeks={currentSchedule.weeks}
            />
            )}
          <div className={classListScheduleTable}>
            {currentSchedule?.isPast
            && (
            <div className="alert alert-warning">
              Passerat schema går ej att ändra. Duplicera schemat om du vill skapa ett nytt med det här schemat som grund.
              <Button bold yellow onClick={handleToggleModalCopySchedule}>Duplicera...</Button>
            </div>
            )}
            <div className={classListScheduleTableWrap}>

              {days.map((day, index) => (
                <ScheduleDay
                  key={day.day + index + day.week}
                  data={day}
                  onDelete={onDeleteScheduleDay}
                  onSave={onSaveSchedule}
                />
              ))}
            </div>
          </div>
        </>
        )}
    </>
  );
};

export default memo(Schedule);
