import moment from 'moment';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isContractOngoing } from '@Utils/sysadmin-util';
import { colors } from '@Components/ui/styled/variables';
import Label from '@Components/ui/label';

const LabelContainer = styled.div`
  text-align: right;

  div + div {
    margin-left: 5px;
  }
`;

const LabelText = styled.div`
  color: ${p => p.isGreen ? colors.success : colors.textMuted};
  line-height: 18px;
  text-align: right;
`;

const AccountsListItemSubHeader = ({
  color, text, labelText, subLabel, subLabelColor,
  isLabelColorGreen, sfLeadCreatedTs, trial,
  contractStartDate, contractEndDate, logCount
}) => {
  const contractOngoing = isContractOngoing(contractStartDate, contractEndDate);
  return (
    <>
      <LabelContainer>
        {sfLeadCreatedTs && trial && <Label bgColor={colors.labelOrange}>SF</Label>}
        {subLabel && <Label bgColor={subLabelColor}>{subLabel}</Label>}
        {text && <Label bgColor={color}>{text}</Label>}
        {contractOngoing && <Label bgColor={colors.darkGray80}>Bundet</Label>}
        {logCount > 0 && <Label bgColor={colors.warning} title="Loggar finns">!</Label>}
      </LabelContainer>
      {labelText && <LabelText isGreen={isLabelColorGreen}>{labelText}</LabelText>}
      {!labelText && contractOngoing && (
        <LabelText>{moment(contractEndDate).format('YYYY-MM-DD')}</LabelText>
      )}
    </>
  );
};

AccountsListItemSubHeader.propTypes = {
  color: PropTypes.string,
  text: PropTypes.string,
  labelText: PropTypes.string,
  subLabel: PropTypes.string,
  subLabelColor: PropTypes.string,
  isLabelColorGreen: PropTypes.bool
};

export default memo(AccountsListItemSubHeader);
