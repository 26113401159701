import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import ButtonModalForm from '@Components/dialogs/button-modal-form';
import HookFormInput from '@Components/inputs/hook-form-input';
import DialogAlert from '@Components/dialogs/dialog-alert';
import { importSupplierCatalog, supplierCatalogUrl } from '@State/import-actions';
import FormButtonsFooter from '@Components/ui/form-buttons-footer';
import FileUploadField from '@Components/ui/file-upload-field';

const ImportSupplierCatalog = ({ location }) => {
  const dispatch = useDispatch();
  const [importSuccess, setImportSuccess] = useState(null);
  const methods = useForm();

  const onImportCustomers = (params) => {
    return dispatch(importSupplierCatalog(params))
      .then((data) => {
        setImportSuccess(data);
        methods.reset();
      });
  };

  const { added, updated } = importSuccess || {};
  const total = (added || 0) + (updated || 0);

  return (
    <>
      {importSuccess && (
        <DialogAlert
          title=""
          success={total > 0}
          warning={!total}
          icon={total > 0
            ? 'fa fa-check-circle'
            : 'fa fa-exclamation-triangle'}
          text={(
            <div>
              {added} produkter lades till och {updated} uppdaterades.
            </div>
          )}
          onClose={() => setImportSuccess(null)}
          closeButtonText="Stäng"
        />
      )}
      <ButtonModalForm
        dialogProps={{
          title: 'Importera leverantörslista',
          contentSize: 'large'
        }}
        onClose={() => methods.reset()}
        onSubmit={onImportCustomers}
        btnText="Importera leverantörslista"
      >
        {({ onClose, onSubmit, loading }) => (
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <HookFormInput
                id="supplier"
                type="select"
                label="Välj leverantör"
                initialValue={supplierCatalogUrl.baldacci}
                options={Object.keys(supplierCatalogUrl).map(key => ({
                  value: key, title: supplierCatalogUrl[key]
                }))}
                registerOptions={{ required: true }}
              />
              <FileUploadField
                value={methods.watch('file')}
                onChange={file => methods.setValue('file', file)}
              />
              <FormButtonsFooter
                submitText="Importera"
                submitting={loading}
                onClose={onClose}
              />
            </form>
          </FormProvider>
        )}
      </ButtonModalForm>
    </>
  );
};

export default ImportSupplierCatalog;
