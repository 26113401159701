import React, { memo, useCallback, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';

import useCustomer from '@Components/customers/useCustomer';
import { customerClearForm } from '@State/customer-actions';
import { getCustomerSalesUrl, getCustomerBookingUrl, navigate, getCustomerGiftCardsUrl, getCustomerVehiclesUrl, getCustomerEmployeesUrl, getCustomersUrl } from '@Utils/navigate';
import { getCustomerTabSections, getCustomerTypeTabSections } from '@Components/customers/customer-detail/selectors';

import NavTabsLink from '@Components/ui/nav-tabs-link';
import CustomerDetailOverview from '@Components/customers/customer-detail/overview/customer-detail-overview';
import CustomerBookings from '@Components/customers/customer-detail/bookings/customer-bookings';
import CustomerDetailLogs from '@Components/customers/customer-detail/logs/customer-detail-logs';
import CustomerDetailSales from '@Components/customers/customer-detail/sales/customer-detail-sales';
import CustomerGiftCards from '@Components/customers/customer-detail/gift-cards/customer-gift-cards';
import CustomerDetailHeader from '@Components/customers/customer-detail/customer-detail-header';
import CustomerVehicles from './vehicles/customer-vehicles';
import CustomerEmployees from './employees/customer-employees';
import CustomerVehicleInfo from './vehicle-info/customer-vehicle-info';

const url = '/:org/:loc/:section(customers)/:id?/';

const CustomerDetail = ({ routeParams }) => {
  const { customerId, selectedCustomer, isLoading } = useCustomer();
  const sections = useSelector(state => getCustomerTabSections(state));
  const customerSections = getCustomerTypeTabSections(sections, selectedCustomer);

  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(customerClearForm());
    };
  }, []);

  const onSelectBooking = useCallback((id) => {
    if (+routeParams.tabId !== +id) {
      navigate(getCustomerBookingUrl(routeParams, id));
    }
  }, [routeParams.tabId]);

  const onSelectSales = (id) => {
    if (+routeParams.tabId !== +id) {
      navigate(getCustomerSalesUrl(routeParams, id));
    }
  };

  const onSelectGiftCard = (id) => {
    if (+routeParams.tabId !== +id) {
      navigate(getCustomerGiftCardsUrl(routeParams, id));
    }
  };

  const onSelectEmployee = (id) => {
    if (+routeParams.tabId !== +id) {
      navigate(getCustomerEmployeesUrl(routeParams, id));
    }
  };

  const onSelectVehicle = (id) => {
    if (+routeParams.tabId !== +id) {
      navigate(getCustomerVehiclesUrl(routeParams, id));
    }
  };

  if (!customerSections.find(s => s.navId === routeParams.tab)) {
    return <Redirect to={getCustomersUrl(routeParams, routeParams.id)} />;
  }

  return (
    <div className="columns-body-wrapper customer-details">
      <CustomerDetailHeader
        customer={selectedCustomer}
        routeParams={routeParams}
      />
      <NavTabsLink
        sections={customerSections}
        subUrl="customers"
        className="no-margins"
        routeParams={routeParams}
      />
      <Switch>
        <Route
          path={`${url}overview`}
          render={() => (
            <CustomerDetailOverview
              isLoadingCustomer={isLoading}
              routeParams={routeParams}
              customerId={customerId}
              selectedCustomer={selectedCustomer}
            />
          )}
        />
        <Route
          path={`${url}bookings/:tabId?`}
          render={() => (
            <CustomerBookings
              routeParams={routeParams}
              onSelectBooking={onSelectBooking}
            />
          )}
        />
        <Route
          path={`${url}logs/:tabId?`}
          render={() => (
            <CustomerDetailLogs routeParams={routeParams} />
          )}
        />
        <Route
          path={`${url}sales/:tabId?`}
          render={() => (
            <CustomerDetailSales
              routeParams={routeParams}
              onSelectSales={onSelectSales}
            />
          )}
        />
        <Route
          path={`${url}gift-cards/:tabId?`}
          render={() => (
            <CustomerGiftCards
              routeParams={routeParams}
              onSelectGiftCard={onSelectGiftCard}
            />
          )}
        />
        <Route
          path={`${url}employees/:tabId?`}
          render={() => (
            <CustomerEmployees
              routeParams={routeParams}
              onSelectEmployee={onSelectEmployee}
            />
          )}
        />
        <Route
          path={`${url}vehicles/:tabId?`}
          render={() => (
            <CustomerVehicles
              routeParams={routeParams}
              onSelectVehicle={onSelectVehicle}
            />
          )}
        />
        <Route
          path={`${url}vehicle-info/:tabId?`}
          component={CustomerVehicleInfo}
        />
      </Switch>
    </div>
  );
};

export default memo(
  CustomerDetail,
  (prevProps, nextProps) => !!isEqual(prevProps.routeParams, nextProps.routeParams)
);
