import { colors } from '@Components/ui/styled/variables';

export const ReleaseNoteLabels = {
  Bugfix: 'Buggfix',
  Improvement: 'Förbättring',
  Feature: 'Ny funktion',
  Change: 'Ändring'
};

export function getReleaseNoteColor(label) {
  switch (label) {
    case 'Bugfix':
      return colors.danger;
    case 'Improvement':
      return colors.labelTurquoise;
    case 'Feature':
      return colors.labelGreen;
    case 'Change':
      return colors.primaryLight;
    default:
      return colors.softGray20;
  }
}

export function isNewFeature(label) {
  return label === 'Feature';
}
