/**
 * This method calculates the compensation time to be applied when week view is active and the week contains a day which needs to be adjusted for summer or winter time.
 *
 * @param isWeekView
 * @param day - a 'momentjs' object for which we should calculate the compensation time.
 * @returns {number} - compensation time in seconds
 */

import moment from 'moment';
import {
  DAY_WEEK_DATE_MONTH,
  DATE_PICKER_FORMAT,
  DAY_WEEK_DATE_MONTH_YEAR,
  DAY_WEEK_DATE_MONTH_YEAR_TIME,
  HOURS_MINUTES,
  MONTH_DAY_YEAR_TIME,
  DAY_MONTH_YEAR_TIME_FORMAT,
  MONTH_YEAR
} from '@Utils/time-constants';

export function calcWeekViewTimeOffsetForDay(day) {
  let compTime = 0;
  const msOnDay = moment(day).startOf('day').add(1, 'd') - moment(day).startOf('day');

  if (msOnDay === 90000000) {
    compTime = -(60 * 60);
  } else if (msOnDay < 86400000) {
    compTime = 60 * 60;
  }
  return compTime;
}

export function calcHeightFromMinutes({ gridScrollHeight, chipIndentPixels }, minutes, useIndent) {
  const minToPixelFactor = (gridScrollHeight / 24) / 60;
  return (minutes * minToPixelFactor) - (useIndent ? chipIndentPixels : 0);
}
/**
 * Calculates the time of day for a given grid y-coordinate on a given day
 *
 * @param gridProps - the current grid properties
 * @param ycoord - the y-coord (int)
 * @param day - a 'momentjs' object which represents the time of day for the passed in y-coord and day
 * @returns {*}
 */
export function calcTimeFromYCoord({ rowsPerHour, pixelsPerRow }, ycoord, day) {
  const minutesPerRow = 60 / rowsPerHour;
  const minuteOffset = Math.floor(ycoord / pixelsPerRow) * minutesPerRow;

  const compTime = calcWeekViewTimeOffsetForDay(day);

  return moment(day).startOf('day').add(minuteOffset, 'minutes').subtract(compTime, 'seconds');
}

export function calcTimeFromXYCoord(gridProps, xCoord, yCoord, day) {
  if (gridProps.isWeekView) {
    const columnIdx = Math.round(xCoord / gridProps.columnWidth);

    day = moment(day).weekday(columnIdx);
  }

  return calcTimeFromYCoord(gridProps, yCoord, day);
}

/**
 * Translates a string rep of a viewmode/viewdate, e.g. week/2015-39 to an array of days. e.g. ['2015-09-21', '2015-09-22','2015-09-23','2015-09-24']
 *
 * @param viewMode 'week' or 'day'
 * @param viewDate 'YYYY-GG' or 'YYYY-MM-DD'
 * @param format
 * @returns [] of day strings. ['2015-09-21', '2015-09-22','2015-09-23','2015-09-24']

 */
function viewDateToDays(viewMode, viewDate, format = DATE_PICKER_FORMAT) {
  if (viewMode === 'week') {
    const days = [];
    const startOfWeek = moment(viewDate, 'gggg-ww').startOf('week'); // will be 00:00

    for (let i = 0; i < 7; i++) {
      days.push(startOfWeek.format(format));
      startOfWeek.add(1, 'd');
    }
    return days;
  }
  return [{ viewDate }];
}

export function viewDateToMomentDays(viewMode, viewDate) {
  if (viewMode === 'week') {
    const days = [];
    const startOfWeek = moment(viewDate, 'gggg-ww').startOf('week'); // will be 00:00

    for (let i = 0; i < 7; i++) {
      days.push(moment(startOfWeek)); // Defensive copy
      startOfWeek.add(1, 'd');
    }
    return days;
  }
  return [moment(viewDate, DATE_PICKER_FORMAT)];
}

/**
 * Translate the string rep to start/end of the viewDate period
 *
 * @param viewMode 'week' or 'day'
 * @param viewDate 'YYYY-GG' or 'YYYY-MM-DD'
 * @returns {Object} - start and end of the current viewDate as momentjs objects
 */
export function viewDateToStartEnd(viewMode, viewDate) {
  if (viewMode === 'week') {
    const startOfWeek = moment(viewDate, 'gggg-ww').startOf('week');

    return {
      start: startOfWeek,
      end: moment(startOfWeek).add(7, 'd')
    };
  }
  const date = moment(viewDate, DATE_PICKER_FORMAT);

  return {
    start: date,
    end: moment(date).add(1, 'd')
  };
}

/**
 * Translate the date into a viewDate (e.g. 2015-39 for 'week' and 2015-10-10 for 'day')
 *
 * @param viewMode
 * @param date
 * @returns {*}
 */
export function viewDateFromDate(viewMode, date) {
  return viewMode === 'week' ? moment(date).format('gggg-ww') : getDate(date);
}

export function viewDateToViewDate(newViewMode, previousViewMode, previousViewDate) {
  if (newViewMode === 'day' && previousViewMode === 'week') {
    const previousWeek = viewDateToStartEnd(previousViewMode, previousViewDate);
    const today = moment();

    if (previousWeek.start.isBefore(today) && previousWeek.end.isAfter(today)) {
      // show today since the first day of the week is passed
      return viewDateFromDate(newViewMode, today);
    }

    // show first day of the previously selected week
    return viewDateFromDate(newViewMode, previousWeek.start);
  }

  return viewDateFromDate(newViewMode, viewDateToStartEnd(previousViewMode, previousViewDate).start);
}

export function scheduleBlockToMomentDates(block) {
  const TIME_FORMAT = `${DATE_PICKER_FORMAT} ${HOURS_MINUTES}`;
  return {
    dayStart: moment(block.day).startOf('day'),
    blStart: moment(`${block.day} ${block.start}`, TIME_FORMAT),
    blEnd: moment(`${block.day} ${block.end}`, TIME_FORMAT)
  };
}

/**
 * Set the date to another date without affecting the time
 *
 * @param dateTime, moment
 * @param newDate, moment
 */
export function setNewDate(dateTime, newDate) {
  return dateTime.year(newDate.year()).month(newDate.month()).date(newDate.date());
}

export const pixelMultiplier = (rowsPerHour) => {
  // if(rowsPerHour === 1) return 0.5;
  if (rowsPerHour < 3) return 1;
  if (rowsPerHour > 6) return 4;
  return 2;
};

export const getTimeByFormat = (value, format) => moment(value).format(format || MONTH_YEAR);

export const getFormattedTime = (value) => moment(value).format(DAY_MONTH_YEAR_TIME_FORMAT);
export const getFullTimeCreated = (created) => moment(created).format(MONTH_DAY_YEAR_TIME);
export const getShortDate = (date) => moment(date).format(DAY_WEEK_DATE_MONTH);
export const getTimeStaffCreated = (date) => moment(date).format(DAY_WEEK_DATE_MONTH_YEAR);
export const getTimeStaffCreatedTime = (date) => moment(date).format(DAY_WEEK_DATE_MONTH_YEAR_TIME);
export const isTheSameDay = (start, end) => moment(start).isSame(moment(end), 'day');
export const getTimeHours = (date) => moment(date).format(HOURS_MINUTES);
export const getMinutesDiff = (end = '', start = '') => moment(end, HOURS_MINUTES).diff(moment(start, HOURS_MINUTES), 'minutes');

// value = 09:00:00
// return 09:00
export const getTime = (value) => moment(value, HOURS_MINUTES).format(HOURS_MINUTES);
export const addTime = (value, time = 1) => moment(value, HOURS_MINUTES).add(time, 'hour').format(HOURS_MINUTES);

export const getCurrentTime = (format) => moment().format(format || HOURS_MINUTES);
export const getDate = (date) => moment(date).format(DATE_PICKER_FORMAT);
export const getTimeISO = (date) => moment(date).toISOString();

const getDurationTime = (time) => moment.duration(moment().diff(time));

export const getDiffTimeStaffList = (time) => {
  const duration = getDurationTime(time);
  // return 5h 10m
  if (duration.asHours() > 1) return `${duration.asHours().toFixed(0)}h ${duration.minutes()}m`;
  // return 12m
  return `${duration.minutes()}m`;
};

export const getDiffTime = (time) => {
  const duration = getDurationTime(time);
  // return  10h 20m 55s
  if (duration.asHours() > 1) {
    return `${duration.asHours().toFixed(0)}h ${duration.minutes()}m ${duration.seconds()}s`;
  }
  // return 20m 55s
  if (duration.asMinutes() > 1) {
    return `${duration.minutes()}m ${duration.seconds()}s`;
  }

  // return 10s
  return `${duration.seconds()}s`;
};

export function isChristmasTime(date) {
  const momentDate = moment(date);
  const christmasDays = [23, 24, 25, 26];

  return momentDate.month() === 11
    && christmasDays.indexOf(momentDate.date()) !== -1;
}

export function isHalloweenTime(date) {
  const momentDate = moment(date);
  const halloweenDays = [28, 29, 30, 31];

  return momentDate.month() === 9
    && halloweenDays.indexOf(momentDate.date()) !== -1;
}

export function getTimeString(minutes) {
  const minutePart = minutes % 60;
  const hourPart = (minutes - minutePart) / 60;
  const parts = [];

  if (hourPart > 0) {
    parts.push(`${hourPart} h`);
  }
  if (minutePart > 0) {
    parts.push(`${minutePart} min`);
  }
  return parts.join(' ');
}

export function getDiffString(end, start) {
  const diff = getMinutesDiff(end, start);
  return getTimeString(diff);
}
