import React, { useState, useRef } from 'react';
import useOnClickOutside from '@Hooks/useOnClickOutside';
import styled from 'styled-components';
import TextEllipsis from '@Components/ui/styled/text-ellipsis';
import { Text } from '@Components/ui/styled/main';

const styleDD = { maxWidth: '100%' };

export const DDLabel = styled.div`
  color: #6c6c6c;
  font-weight: 500;
`;

export const DDSubList = styled.div`
  padding-left: 14px;
  font-weight: 500;
`;

export const DDWrapper = styled.div`
  position: relative;
  cursor: pointer;
`;

export const DDStyleBody = styled.div`
  padding-bottom: 10px;
`;

export const DDList = styled.ul`
  font-size: 15px;
  width: 100%;
  position: absolute;
  background-color: #ffffff;
  border: 1px solid var(--color-soft-gray-80);
  padding: 10px 0;
  z-index: 1111;
  max-height: 300px;
  height: fit-content;
  overflow: auto;
  border-radius: 5px;
  margin-top: 2px;
`;

export const DDListItem = styled.li`
  position: relative;
  list-style: none;
  padding: 2px 10px;

  &:hover {
    background: #e6e9ed;
  }
`;

export const DDHeader = styled.div`
  font-size: 15px;
  display: flex;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  height: 40px;
  padding-left: 10px;
  text-align: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  i {
    height: 40px;
    width: 40px;
    margin-left: auto;
    background: #e6e9ed;
    border-radius: 0 5px 5px 0;

    display: flex;
    align-items: center;
    justify-content: center;
    ::before {
      font-size: 24px;
    }
  }
`;

export const TextEllipsisTitle = styled(TextEllipsis)`
  color: rgba(0, 0, 0, 0.75);
  font-weight: 500;
`;

export const DDTextTitle = styled(Text)`
  font-weight: 500;
  padding-bottom: 4px;
`;

export const TextEllipsisStyledItem = styled(TextEllipsis)`
  padding: 4px 0;
`;

const DDModern = ({ style, children, listTitle }) => {
  const ref = useRef();
  const [listOpen, setListOpen] = useState(false);
  useOnClickOutside(ref, () => setListOpen(false));
  const toggleOpen = () => setListOpen(!listOpen);

  return (
    <div
      style={style || styleDD}
      ref={ref}
      onClick={toggleOpen}
    >
      <DDHeader onClick={() => setListOpen(!listOpen)}>
        <TextEllipsisTitle width={220}>{listTitle}</TextEllipsisTitle>
        {listOpen
          ? <i className="fal fa-angle-up" />
          : <i className="fal fa-angle-down" />}
      </DDHeader>
      {listOpen && children}
    </div>
  );
};

export default DDModern;
