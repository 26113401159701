import React from 'react';
import Panel from '@Components/ui/styled/panel';
import CurrencyUtil from '@Utils/currency-util';
import { colors } from '@Components/ui/styled/variables';

const SupplierProductInfo = ({ supplierProduct }) => {
  const { brand, productName, supplierKey, rrp } = supplierProduct;

  return (
    <Panel bgColor={colors.successBg}>
      <p>
        <strong>{brand}, {productName}</strong><br />
        Rekommenderat pris: {CurrencyUtil.accountCurrency(rrp)}
      </p>
      <span className="text-muted">
        Produktinfo hämtad från {supplierKey}
      </span>
    </Panel>
  );
};

export default SupplierProductInfo;
