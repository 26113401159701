export const config = {
  stableId: 'stableId',
  locHashId: 'locHashId',
  resourceId: 'resourceId',
  locationRole: 'locationRole',
  onboarded: 'onboarded',
  smsPrices: 'smsPrices',
  allowSms: 'allowSms',
  allowSmsCampaign: 'allowSmsCampaign',
  allowEmailCampaign: 'allowEmailCampaign',
  smsBillingEnabled: 'smsBillingEnabled',
  locationName: 'locationName',
  organisationName: 'organisationName',
  currentVatRates: 'currentVatRates',
  currency: 'currency',
  timeZone: 'timeZone',
  locale: 'locale',
  region: 'region',
  slug: 'slug',
  searchEngineEnabled: 'searchEngineEnabled',
  bookingReservationTypes: 'bookingReservationTypes',
  releaseNote: 'releaseNote',
  orgWideCustomerDb: 'orgWideCustomerDb'
};

export const locale = {
  preferredE164CountryCode: 'locale_preferredE164CountryCode'
};

export const calendar = {
  showWebBookingIndicator: 'calendar_showWebBookingIndicator',
  darkenClosedWebBookingDays: 'calendar_darkenClosedWebBookingDays',
  rowsPerHour: 'calendar_rowsPerHour',
  confirmMoveEnabled: 'calendar_confirmMoveEnabled',
  dpPosition: 'calendar_dpPosition',
  showHolidays: 'calendar_showHolidays',
  showAssociatedResource: 'calendar_showAssociatedResource',
  showNotesIndicator: 'calendar_showNotesIndicator',
  useWebBookingColor: 'calendar_useWebBookingColor',
  showPaidBookingBadge: 'calendar_showPaidBookingBadge',
  alwaysShowTime: 'calendar_alwaysShowTime',
  alwaysShowServiceOnNewLine: 'calendar_alwaysShowServiceOnNewLine',
  resourceListViewMode: 'calendar_resourceListViewMode',
  extraColumnMargin: 'calendar_extraColumnMargin'
};

export const customer = {
  associateWithResource: 'customer_associateWithResource'
};

export const sms = {
  smsSender: 'sms_smsSender',
  onBookOnline: 'sms_onBookOnline',
  onCancelOnline: 'sms_onCancelOnline',
  defaultOptionSmsConfirm: 'sms_defaultOptionSmsConfirm',
  reminderWithin: 'sms_reminderWithin',
  reminderSendTimes: 'sms_reminderSendTimes',
  confirmTemplate: 'sms_confirmTemplate',
  reminderTemplate: 'sms_reminderTemplate',
  cancelTemplate: 'sms_cancelTemplate',
  reminderDisabled: 'sms_reminderDisabled'
};

export const email = {
  fromName: 'email_fromName',
  replyTo: 'email_replyTo',
  onBookOnline: 'email_onBookOnline',
  onCancelOnline: 'email_onCancelOnline',
  defaultOptionEmailConfirm: 'email_defaultOptionEmailConfirm',
  confirmExtraInfo: 'email_confirmExtraInfo',
  cancelExtraInfo: 'email_cancelExtraInfo',
  includeIcs: 'email_includeIcs',
  icsHeader: 'email_icsHeader',
  icsLocation: 'email_icsLocation',
  icsNotes: 'email_icsNotes'
};

export const company = {
  companyName: 'web_companyName',
  contactNumber: 'web_contactNumber',
  contactEmail: 'web_contactEmail',
  contactWebsite: 'web_contactWebsite',
  addressLine1: 'web_addressLine1',
  postCode: 'web_postCode',
  area: 'web_area'
};

export const web = {
  slotFinderStrategy: 'web_slotFinderStrategy',
  slotFinderParams: 'web_slotFinderParams',
  minTimeBeforeBooking: 'web_minTimeBeforeBooking',
  bookingMaxDaysInAdvance: 'web_bookingMaxDaysInAdvance',
  allowCancellation: 'web_allowCancellation',
  cancelUpToMinutes: 'web_cancelUpToMinutes',
  resourceLabel: 'web_resourceLabel',
  useSmsVerification: 'web_useSmsVerification',
  allowMultipleServices: 'web_allowMultipleServices',
  allowMultipleAddonServices: 'web_allowMultipleAddonServices',
  autoResourceSelection: 'web_autoResourceSelection',
  showOneSlotPerTime: 'web_showOneSlotPerTime',
  mergeDuplicateResources: 'web_mergeDuplicateResources',
  alwaysShowDescription: 'web_alwaysShowDescription',
  alwaysShowSummary: 'web_alwaysShowSummary',
  collapseGroups: 'web_collapseGroups',
  requirePNo: 'web_requirePNo',
  allowForeignPno: 'web_allowForeignPno',
  bookingsPerPnoLimit: 'web_bookingsPerPnoLimit',
  bookingsPerMobileLimit: 'web_bookingsPerMobileLimit',
  separateNameFields: 'web_separateNameFields',
  requireEmail: 'web_requireEmail',
  confirmPhone: 'web_confirmPhone',
  confirmEmail: 'web_confirmEmail',
  hideNotes: 'web_hideNotes',
  requireNotes: 'web_requireNotes',
  requireOrgNo: 'web_requireOrgNo',
  requireOrgName: 'web_requireOrgName',
  customMessageLabel: 'web_customMessageLabel',
  messageExtraInfo: 'web_messageExtraInfo',
  bookingExtraInfo: 'web_bookingExtraInfo',
  cancellationPageInfo: 'web_cancellationPageInfo',
  paymentTerms: 'web_paymentTerms',
  showTCBox: 'web_showTCBox',
  customTCBoxLabel: 'web_customTCBoxLabel',
  showExtraTCBox: 'web_showExtraTCBox',
  extraTCBoxLabel: 'web_extraTCBoxLabel',
  showExtraTCBoxInVerifyStep: 'web_showExtraTCBoxInVerifyStep',
  showExtraInfoOnConfirmPage: 'web_showExtraInfoOnConfirmPage',
  customerMatchingCriteria: 'web_customerMatchingCriteria',
  blockPublicHolidays: 'web_blockPublicHolidays',
  showResourceStep: 'web_showResourceStep',
  skipServiceStep: 'web_skipServiceStep',
  paymentEnabled: 'web_paymentEnabled',
  paymentRequired: 'web_paymentRequired',
  allowPayOnSite: 'web_allowPayOnSite',
  allowDiscountVoucher: 'web_allowDiscountVoucher',
  autoSelectFirstAvailableSlot: 'web_autoSelectFirstAvailableSlot',
  showUnmappedServices: 'web_showUnmappedServices',
  gotoNextAvailableTime: 'web_gotoNextAvailableTime',
  showAllowMarketing: 'web_showAllowMarketing',
  allowMarketingLabel: 'web_allowMarketingLabel',
  allowMarketingDefaultChecked: 'web_allowMarketingDefaultChecked',
  eveningPriceStart: 'web_eveningPriceStart',
  holidayPriceOnSaturday: 'web_holidayPriceOnSaturday',
  holidayPriceOnSunday: 'web_holidayPriceOnSunday',
  holidayPriceOnRedDays: 'web_holidayPriceOnRedDays'
};

export const storage = {
  autoPurgeEnabled: 'storage_autoPurgeEnabled',
  purgeLogsEnabled: 'storage_purgeLogsEnabled',
  bookingMaxAge: 'storage_bookingMaxAge',
  customerLogMaxAge: 'storage_customerLogMaxAge',
  inactiveCustomerMaxAge: 'storage_inactiveCustomerMaxAge',
  settingsAccepted: 'storage_settingsAccepted'
};

export const pos = {
  autoEmailZReport: 'pos_autoEmailZReport',
  defaultPrinterId: 'pos_defaultPrinterId',
  alwaysPrintReceipt: 'pos_alwaysPrintReceipt',
  alwaysOpenDrawer: 'pos_alwaysOpenDrawer',
  autoPrintZReport: 'pos_autoPrintZReport',
  useOperatorPinCode: 'pos_useOperatorPinCode',
  useOperatorIdleTimeout: 'pos_useOperatorIdleTimeout',
  operatorIdleTimeout: 'pos_operatorIdleTimeout',
  lockVunitToDevice: 'pos_lockVunitToDevice',
  logoutAfterPurchase: 'pos_logoutAfterPurchase',
  logoutOnNavigate: 'pos_logoutOnNavigate',
  paymentTipEnabled: 'pos_paymentTipEnabled',
  paymentMethodCard: 'pos_paymentMethodCard',
  paymentMethodCash: 'pos_paymentMethodCash',
  paymentMethodSwish: 'pos_paymentMethodSwish',
  paymentMethodKlarna: 'pos_paymentMethodKlarna',
  paymentMethodInvoice: 'pos_paymentMethodInvoice',
  paymentMethodFortnoxInvoice: 'pos_paymentMethodFortnoxInvoice',
  paymentMethodGiftCard: 'pos_paymentMethodGiftCard',
  paymentMethodOther: 'pos_paymentMethodOther',
  swishPayeeAlias: 'pos_swishPayeeAlias',
  swishCertId: 'pos_swishCertId',
  useSwishIntegration: 'pos_useSwishIntegration',
  klarnaUsername: 'pos_klarnaKPUsername',
  klarnaPassword: 'pos_klarnaKPPassword',
  defaultVoucherType: 'pos_defaultVoucherType',
  defaultVoucherValidity: 'pos_defaultVoucherValidity',
  defaultSinglePurposeVoucherVatPct: 'pos_defaultSinglePurposeVoucherVatPct',
  defaultGroupType: 'pos_defaultGroupType',
  hideServices: 'pos_hideServices',
  hideProducts: 'pos_hideProducts',
  defaultVatPct: 'payment_defaultVatPct',
  showCashierNameOnReceipt: 'pos_showCashierNameOnReceipt',
  showCustomerNameOnReceipt: 'pos_showCustomerNameOnReceipt',
  showCustomerPnoOnReceipt: 'pos_showCustomerPnoOnReceipt',
  showProductBrandOnReceipt: 'pos_showProductBrandOnReceipt',
  showVoucherCodeOnReceipt: 'pos_showVoucherCodeOnReceipt',
  showVoucherCodeOnRedeemReceipt: 'pos_showVoucherCodeOnRedeemReceipt',
  showVoucherBalanceOnReceipt: 'pos_showVoucherBalanceOnReceipt',
  showUpcomingBookingsOnReceipt: 'pos_showUpcomingBookingsOnReceipt',
  invoiceBGNumber: 'pos_invoiceBGNumber',
  invoicePGNumber: 'pos_invoicePGNumber',
  invoiceDueDays: 'pos_invoiceDueDays',
  latePaymentInterest: 'pos_latePaymentInterest',
  useCustomerInvoiceRef: 'pos_useCustomerInvoiceRef',
  invoiceUseWindowedEnvelope: 'pos_invoiceUseWindowedEnvelope',
  invoiceCopyToBillingContact: 'pos_invoiceCopyToBillingContact',
  useDeferredServiceSale: 'pos_UseDeferredServiceSale',
  showProductStockQuantity: 'pos_showProductStockQuantity',
  lowStockQuantityWarning: 'pos_lowStockQuantityWarning',
  mergeSameArticlesOnReceipt: 'pos_mergeSameArticlesOnReceipt',
  showBookingNoteInSale: 'pos_showBookingNoteInSale',
  invoiceUseFortnox: 'pos_invoiceUseFortnox',
  invoiceFortnoxService: 'pos_invoiceFortnoxService',
  invoiceFortnoxBookAndSend: 'pos_invoiceFortnoxBookAndSend',
  invoiceEmailSubject: 'pos_invoiceEmailSubject',
  invoiceEmailBody: 'pos_invoiceEmailBody'
};

export const reports = {
  columnSettings: 'reports_columnSettings',
  hideCancelledBookings: 'reports_hideCancelledBookings',
  hideReservations: 'reports_hideReservations',
  salesExVat: 'reports_salesExVat'
};

export const payment = {
  sellerName: 'payment_sellerName',
  sellerVatNo: 'payment_sellerVatNo',
  sellerWeb: 'payment_sellerWeb',
  sellerEmail: 'payment_sellerEmail',
  logoUrl: 'payment_logoUrl',
  addressLine1: 'payment_addressLine1',
  addressLine2: 'payment_addressLine2',
  postCode: 'payment_postCode',
  area: 'payment_area',
  statementDescriptor: 'payment_statementDescriptor',
  chargeDescription: 'payment_chargeDescription',
  defaultVatPct: 'payment_defaultVatPct',
  pricesExVat: 'payment_pricesExVat',
  showVat: 'payment_showVat',
  defaultEcomVunitId: 'payment_defaultEcomVunitId'
};

export const accounting = {
  fortnoxSyncEnabled: 'accounting_fortnoxSyncEnabled',
  fortnoxAccessToken: 'accounting_fortnoxAccessToken',
  voucherSeries: 'accounting_voucherSeries'
};

export const ecom = {
  stripeEnabled: 'ecom_stripeEnabled',
  stripeAccountId: 'ecom_stripeAccountId',
  swishEnabled: 'ecom_swishEnabled',
  swishCertId: 'ecom_swishCertId',
  swishPayeeAlias: 'ecom_swishPayeeAlias',
  klarnaEnabled: 'ecom_klarnaEnabled',
  klarnaUsername: 'ecom_klarnaUsername',
  klarnaPassword: 'ecom_klarnaPassword'
};

export const mybusiness = {
  placeId: 'mybusiness_placeId'
};

export const review = {
  reminderStatus: 'review_reminderStatus'
};

export const notification = {
  email: {
    bookingConfirmWeb: 'Email_Booking_Confirm_Web',
    bookingCancelWeb: 'Email_Booking_Cancel_Web',
    bookingCreateCal: 'Email_Booking_Create_Cal',
    bookingCancelCal: 'Email_Booking_Cancel_Cal',
    bookingReviewPosted: 'Email_Booking_Review'
  },
  push: {
    bookingConfirmWeb: 'Push_Booking_Confirm_Web',
    bookingCancelWeb: 'Push_Booking_Cancel_Web',
    bookingCreateCal: 'Push_Booking_Create_Cal',
    bookingCancelCal: 'Push_Booking_Cancel_Cal',
    bookingReviewPosted: 'Push_Booking_Review'
  }
};

export const user = {
  hideUndoMoveHelpTip: 'hideUndoMoveHelpTip',
  hideClipboardHelpTip: 'hideClipboardHelpTip',
  releaseNotesDismissed: 'releaseNotesDismissed'
};

export const resource = [
  web.slotFinderStrategy, web.slotFinderParams, web.minTimeBeforeBooking, web.bookingMaxDaysInAdvance,
  company.addressLine1, company.postCode, company.area, web.paymentEnabled, payment.defaultEcomVunitId,
  web.eveningPriceStart, web.holidayPriceOnSaturday, web.holidayPriceOnSunday, web.holidayPriceOnRedDays,
  sms.onBookOnline, sms.onCancelOnline, sms.defaultOptionSmsConfirm, sms.reminderWithin,
  sms.confirmTemplate, sms.reminderTemplate, sms.cancelTemplate,
  email.onBookOnline, email.onCancelOnline, email.defaultOptionEmailConfirm, email.confirmExtraInfo,
  email.includeIcs, email.icsHeader, email.icsLocation, email.icsNotes
];
