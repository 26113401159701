import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { fetchGroupsAndResources } from '@State/resource-actions';
import ButtonModalForm from '@Components/dialogs/button-modal-form';
import HookFormInput from '@Components/inputs/hook-form-input';
import DialogAlert from '@Components/dialogs/dialog-alert';
import { customerImportUrl, importCsvCustomers } from '@State/import-actions';
import FormButtonsFooter from '@Components/ui/form-buttons-footer';
import FileUploadField from '@Components/ui/file-upload-field';

const SelectResourceField = ({ location }) => {
  const dispatch = useDispatch();
  const resources = useSelector(state => state.resourcesById.valueSeq());

  const options = [{
    value: '0',
    title: 'Ingen resurs'
  }, ...resources.map(resource => ({
    value: resource.id,
    title: resource.name
  }))];

  useEffect(() => {
    dispatch(fetchGroupsAndResources(location.get('orgCtxName'), location.get('ctxName')));
  }, [location]);

  return (
    <HookFormInput
      id="resourceId"
      type="select"
      label="Koppla till resurs"
      initialValue="0"
      options={options}
    />
  );
};

const AccountImportCustomers = ({ location }) => {
  const dispatch = useDispatch();
  const [importSuccess, setImportSuccess] = useState(null);
  const methods = useForm();
  const importUrl = methods.watch('importUrl');
  const supportNoExisting = !importUrl || importUrl === customerImportUrl.generalCsv;

  const onImportCustomers = (params) => {
    return dispatch(importCsvCustomers(location.get('orgCtxName'), location.get('ctxName'), params))
      .then((data) => {
        setImportSuccess(data);
        methods.reset();
      });
  };

  return (
    <>
      {importSuccess && (
        <DialogAlert
          title=""
          success={importSuccess.customers > 0}
          warning={!importSuccess.customers}
          icon={importSuccess.customers > 0
            ? 'fa fa-check-circle'
            : 'fa fa-exclamation-triangle'}
          text={(
            <div>
              {importSuccess.customers || 'Inga'} kunder importerades
            </div>
          )}
          onClose={() => setImportSuccess(null)}
          closeButtonText="Stäng"
        />
      )}
      <ButtonModalForm
        dialogProps={{
          title: 'Importera kunder',
          contentSize: 'large'
        }}
        onClose={() => methods.reset()}
        onSubmit={onImportCustomers}
        btnText="Importera kunder"
      >
        {({ onClose, onSubmit, loading }) => (
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <HookFormInput
                id="importUrl"
                type="select"
                label="Välj importformat"
                initialValue={customerImportUrl.generalCsv}
                options={[
                  { value: customerImportUrl.generalCsv, title: 'Generell CSV' },
                  { value: customerImportUrl.bokadirektV1, title: 'Bokadirekt V1' },
                  { value: customerImportUrl.bokadirektV2, title: 'Bokadirekt V2' }
                ]}
                registerOptions={{ required: true }}
              />
              <SelectResourceField location={location} />
              {supportNoExisting && (
                <HookFormInput
                  id="noExisting"
                  type="checkbox"
                  label="Hoppa över befintliga kunder"
                />
              )}
              <FileUploadField
                value={methods.watch('file')}
                onChange={file => methods.setValue('file', file)}
              />
              <FormButtonsFooter
                submitText="Importera kunder"
                submitting={loading}
                onClose={onClose}
              />
            </form>
          </FormProvider>
        )}
      </ButtonModalForm>
    </>
  );
};

export default AccountImportCustomers;
