import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import ClipLoader from 'halogenium/lib/ClipLoader';

const LoaderWrapper = styled.div`
  text-align: center;
  position: relative;
  top: ${p => p.topValue};
`;

const Loader = ({ topValue = '50px', topPosition = false, solid = false }) => {
  const theme = useSelector((state) => state.appState.get('theme'));

  if (topPosition) {
    return (
      <LoaderWrapper topValue={topValue}>
        <ClipLoader color="#dedede" />
      </LoaderWrapper>
    );
  }

  const imageSrc = theme === 'yabie' ? '/yabie-black.svg' : '/c.svg';
  const imageClass = theme === 'yabie' ? 'yabie' : 'cliento';

  return (
    <div className={solid ? 'loader-container solid' : 'loader-container'}>
      {solid
        ? <img src={imageSrc} className={imageClass} alt="" />
        : <ClipLoader color="#dedede" />}
    </div>
  );
};

export default Loader;
