import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { txt } from '@Utils/i18n-util';
import msg from './booking-form.msg';

class BookingRecurring extends Component {
  componentDidMount() {
    this.focusEditField();
  }

  focusEditField = () => {
    if (this.rrule) {
      const rrule = ReactDOM.findDOMNode(this.rrule);
      rrule.focus();
    }
  };

  render() {
    return (
      <div className="booking-form-block form">
        <div className="row">
          <div className="form-group col-xs-12">
            <label className="control-label">{txt(msg.recurringLabel)}</label>
            <Field
              name="rrule"
              component="input"
              className="form-control"
              normalize={v => v && v.trim()}
              ref={(ref) => { this.rrule = ref; }}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { bkf } = state;

  const initialValues = {
    rrule: bkf.get('rrule') || ''
  };

  return {
    initialValues,
    ...getFormValues('bkf-recurring')(state)
  };
};

export default connect(mapStateToProps)(reduxForm({
  form: 'bkf-recurring',
  destroyOnUnmount: false
})(BookingRecurring));
