import { isBlank } from 'underscore.string';
import { isClassBooking } from '@Utils/booking-util';

export function isWebBooking(channel) {
  return channel && channel.indexOf('Web') !== -1;
}

export function smsReminderSent(reminders) {
  return reminders && reminders.find(r => r.type === 'PreReminder' && r.status === 'DELIVERED');
}

export function getIcons(props) {
  const {
    reminders, channel, note, reservation, associatedResourceId, showAssociatedResource,
    resourceId, resources, resourcesById, sales, payments, partnerId, showNotesIndicator
  } = props;

  const isClass = isClassBooking(props);
  const notes = !(isBlank(note) || reservation);
  const isAssociatedResource = associatedResourceId && associatedResourceId === resourceId;
  const associatedResourceName = isAssociatedResource && resourcesById.get(associatedResourceId)?.name;
  const paid = sales?.length > 0;
  const prePaid = !paid && payments?.filter(p => p.paymentType === 'PrePaid').length > 0;

  const icons = [];
  if (isClass) {
    icons.push({ icon: 'fa fa-users', title: 'Klassbokning' });
  }
  if (resources.length > 1) {
    icons.push({ icon: 'fa fa-link-horizontal', title: 'Flera resurser' });
  }
  if (isAssociatedResource && showAssociatedResource) {
    icons.push({ icon: 'fa fa-star-of-life', title: `Kund tillhör ${associatedResourceName}` });
  }
  if (notes && showNotesIndicator) {
    icons.push({ icon: 'fa fa-info-circle', title: note });
  }
  if (smsReminderSent(reminders)) {
    icons.push({ icon: 'fa fa-comment-check', title: 'SMS skickat' });
  }
  if (isWebBooking(channel) && !partnerId) {
    icons.push({ icon: 'fa fa-globe', title: 'Onlinebokning' });
  }
  if (partnerId) {
    icons.push({ icon: 'fa fa-yin-yang', title: 'Bokad via partner' });
  }
  if (paid && !isClass) {
    icons.push({ icon: 'fa fa-badge-dollar', title: 'Betald' });
  }
  if (prePaid) {
    icons.push({ icon: 'fa fa-circle-dollar-to-slot', title: 'Förbetalt belopp' });
  }
  return icons;
}
