module.exports = {
  initialize(currencyCode, locale) {
    this.currencyCode = currencyCode;
    this.locale = locale;
  },

  accountCurrency(amount, decimals, currencyCode) {
    const fractionDigits = decimals === undefined ? 2 : parseInt(decimals);
    const properties = {
      style: 'currency',
      currency: currencyCode || this.currencyCode,
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits
    };
    return parseFloat(amount || 0).toLocaleString(this.locale, properties);
  },

  // This method is only used for billing currency, which is currently always SEK
  currency(amount) {
    return this.accountCurrency(amount, 2, 'SEK');
  },

  currencySymbol() {
    switch (this.currencyCode) {
      case 'EUR':
        return '€';

      case 'USD':
        return '$';

      default:
        return 'kr';
    }
  },

  isValidCashAmount(value) {
    switch (this.currencyCode) {
      case 'EUR':
      case 'USD':
        return true;

      default:
        return value % 100 === 0;
    }
  }
};
