import React, { useCallback } from 'react';

import Grid, { GridRow } from '@Components/ui/styled/grid';
import FormGroup from '@Components/ui/styled/form-group';
import { Flex, Panel, Row } from '@Components/ui/styled/main';
import { Title } from '@Components/sysadmin/accounts/accounts-styles';
import SwitchInput from '@Components/ui/switch-input/switch-input';
import { txt } from '@Utils/i18n-util';

import msg from './account-overview.msg';

const getProp = (prop, format) => {
  if (prop !== undefined && prop !== null && format) return format(prop);
  return prop;
};

const AccountOverviewBlock = ({ data, title, button, columns, columnsQuan }) => {
  const getTextComponent = useCallback((field) => {
    if (field.getProp) return field.getProp(data);
    const value = getProp(data.get ? data.get(field.prop) : data[field.prop], field.format);
    if (field.renderText) return field.renderText(value);
    if (field.handleToggle) {
      return (
        <Flex rightMargin className="no-margins">
          <SwitchInput
            id={field.prop}
            isOn={value}
            handleToggle={field.handleToggle}
          />
        </Flex>
      );
    }
    return value;
  }, [data]);

  const getLabelTranslation = useCallback((key) => {
    return msg[key] ? txt(msg[key]) : key;
  }, []);

  if (!data) return null;

  return (
    <>
      {(title || button) && (
        <Row>
          <Title muted>{title}</Title>
          <Flex right vCenter>
            {button || null}
          </Flex>
        </Row>
      )}
      <Panel>
        <Grid>
          {columns.map((row = [], i) => (
            <GridRow columns={columnsQuan} key={`fields_row_${i}`}>
              {row.map(field => (
                <FormGroup
                  key={field.title}
                  labelText={getLabelTranslation(field.title)}
                  text={getTextComponent(field)}
                  tooltip={field.tooltip}
                />
              ))}
            </GridRow>
          ))}
        </Grid>
      </Panel>
    </>
  );
};

export default AccountOverviewBlock;
