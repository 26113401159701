import moment from 'moment';
import { formatPhoneNumber } from '@Utils/phone-util';
import { getPrimaryCustomer } from '@Utils/booking-util';

export function dataURLtoFile(dataurl, filename) {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[arr.length - 1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export function getStatus(booking) {
  const hasSales = booking.sales?.length > 0;
  const isShow = booking.status === 'Show';
  const isNoShow = booking.status === 'NoShow';
  const isAfterStart = moment().isAfter(booking.startTime);
  const isAfterEnd = moment().subtract(1, 'minutes').isAfter(booking.endTime);
  const isAlmostOver = isAfterStart && !isAfterEnd
    && moment(booking.endTime).subtract(5, 'minutes').isBefore(moment());

  if (isShow) {
    return {
      icon: 'fa fa-check-circle text-success mr1',
      background: '#E6FAE6',
      status: null
    };
  }
  if (isNoShow || isAfterEnd) {
    return {
      icon: 'fa fa-exclamation-circle text-danger mr1',
      background: '#FFE6E7',
      status: isAfterEnd ? `${moment().diff(booking.endTime, 'minutes')} min sen` : null
    };
  }
  if (isAlmostOver) {
    return {
      icon: 'fa fa-play-circle text-warning mr1',
      background: '#FEF7DE',
      status: `${moment(booking.endTime).add(1, 'minutes').diff(moment(), 'minutes')} min kvar`
    };
  }
  if (hasSales || isAfterStart) {
    return {
      icon: hasSales ? 'fa fa-play-circle text-info mr1' : null,
      background: hasSales ? '#E1F4FF' : null,
      status: `${moment(booking.endTime).add(1, 'minutes').diff(moment(), 'minutes')} min kvar`
    };
  }

  return {
    icon: null,
    background: null,
    status: null
  };
}

export function getCustomer(booking) {
  const customer = getPrimaryCustomer(booking);
  return {
    ...customer,
    phoneNumber: formatPhoneNumber(booking.customerPhoneNumber),
    otherPhoneNumber: formatPhoneNumber(booking.customerOtherPhoneNumber)
  };
}

export function getBookingDataWithService(booking, service) {
  const { serviceDuration, afterTime, price } = service;
  return {
    ...booking,
    services: [...booking.services, service],
    price: booking.price + price,
    endTime: moment(booking.endTime).add(serviceDuration + afterTime, 'minutes'),
    secondaryResources: booking.resources.filter(r => !r.primary).map(r => r.id)
  };
}

export function matchFilter(booking, filter) {
  return booking.customerName.toLowerCase().includes(filter)
    || booking.customFields.some(f => f.value?.toLowerCase().includes(filter));
}
