import { createBrowserHistory } from 'history';
import moment from 'moment';
import { viewDateFromDate, viewDateToStartEnd, viewDateToViewDate } from './time-util';

const history = createBrowserHistory();

export function getHistory() {
  return history;
}

const defaultSysAdminUrl = '/sysadmin/a/accounts/';
export function getSysadminUrl({ id, subsection = 'overview' } = {}) {
  return !id ? defaultSysAdminUrl : `${defaultSysAdminUrl}${id}/${subsection}`;
}

export function getSectionUrl(routeParams, section, subsection) {
  const url = `${getOrgLocUrl(routeParams)}/${section}/`;
  return subsection
    ? `${url}${subsection}`
    : url;
}

export function getOrgLocUrl({ org, loc }) {
  return `/${org}/${loc}`;
}

export function getOrgLocAdminUrl(rp) {
  return `${getOrgLocUrl(rp)}/admin`;
}

export function getOrgLocCalendarUrl(rp) {
  return `${getOrgLocUrl(rp)}/calendar`;
}

export function getOrgLocCustomersUrl(rp) {
  return `${getOrgLocUrl(rp)}/customers`;
}

export function getOrgLocCampaignUrl(rp) {
  return `${getOrgLocUrl(rp)}/campaign`;
}

export function getJSONConfigUrl(routeParams, subsection = 'common') {
  return `${getOrgLocAdminUrl(routeParams)}/json-prefs/${subsection}`;
}

export function getServiceConfigUrl(routeParams, id, subsection = 'settings') {
  return `${getOrgLocAdminUrl(routeParams)}/${routeParams.subsection}/${id}/${subsection}`;
}

export function getVoucherTemplatesUrl(routeParams, id) {
  return `${getOrgLocAdminUrl(routeParams)}/voucher-templates/${id}`;
}

export function getStaffUrl(routeParams, id) {
  return `${getOrgLocUrl(routeParams)}/staff/${id}`;
}

export function getOrganisationsUrl(routeParams) {
  return `/organisations/${routeParams.org}/`;
}

export function getCustomersUrl(routeParams, id, subsection = 'overview') {
  return `${getOrgLocCustomersUrl(routeParams)}/${id}/${subsection}`;
}

export function getCustomerTabUrl(routeParams, subsection, newTabId) {
  const { id, tabId } = routeParams;
  return `${getOrgLocCustomersUrl(routeParams)}/${id}/${subsection}/${newTabId || tabId}`;
}

export function getCustomerBookingUrl(routeParams, newTabId) {
  return getCustomerTabUrl(routeParams, 'bookings', newTabId);
}

export function getCustomerLogsUrl(routeParams, newTabId) {
  return getCustomerTabUrl(routeParams, 'logs', newTabId);
}

export function getCustomerSalesUrl(routeParams, newTabId) {
  return getCustomerTabUrl(routeParams, 'sales', newTabId);
}

export function getCustomerGiftCardsUrl(routeParams, newTabId) {
  return getCustomerTabUrl(routeParams, 'gift-cards', newTabId);
}

export function getCustomerEmployeesUrl(routeParams, newTabId) {
  return getCustomerTabUrl(routeParams, 'employees', newTabId);
}

export function getCustomerVehiclesUrl(routeParams, newTabId) {
  return getCustomerTabUrl(routeParams, 'vehicles', newTabId);
}

export function getResourceConfigUrl(routeParams, id, subsection) {
  const newSubsection = subsection || 'settings';
  return `${getOrgLocAdminUrl(routeParams)}/resources/${id}/${newSubsection}`;
}

export function getScheduleConfigUrl(routeParams, id) {
  return id
    ? `${getOrgLocAdminUrl(routeParams)}/schedules/${id}/${routeParams.tab || 'schedule'}`
    : `${getOrgLocAdminUrl(routeParams)}/schedules`;
}

export function getPosOrgUrl(routeParams, id, subsection = 'settings') {
  return id
    ? `${getOrgLocAdminUrl(routeParams)}/pos/${id}/${subsection}`
    : `${getOrgLocAdminUrl(routeParams)}/pos/`;
}

export function getGiftCardUrl(routeParams, id, subsection = 'active') {
  return `${getOrgLocAdminUrl(routeParams)}/gift-cards/${id}/${subsection}`;
}

export function getInvoicesUrl(routeParams, id, subsection = 'active') {
  return `${getOrgLocAdminUrl(routeParams)}/invoices/${id}/${subsection}`;
}

export function getAdminUrl(routeParams, section, id, subsection) {
  const { org, loc } = routeParams;
  return `/${org}/${loc}/admin/${section}/${id}/${subsection}`;
}

export function getResourceEntityType(groupId) {
  return groupId === 0 ? 'my-resource' : 'resource';
}

export function getResourceUrl(resId, resourceEntityType, newViewMode, routeParams) {
  const { viewDate, viewMode } = routeParams;
  const newViewDate = viewDateToViewDate(newViewMode, viewMode, viewDate);
  return `${getOrgLocCalendarUrl(routeParams)}/${newViewMode}/${newViewDate}/${resourceEntityType}/${resId}`;
}

export function getGroupUrl(groupId, routeParams) {
  const { viewDate, viewMode } = routeParams;
  const newViewMode = 'day';
  const newViewDate = viewDateToViewDate(newViewMode, viewMode, viewDate);
  return `${getOrgLocCalendarUrl(routeParams)}/${newViewMode}/${newViewDate}/group/${groupId}`;
}

export function getCalendarUrl(newViewDate, routeParams, includeSearch = false) {
  const { viewMode, entityType, entityId, search } = routeParams;
  const query = includeSearch ? search : '';

  return `${getOrgLocCalendarUrl(routeParams)}/${viewMode}/${newViewDate}/${entityType}/${entityId}${query}`;
}

export function getModeUrl(newViewMode, routeParams, includeSearch = false) {
  const { viewDate, viewMode, entityType, entityId, search } = routeParams;
  const newViewDate = viewDateToViewDate(newViewMode, viewMode, viewDate);
  const query = includeSearch ? search : '';

  return `${getOrgLocCalendarUrl(routeParams)}/${newViewMode}/${newViewDate}/${entityType}/${entityId}${query}`;
}

export function getDateUrl(where, routeParams) {
  const { viewMode, viewDate } = routeParams;
  const viewDateStartEnd = viewDateToStartEnd(viewMode, viewDate);
  const currentDate = moment(viewDateStartEnd.start);
  let newDate;

  switch (where) {
    case 'back':
      newDate = moment(currentDate).subtract(1, viewMode);
      break;
    case 'forward':
      newDate = moment(currentDate).add(1, viewMode);
      break;
    case 'today':
      newDate = moment().startOf('day');
      break;
  }

  if (where.startsWith('skip-')) {
    const weeks = parseInt(where.split('-')[1]);
    newDate = moment(currentDate).add(weeks, 'weeks');
  }

  const newViewDate = where === 'today' ? 'today' : viewDateFromDate(viewMode, newDate);
  return getCalendarUrl(newViewDate, routeParams, true);
}

export function navigate(url) {
  history.push(url);
}

export function navigateAsync(url, cb) {
  setTimeout(() => { history.push(url); if (cb) cb(); }, 0);
}

export function navigateSearchAsync(search) {
  setTimeout(() => { history.push({ search }); }, 0);
}

export function navigateTo(newViewDate, routeParams) {
  const url = getCalendarUrl(newViewDate, routeParams);
  console.log('generated url: ', url);
  navigate(url);
}
