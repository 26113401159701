import { defineMessages } from 'react-intl';

export default defineMessages({
  noEmployeesTitle: {
    id: 'customers.employees.noEmployeesTitle',
    defaultMessage: 'No employees'
  },
  noEmployeesText: {
    id: 'customers.employees.noEmployeesText',
    defaultMessage: 'There are no employees for this customer'
  },
  lblDetails: {
    id: 'customers.employees.lblDetails',
    defaultMessage: 'Details'
  }
});
