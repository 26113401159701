import React, {
  useContext, useEffect, useMemo
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { getPermissions } from '@State/selectors';
import { hasPermission, POS_ADMIN } from '@Utils/permissions';
import { getCustomerGiftCardsUrl } from '@Utils/navigate';
import { fetchCustomerGiftCards } from '@State/customer-actions';
import { sortEventByTimeAndReverse } from '@Utils/customer-util';
import customerIdContext from '@Components/customers/customer-id-context';

import Row from '@Components/ui/styled/row';
import CustomerGiftCardDetails from '@Components/customers/customer-detail/gift-cards/customer-gift-card-details';
import CustomerEmptyTab from '@Components/customers/customer-detail/customer-empty-tab';
import CustomerGiftCardList from '@Components/customers/customer-detail/gift-cards/customer-gift-card-list';
import CustomerAddGiftCard from '@Components/customers/customer-detail/gift-cards/customer-add-gift-card';
import Loader from '@Components/ui/loader';
import {
  ScrollContent, ScrollWrapper, ColumnRight, ColumnLeft, ContentBlock, TitleBlock
} from '@Components/customers/customer-styles';
import { txt } from '@Utils/i18n-util';
import msg from './customer-gift-cards.msg';

const CustomerGiftCards = ({ isModal, routeParams, tabId, onSelectGiftCard }) => {
  const dispatch = useDispatch();
  const id = isModal ? tabId : routeParams?.tabId;
  const customerId = +useContext(customerIdContext);
  const customerGiftCards = useSelector((state) => state.customerGiftCards);
  const phoneMode = useSelector(state => state.mainViewState.get('phoneMode'));
  const permissions = useSelector(state => getPermissions(state, { routeParams }));
  const hasPosPermission = hasPermission(permissions, POS_ADMIN);

  const giftCards = customerGiftCards.get('giftCards').filter(g => g.voucherStatus !== 'Void');
  const loading = customerGiftCards.get('loading');

  const selectedGiftCard = useMemo(() => {
    if (giftCards.length === 0) {
      return null;
    }
    if (id) {
      const itemById = giftCards.find((g) => g.id === +id);
      if (itemById) {
        return (itemById);
      }
    }

    const firstValid = sortEventByTimeAndReverse(giftCards.filter(g => g.valid));
    if (firstValid?.length > 0) return (firstValid[0]);

    const firstInvalid = sortEventByTimeAndReverse(giftCards.filter(g => !g.valid));
    if (firstInvalid?.length > 0) return (firstInvalid[0]);
  }, [giftCards, id]);

  useEffect(() => {
    dispatch(fetchCustomerGiftCards(customerId));
  }, [customerId]);

  const handlerOnClick = (item) => {
    onSelectGiftCard(item.id, 'gift-cards');
  };

  const onAdded = (id) => {
    onSelectGiftCard(id, 'gift-cards');
  };

  if (giftCards && giftCards.length > 0 && !isModal && selectedGiftCard && selectedGiftCard.id !== +id) {
    return <Redirect to={getCustomerGiftCardsUrl(routeParams, selectedGiftCard.id)} />;
  }

  if (loading) {
    return <Loader topPosition />;
  }

  if (giftCards.length === 0) {
    return (
      <CustomerEmptyTab
        title={txt(msg.noGiftCardsTitle)}
        text={txt(msg.noGiftCardsText)}
      >
        {hasPosPermission && <CustomerAddGiftCard customerId={customerId} onAdded={onAdded} />}
      </CustomerEmptyTab>
    );
  }

  const renderGiftCardDetails = () => {
    return selectedGiftCard && (
      <>
        <TitleBlock>{txt(msg.lblDetails)}</TitleBlock>
        <ContentBlock>
          <CustomerGiftCardDetails
            hasPosPermission={hasPosPermission}
            giftCard={selectedGiftCard}
          />
        </ContentBlock>
      </>
    );
  };

  const renderGiftCards = () => (
    <>
      <CustomerGiftCardList
        isSelectable
        selectedEventId={selectedGiftCard?.id}
        onSelectEvent={handlerOnClick}
        items={giftCards}
      />
      {hasPosPermission && (
        <div className="mt3">
          <CustomerAddGiftCard customerId={customerId} onAdded={onAdded} />
        </div>
      )}
    </>
  );

  if (phoneMode) {
    return (
      <Row scrollContainer>
        <ScrollWrapper>
          <ScrollContent>
            {renderGiftCards()}
          </ScrollContent>
        </ScrollWrapper>
      </Row>
    );
  }

  return (
    <Row scrollContainer>
      <ColumnLeft>
        <ScrollWrapper>
          <ScrollContent>
            {renderGiftCards()}
          </ScrollContent>
        </ScrollWrapper>
      </ColumnLeft>
      <ColumnRight>
        <ScrollWrapper>
          <ScrollContent>
            {renderGiftCardDetails()}
          </ScrollContent>
        </ScrollWrapper>
      </ColumnRight>
    </Row>
  );
};

export default CustomerGiftCards;
