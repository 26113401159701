import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import ModalDialog from '@Components/dialogs/modal-dialog';
import { GrayText, BoldText } from '@Components/dialogs/dialog-styles';
import Flex from '@Components/ui/styled/flex-item';
import TextInput from '@Components/inputs/text-input';
import { required, number, companyOrgNoValid } from '@Utils/validation';
import { normalizeOrgNoSE, normalizeOrgNoFI } from '@Utils/normalize';

const CreatePosOrgCompany = ({
  region, setSeparateAddress, separateAddress, onNext, handleSubmit, onHide, invalid
}) => {
  const buttonsAction = [{
    name: 'Nästa',
    primary: true,
    type: 'submit',
    disabled: invalid,
    onClick: handleSubmit(onNext)
  }];

  const onToggleCheckbox = () => setSeparateAddress(!separateAddress);

  return (
    <ModalDialog
      contentSize="medium"
      title="Nytt företag"
      onClose={onHide}
      buttons={buttonsAction}
    >
      <form onSubmit={handleSubmit(onNext)} autoComplete="off" className="dialog">
        <Flex>
          <Field
            name="companyName"
            component={TextInput}
            label="Företagsnamn"
            placeholder="Ditt företagsnamn"
            validate={required}
          />
        </Flex>
        <Flex>
          {region === 'FI' ? (
            <Field
              name="companyOrgNo"
              component={TextInput}
              label="FO-nummer"
              labelHint="(ex. 12345678-9)"
              normalize={normalizeOrgNoFI}
              placeholder="Ditt FO-nummer"
              validate={required}
            />
          ) : (
            <Field
              name="companyOrgNo"
              component={TextInput}
              label="Organisationsnummer"
              labelHint="(ex. 556971-4016)"
              normalize={normalizeOrgNoSE}
              placeholder="Ditt organisationsnummer"
              validate={[required, companyOrgNoValid]}
            />
          )}
        </Flex>
        <Flex>
          <Field
            name="companyAddressLine1"
            component={TextInput}
            label="Gatuadress"
            placeholder="Gatuadress"
            validate={required}
          />
        </Flex>
        <Flex>
          <Flex rightMargin>
            <Field
              name="companyPostCode"
              type="number"
              component={TextInput}
              label="Postnummer"
              placeholder="Postnummer"
              validate={[required, number]}
            />
          </Flex>
          <Flex>
            <Field
              name="companyArea"
              component={TextInput}
              label="Postort"
              placeholder="Postort"
              validate={required}
            />
          </Flex>
        </Flex>
        <Flex>
          <div className="form-group">
            <div className="checkboxEl">
              <input type="checkbox" checked={separateAddress} onChange={onToggleCheckbox} id="kassaAddress" />
              <label htmlFor="kassaAddress">
                <BoldText>Kassaregistret används på annan adress än ovan</BoldText>
                <GrayText>
                  Om kassaregistret används på en annan adress än den där
                  företaget är registrerat så behöver du ange den adressen i nästa steg
                </GrayText>
              </label>
            </div>
          </div>
        </Flex>
      </form>
    </ModalDialog>
  );
};

export default reduxForm({
  form: 'create-pos-org',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(CreatePosOrgCompany);
