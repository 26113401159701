import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import CurrencyUtil from '@Utils/currency-util';
import { fetchGiftCards, importGiftCards, updateGiftCard, voidGiftCard } from '@State/gift-card-actions';
import FilterContainer from '@Components/ui/filter-container';
import { getGiftCardStatus, getGiftCardType } from '@Utils/pos-utils';
import Button from '@Components/ui/button';
import { Panel, Row } from '@Components/ui/styled/main';
import { FlexOne, ReportTextLabel } from '@Components/pos/style';
import { reportingCount, reportingFormat } from '@Utils/format';
import { getGiftCardUrl, navigate } from '@Utils/navigate';
import NavTabsLink from '@Components/ui/nav-tabs-link';
import GiftCardModal from './gift-card-modal';
import GiftCardEditModal from './gift-card-edit-modal';

const tabSections = [
  { navId: 'active', name: 'Aktiva' },
  { navId: 'other', name: 'Ej aktiva' }
];

class GiftCards extends Component {
  state = {
    showEditModal: null,
    showAddGiftCard: false
  };

  showAddGiftCard = () => this.setState({ showAddGiftCard: true });

  hideEditModal = () => this.setState({ showEditModal: null });

  importGiftCards = (giftCards) => {
    return this.props.importGiftCards(this.props.posOrgId, giftCards)
      .then(() => this.props.fetchGiftCards(this.props.posOrgId));
  };

  hideForm = () => {
    this.setState({ showAddGiftCard: false });
  };

  showEditModal = (giftCard) => {
    this.setState({ showEditModal: giftCard.toJS() });
  };

  handleVoidGiftCard = (id) => {
    this.hideEditModal();
    return this.props.voidGiftCard(id)
      .then(() => this.props.fetchGiftCards(this.props.posOrgId));
  };

  handleEditGiftCard = (id, values) => {
    return this.props.updateGiftCard(id, values)
      .then(() => this.props.fetchGiftCards(this.props.posOrgId))
      .then(this.hideEditModal);
  };

  changePosOrgFilter = (ev) => {
    const { routeParams } = this.props;
    navigate(getGiftCardUrl(routeParams, ev.target.value, routeParams.tab));
  };

  componentDidMount() {
    if (this.props.posOrgId) {
      this.props.fetchGiftCards(this.props.posOrgId);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.posOrgId !== nextProps.posOrgId && nextProps.posOrgId) {
      this.props.fetchGiftCards(nextProps.posOrgId);
    }
  }

  render() {
    const { showAddGiftCard, showEditModal } = this.state;
    const {
      routeParams, posOrgs, posOrgId, activeVouchers, nonActiveVouchers,
      activeCount, remainingValue, match, posOrgPrefs
    } = this.props;

    const showActiveGiftCards = match.params.tab !== 'other';
    const giftCards = showActiveGiftCards ? activeVouchers : nonActiveVouchers;

    if (!posOrgId && !posOrgs.isEmpty()) {
      const posOrg = posOrgs.sortBy(o => o.get('companyName')).first();
      return (
        <Redirect to={getGiftCardUrl(routeParams, posOrg.get('id'))} />
      );
    }

    return (
      <div className="columns-container">
        <div className="columns-content">
          <div className="columns-content-container width-large">
            <div className="columns-content-body with-padding">
              <FilterContainer>
                <div className="form-group">
                  <div className="select-container">
                    <select className="form-control" value={posOrgId} onChange={this.changePosOrgFilter}>
                      {posOrgs.sortBy(o => o.get('companyName')).map((item) => {
                        const { id, companyName } = item.toObject();
                        return <option key={id} value={id}>{companyName}</option>;
                      })}
                    </select>
                  </div>
                </div>
                <Button small primary onClick={this.showAddGiftCard}>Importera presentkort</Button>
              </FilterContainer>
              <NavTabsLink
                bottomMargin
                subUrl="admin/gift-cards"
                routeParams={routeParams}
                sections={tabSections}
              />
              {showActiveGiftCards && (
                <Panel>
                  <Row>
                    <FlexOne>
                      <ReportTextLabel>
                        <span>Antal aktiva</span>
                        <p className="mb0">{reportingCount(activeCount)}</p>
                      </ReportTextLabel>
                    </FlexOne>
                    <FlexOne>
                      <ReportTextLabel>
                        <span>Återstående värde</span>
                        <p className="mb0">{reportingFormat(remainingValue)}</p>
                      </ReportTextLabel>
                    </FlexOne>
                  </Row>
                </Panel>
              )}
              <table className="table table-striped select-text">
                <thead>
                  <tr>
                    <th>Nummer/kod</th>
                    <th>Förmånstagare</th>
                    <th>Värde <span className="text-muted">(kvar)</span></th>
                    <th>Giltigt t.o.m.</th>
                    <th>Status</th>
                    <th>Inlöst</th>
                    <th>Typ</th>
                    <th className="print-hidden" />
                  </tr>
                </thead>
                <tbody>
                  {giftCards && giftCards.map(giftCard => {
                    const {
                      id, code, beneficiary, accountingType, vatPct, initialValue, remainingValue,
                      noRedeemAfterTs, voucherStatus, lastRedeemTs
                    } = giftCard.toJS();
                    const canChange = voucherStatus !== 'Void';
                    const showRemaining = voucherStatus === 'RedeemedPartial';

                    return (
                      <tr key={id}>
                        <td>{code}</td>
                        <td>{beneficiary}</td>
                        <td>
                          {CurrencyUtil.accountCurrency(initialValue, 0)}{' '}
                          {showRemaining && (
                            <span className="text-muted">
                              ({CurrencyUtil.accountCurrency(remainingValue, 0)})
                            </span>
                          )}
                        </td>
                        <td>{noRedeemAfterTs ? moment(noRedeemAfterTs).format('L') : '-'}</td>
                        <td>{getGiftCardStatus(voucherStatus)}</td>
                        <td>{lastRedeemTs ? moment(lastRedeemTs).format('L') : '-'}</td>
                        <td>{getGiftCardType(accountingType, vatPct)}</td>
                        <td className="text-right print-hidden">
                          {canChange && (
                            <Button tiny gray onClick={() => this.showEditModal(giftCard)}>Ändra</Button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {showAddGiftCard && (
                <GiftCardModal
                  posOrgPrefs={posOrgPrefs?.toJS()}
                  onSubmit={this.importGiftCards}
                  onClose={this.hideForm}
                />
              )}
              {showEditModal && (
                <GiftCardEditModal
                  giftCard={showEditModal}
                  onSubmit={this.handleEditGiftCard}
                  onClose={this.hideEditModal}
                  onVoid={this.handleVoidGiftCard}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { posOrgs, giftCards } = state;
  const posOrgId = props.match.params.id;
  const routeParams = {
    ...props.match.params,
    entityId: posOrgId
  };
  const posOrg = posOrgs.find(o => o.get('id') === parseInt(posOrgId));

  return {
    posOrgs,
    posOrgId,
    routeParams,
    posOrgPrefs: posOrg?.get('prefs'),
    activeVouchers: giftCards.get('activeVouchers'),
    nonActiveVouchers: giftCards.get('nonActiveVouchers'),
    activeCount: giftCards.get('activeCount'),
    remainingValue: giftCards.get('remainingValue')
  };
};

const mapDispatchToProps = dispatch => ({
  fetchGiftCards: posOrgId => dispatch(fetchGiftCards(posOrgId)),
  importGiftCards: (posOrgId, giftCards) => dispatch(importGiftCards(posOrgId, giftCards)),
  voidGiftCard: id => dispatch(voidGiftCard(id)),
  updateGiftCard: (id, values) => dispatch(updateGiftCard(id, values))
});

export default connect(mapStateToProps, mapDispatchToProps)(GiftCards);
