import { createSelector } from 'reselect';
import { NEW_BOOKING } from '@Utils/booking-util';
import { getGroupUrl, getResourceUrl } from '@Utils/navigate';
import { getResourceIdsFromSearch } from '../utils/url-util';

const emptyArray = [];
const getOrderedResourceIds = (orderedGroups) => {
  const otherResources = orderedGroups?.filter(g => g.get('id') !== 0)
    .reduce((a, g) => a.concat(g.get('resourceIds').toArray()), []);

  const myGroup = orderedGroups?.find(g => g.get('id') === 0);
  const myResources = myGroup?.get('resourceIds').toArray()
    .filter(id => !otherResources.includes(id)) || [];

  return [...myResources, ...otherResources];
};

export const getResourcesInView = createSelector(
  state => state.orderedGroups,
  state => state.resourcesById,
  (state, props) => props.routeParams,
  (orderedGroups, resourcesById, routeParams) => {
    const { entityType, entityId, search } = routeParams;
    const searchIds = search ? getResourceIdsFromSearch(search) : null;

    if (searchIds?.length > 0) {
      const resourceIds = getOrderedResourceIds(orderedGroups);

      return resourceIds.filter(id => searchIds.includes(id))
        .map((id) => resourcesById.get(id));
    }
    if (entityType === 'group') {
      const resourceIds = orderedGroups
        .find(g => g.get('id') === entityId)?.get('resourceIds');

      return resourceIds
        ? resourceIds.map((id) => resourcesById.get(id)).toArray()
        : emptyArray;
    }
    if (entityType === 'resource' || entityType === 'my-resource') {
      const resource = resourcesById.get(entityId);
      return resource ? [resource] : emptyArray;
    }
    return emptyArray;
  }
);

export const getOrderedResources = createSelector(
  state => state.orderedGroups,
  state => state.resourcesById,
  (orderedGroups, resourcesById) => {
    const resourceIds = getOrderedResourceIds(orderedGroups);
    return resourceIds?.map(id => resourcesById.get(id));
  }
);

export const getOrderedPrimaryResources = createSelector(
  getOrderedResources,
  (orderedResources) => {
    return orderedResources.filter(r => r.resourceType === 'Person');
  }
);

export const getIsMultiResourceView = createSelector(
  getResourcesInView,
  (state, props) => props.routeParams,
  (resourcesInView, routeParams) => {
    const { viewMode } = routeParams;
    return viewMode === 'week' && resourcesInView.length > 1;
  }
);

export const getResourceFromColIdx = createSelector(
  getResourcesInView,
  (state, props) => props.routeParams,
  (state, props) => props.colIdx,
  (resourcesInView, routeParams, colIdx) => {
    const { viewMode } = routeParams;

    if (viewMode === 'day') {
      return resourcesInView[colIdx];
    }
    if (resourcesInView.length === 1) {
      return resourcesInView[0];
    }
    return null;
  }
);

export const getServiceColors = createSelector(
  state => state.servicesById,
  state => state.orderedServiceGroups,
  (servicesById, groups) => {
    const serviceColors = {};
    servicesById?.valueSeq().forEach((service) => {
      const group = groups.find(g => g.get('serviceIds').contains(service.id));
      const color = service.prefs?.colorway || group?.get('color');
      if (color) {
        serviceColors[service.id] = color;
      }
    });
    return serviceColors;
  }
);

export const getServicesForResources = createSelector(
  state => state.resourceServices,
  state => state.bkf.get('resourceId'),
  state => state.bkf.get('type'),
  (resourceServices, resourceId, bookingType) => {
    const services = resourceServices.get(resourceId) || emptyArray;
    return services.filter(s => s.bookingType === bookingType);
  }
);

export const getServicesForResourcesInView = createSelector(
  getResourcesInView,
  state => state.servicesById,
  state => state.resourceServiceIds,
  (resources, services, mappings) => {
    return services.valueSeq().filter(service => {
      return resources.some(resource => {
        return mappings.get(resource.id).includes(service.id);
      });
    }).toJS() || emptyArray;
  }
);

export const hasAnyResourceAllServices = createSelector(
  getResourcesInView,
  state => state.findTime.get('services'),
  state => state.resourceServiceIds,
  (resources, services, mappings) => {
    return resources.some(resource => {
      return services?.every(service => {
        return mappings.get(resource.id).includes(service.id);
      });
    });
  }
);

export const getAllRulesFulfilled = createSelector(
  state => state.bkf.get('resources'),
  state => state.bkf.get('services'),
  state => state.servicesById,
  (resources, services, servicesById) => {
    return services ? services.every(({ id }) => {
      const { multiResource, multiResourceRules } = servicesById.get(id) || {};
      return multiResource ? multiResourceRules.every(({ requiredResources, resourceIds }) => {
        if (requiredResources > 1) {
          const resourceCount = resourceIds.reduce((count, id) => {
            return count + (resources.has(id) ? 1 : 0);
          }, 0);
          return resourceCount >= requiredResources;
        }
        return resourceIds.some(id => resources.has(id));
      }) : true;
    }) : true;
  }
);

export const getAddonServices = createSelector(
  state => state.servicesById,
  (services) => {
    return services.valueSeq()
      .filter(service => service.addon)
      .sortBy(service => service.name)
      .toJS() || emptyArray;
  }
);

/*
Get all resources that is enabled through either service mappings or multi resource rules.
Also include any deleted or non matching resources that already exist on the booking.
*/
export const getResourcesForBookingForm = createSelector(
  getOrderedResources,
  state => state.servicesById,
  state => state.resourceServiceIds,
  state => state.bkf.get('resources'),
  state => state.bkf.get('services'),
  (resources, services, mappings, selectedResources, selectedServices) => {
    if (!selectedServices) {
      return resources;
    }
    return resources.filter(resource => {
      const serviceMappings = mappings.get(resource.id);
      const hasMapping = selectedServices.every(({ id }) => serviceMappings.includes(id));

      const ruleResourceIds = selectedServices.valueSeq().flatMap(({ id }) => {
        return services.get(id).multiResourceRules?.map(r => r.resourceIds) || [];
      }).reduce((array, ids) => array.concat(ids), []);

      const isInRule = ruleResourceIds.includes(resource.id);
      const isSelected = selectedResources.has(resource.id);

      return hasMapping || isInRule || isSelected;
    });
  }
);

export const getSingleResourceModeUrl = createSelector(
  getResourcesInView,
  state => state.orderedGroups,
  (state, props) => props.routeParams,
  (resourcesInView, orderedGroups, routeParams) => {
    const { viewMode } = routeParams;

    // Try to select the first group with selected resources, or the first group
    if (viewMode === 'day') {
      const firstGroupWithResource = orderedGroups.find(g => {
        return resourcesInView.some(r => g.get('resourceIds').includes(r.id));
      }) || orderedGroups.first();

      return getGroupUrl(firstGroupWithResource?.get('id') || 0, routeParams);
    }

    // For week view, try to select the first resource in selection
    let groupId = null;
    let resourceId = null;
    orderedGroups.every(g => {
      return g.get('resourceIds').every(id => {
        if (resourcesInView.map(r => r.id).includes(id)) {
          groupId = g.get('id');
          resourceId = id;
          return false;
        }
        return true;
      });
    });

    // Fall back to selecting first resource in first group
    if (!resourceId) {
      const group = orderedGroups.first();
      groupId = group.get('id');
      resourceId = group.get('resourceIds').first();
    }

    const view = groupId === 0 ? 'my-resource' : 'resource';
    return getResourceUrl(resourceId, view, 'week', routeParams);
  }
);

export const getDimmedBookingIds = createSelector(
  state => state.bookingsById,
  state => state.gridViewState.get('highlightResourceIds'),
  state => state.gridViewState.get('scheduleEditMode'),
  (bookingsById, highlightResourceIds, scheduleEditMode) => {
    if (scheduleEditMode) {
      return bookingsById.keySeq()
        .filter(id => id !== NEW_BOOKING)
        .toArray();
    }
    if (!bookingsById || !highlightResourceIds) {
      return emptyArray;
    }
    return bookingsById.valueSeq().filter(({ resources }) => {
      return highlightResourceIds.every(id => resources.every(r => r.id !== id));
    }).map(({ id }) => id).toArray();
  }
);
