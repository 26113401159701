import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  change, Field, FieldArray, formValueSelector, reduxForm
} from 'redux-form';
import { payment } from '@Utils/preference-keys';
import { getFeatures, getVatRates, getWebPaymentEnabled } from '@State/selectors';
import SubmitButton from '@Components/ui/submit-button';
import Popover from '@Components/ui/popover';
import ColorPicker from '@Components/inputs/color-picker';
import CurrencyUtil from '@Utils/currency-util';
import { required, number, minValue } from '@Utils/validation';
import { isServiceMappingDiff, serviceMappingValues } from '@State/services-actions';
import CheckBoxChild from '@Components/inputs/checkbox-child';
import { Panel } from '../ui/styled/main';
import TextInput from '../inputs/text-input';
import CheckBox from '../inputs/checkbox';
import MultiVatForm from './multi-vat-form';

class EditServiceForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteProgress: false,
      showConfirmDelete: false
    };
  }

  onPaymentRequiredChange = (ev) => {
    if (!ev.target.checked) {
      this.props.setFormValue('webAutoSelectFirstAvailableSlot', false);
    }
  };

  onShowInBookingChange = (ev) => {
    this.props.setFormValue('webAllowBooking', ev.target.checked);
  };

  toggleMultiVat = (ev) => {
    ev.preventDefault();
    ev.target.blur();

    const {
      multiVat, multiVatRows, name, price, vatPct
    } = this.props;

    if (multiVat) {
      this.props.setFormValue('price', multiVatRows.reduce((a, b) => a + parseInt(b.price || 0), 0));
      this.props.setFormValue('vatPct', multiVatRows[0].vatPct);
    } else {
      this.props.setFormValue('multiVatRows', [
        { description: name, price, vatPct }, {}
      ]);
    }

    this.props.setFormValue('multiVat', !multiVat);
  };

  showConfirmDelete = (ev) => {
    ev.preventDefault();
    this.setState({ showConfirmDelete: true, deleteProgress: false });
  };

  hideConfirmDelete = (ev) => {
    ev.preventDefault();
    this.setState({ showConfirmDelete: false });
  };

  onDelete = (ev) => {
    ev.preventDefault();
    this.setState({ deleteProgress: true });
    this.props.onDelete(this.props.serviceId)
      .catch(() => this.setState({ deleteProgress: false }));
  };

  render() {
    const {
      handleSubmit, multiVat, paymentEnabled, paymentRequired, isAddonService, resourceMappings,
      webShowInBooking, webAllowBooking, pricesExVat, vatRates, features, hasCustomDiffs, allowGroup,
      useDynamicPricing, isClassBooking
    } = this.props;
    const priceLabel = pricesExVat ? 'Pris (exkl. moms)' : 'Pris';
    const { EnableClassBooking, EnableDiscountVouchers } = features;

    return (
      <form onSubmit={handleSubmit}>
        {hasCustomDiffs && (
          <div className="alert alert-warning">
            Fält markerade med <i className="fa fa-fw fa-info-circle text-highlight-warn" /> har ett
            annat värde för en eller flera resurser. Se inställningar i listan till höger.
          </div>
        )}

        <Field name="name" component={TextInput} label="Namn" validate={[required]} />

        <div className="form-group">
          <label className="control-label">Beskrivning</label>
          <Field component="textarea" name="description" rows={3} className="form-control" />
        </div>

        <label className="control-label mt2">Tidsåtgång</label>
        <Panel>
          <Field
            name="serviceDuration"
            component={TextInput}
            label="Längd"
            width={120}
            unitLabel="min"
            helpText="Ange hur lång tid som ska reserveras vid bokning av den här tjänsten."
            validate={isAddonService ? [number, minValue(0)] : [required, number, minValue(1)]}
          />
          <Field
            name="afterTime"
            component={TextInput}
            label="Paus efter"
            width={120}
            unitLabel="min"
            helpText="Reservera extra tid efter bokningen, tex för städning."
            validate={[number, minValue(0)]}
          />
        </Panel>
        {!hasCustomDiffs && !pricesExVat && (
          <a href="#" className="pull-right mt2" onClick={this.toggleMultiVat}>
            {multiVat ? <><i className="fa-solid fa-toggle-on" /> Avancerad moms</> : <><i className="fa-solid fa-toggle-off" /> Avancerad moms</>}
          </a>
        )}
        <label className="control-label mt2">Inställningar för pris</label>
        {multiVat ? (
          <Panel>
            <FieldArray name="multiVatRows" component={MultiVatForm} />
          </Panel>
        ) : (
          <Panel>
            <table className="table-form">
              <tbody>
                <tr>
                  <td className="width-md">
                    <Field
                      name="price"
                      component={TextInput}
                      label={priceLabel}
                      unitLabel={CurrencyUtil.currencySymbol()}
                      validate={[number, minValue(0)]}
                    />
                  </td>
                  <td className="width-md">
                    <Field
                      name="priceEvening"
                      component={TextInput}
                      label="Pris kväll"
                      disabled={!useDynamicPricing}
                      unitLabel={CurrencyUtil.currencySymbol()}
                      validate={[number, minValue(0)]}
                    />
                  </td>
                  <td className="width-md">
                    <Field
                      name="priceHoliday"
                      component={TextInput}
                      label="Pris helg"
                      disabled={!useDynamicPricing}
                      unitLabel={CurrencyUtil.currencySymbol()}
                      validate={[number, minValue(0)]}
                    />
                  </td>
                  <td className="width-sm">
                    <div className="form-group">
                      <label htmlFor="vatPct" className="control-label">Moms</label>
                      <div className="select-container">
                        <Field
                          id="vatPct"
                          name="vatPct"
                          component="select"
                          className="form-control"
                          validate={[required]}
                        >
                          {vatRates.map(vat => <option key={vat} value={vat}>{`${vat}%`}</option>)}
                        </Field>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="form-group form-horizontal">
              <Field name="useDynamicPricing" component={CheckBox} label="Använd helg- och kvällspriser" />
              <Field name="priceFrom" component={CheckBox} label="Visa som från-pris" />
            </div>
          </Panel>
        )}

        {EnableClassBooking && isClassBooking && (
          <>
            <label className="control-label mt2">
              Inställningar för {isClassBooking ? 'klassbokning' : 'bokning'}
            </label>
            <Panel>
              {isClassBooking && (
                <>
                  <Field
                    name="maxSlots"
                    component={TextInput}
                    label="Totalt antal platser"
                    width={120}
                    unitLabel="st"
                    helpText="Ange hur många platser klassen har (går att ändra per pass)."
                    validate={[required, number, minValue(1)]}
                  />
                  <Field
                    name="webMinTimeBeforeBooking"
                    component={TextInput}
                    label="Stäng bokning innan"
                    width={120}
                    unitLabel="min"
                    helpText="Ange hur många minuter innan klassen som bokningen stängs."
                    validate={[required, number, minValue(1)]}
                  />
                </>
              )}
              {/*
              <div className="form-group form-horizontal">
                <Field name="allowGroup" component={CheckBox} label="Tillåt flera personer i sällskap" onChange={this.onShowInBookingChange} />
              </div>
              {allowGroup && (
                <Field
                  name="maxSlotsPerPerson"
                  component={TextInput}
                  label="Max antal per sällskap"
                  width={120}
                  unitLabel="st"
                  helpText="Ange hur många personer som mest får bokas i ett sällskap."
                  validate={[required, number, minValue(1)]}
                />
              )}
              */}
            </Panel>
          </>
        )}

        <label className="control-label mt2">Inställningar för onlinebokning</label>
        <Panel>
          <div className="form-group form-horizontal">
            <Field name="webShowInBooking" component={CheckBox} label="Visa i onlinebokning" onChange={this.onShowInBookingChange} />
            <div className={webShowInBooking ? 'checkbox checkbox-child' : 'checkbox checkbox-child disabled'}>
              <Field name="webShowDuration" component={CheckBoxChild}>
                Visa längd
              </Field>
              <Field name="webShowPrice" component={CheckBoxChild}>
                Visa pris
              </Field>
            </div>
          </div>

          {!isAddonService && (
            <div className={webShowInBooking ? 'form-group form-horizontal' : 'form-group form-horizontal disabled'}>
              <Field name="webAllowBooking" component={CheckBox} label="Tillåt bokning" />
              <div className="checkbox-description">
                Om tjänsten visas i onlinebokning men bokning inte är tillåten
                visas texten "Kontakta oss för bokning".
              </div>
            </div>
          )}

          {paymentEnabled && !isAddonService && (
            <div className="form-group form-horizontal">
              <Field name="webPaymentRequired" component={CheckBox} label="Betalas vid bokning" disabled={!webAllowBooking} onChange={this.onPaymentRequiredChange} />
              <div className={webAllowBooking && paymentRequired ? 'checkbox checkbox-child' : 'checkbox checkbox-child disabled'}>
                <Field name="webAllowPayOnSite" component={CheckBoxChild}>
                  Tillåt betalning på plats
                </Field>
                {EnableDiscountVouchers && (
                  <Field name="webAllowDiscountVoucher" component={CheckBoxChild}>
                    Tillåt rabattkod vid betalning
                  </Field>
                )}
                <Field name="webAutoSelectFirstAvailableSlot" component={CheckBoxChild}>
                  Endast betalning <span className="text-muted">(hoppa över val av tid)</span>
                </Field>
              </div>
            </div>
          )}
        </Panel>

        <label className="control-label mt2">Färg i kalendern</label>
        <Field component={ColorPicker} name="colorway" />
        <br />

        {resourceMappings.isEmpty() && (
          <div className="alert alert-danger">
            <i className="fa fa-exclamation-circle" />&nbsp;
            OBS! Inga resurser är valda för denna tjänst. För att kunna bokas
            online behöver du välja vilka resurser som utför tjänsten i listan
            till höger.
          </div>
        )}

        <div className="form-group">
          <SubmitButton {...this.props} /> &nbsp;
          <Popover isOpen={this.state.showConfirmDelete} body={this.popoverContent()} onOuterAction={this.hideConfirmDelete}>
            <button className="btn-delete" tabIndex="4" onClick={this.showConfirmDelete}>Ta bort</button>
          </Popover>
        </div>
      </form>
    );
  }

  popoverContent() {
    return (
      <div className="Popover-content-small">
        <a href="#" onClick={this.hideConfirmDelete} className="Popover-close"><i className="fa fa-lg fa-times" /></a>
        Är du säker på att du vill ta bort tjänsten? Detta går inte att ångra!
        <br />
        <br />
        <button className="btn-delete btn-block" onClick={this.onDelete} disabled={this.state.deleteProgress}>Ta bort tjänst</button>
      </div>
    );
  }
}

const warnings = (values, { resourceMappings }) => {
  if (resourceMappings.size === 0) {
    return null;
  }
  const warnings = {};
  const message = 'En eller flera resurser har egna inställningar med andra värden';
  for (let i = 0; i < serviceMappingValues.length; i++) {
    if (isServiceMappingDiff(values, resourceMappings, serviceMappingValues[i])) {
      warnings[serviceMappingValues[i]] = message;
    }
  }
  return warnings;
};

const selector = formValueSelector('edit-service-form');

const mapStateToProps = (state) => {
  return {
    vatRates: getVatRates(state),
    name: selector(state, 'name'),
    allowGroup: selector(state, 'allowGroup'),
    price: selector(state, 'price'),
    vatPct: selector(state, 'vatPct'),
    multiVat: selector(state, 'multiVat'),
    multiVatRows: selector(state, 'multiVatRows'),
    paymentEnabled: getWebPaymentEnabled(state),
    paymentRequired: selector(state, 'webPaymentRequired'),
    webShowInBooking: selector(state, 'webShowInBooking'),
    webAllowBooking: selector(state, 'webAllowBooking'),
    useDynamicPricing: selector(state, 'useDynamicPricing'),
    pricesExVat: state.locationConfig.get(payment.pricesExVat),
    features: getFeatures(state)
  };
};

const mapDispatchToProps = dispatch => ({
  setFormValue: (property, value) => {
    dispatch(change('edit-service-form', property, value));
  }
});

export default reduxForm({
  form: 'edit-service-form',
  warn: warnings,
  shouldWarn: () => true
})(connect(mapStateToProps, mapDispatchToProps)(EditServiceForm));
