import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import groupBy from 'lodash/groupBy';

import { useDispatch, useSelector } from 'react-redux';
import Text from '@Components/ui/styled/text-element';
import Loader from '@Components/ui/loader';
import styled from 'styled-components';
import StaffEntryRow from '@Components/staff/staff-entry-row';
import { colors } from '@Components/ui/styled/variables';
import Panel from '@Components/ui/styled/panel';
import { fetchStaffEntries, sortStaffEntries } from '@State/staff-actions';

const EntriesBlock = styled(Panel)`
  padding: 0 10px;
  margin-top: 12px;
  margin-bottom: 0;
`;

const getEntries = (entriesState) => {
  let entries = entriesState?.filter((entry) => !!entry.endTime);

  entries = groupBy(entries, ({ startTime }) => moment(startTime).startOf('day').format());

  entries = Object.entries(entries)
    .map(([, data]) => data.sort(sortStaffEntries));
  return entries;
};

const StaffUserDetailEntries = ({ userId }) => {
  const dispatch = useDispatch();
  const staffEntriesState = useSelector(({ staffUserEntriesState }) => staffUserEntriesState);
  const [entriesState, setEntriesState] = useState(staffEntriesState?.toJS());

  const [loadingEntries, setLoadingEntries] = useState(true);

  const fetchStaffEntriesAction = () => {
    setLoadingEntries(true);
    return dispatch(fetchStaffEntries(userId))
      .then(() => {
        setLoadingEntries(false);
      });
  };

  useEffect(() => {
    fetchStaffEntriesAction();
  }, [userId]);

  useEffect(() => {
    if (loadingEntries) {
      fetchStaffEntriesAction();
    }
  }, []);

  useEffect(() => {
    setEntriesState(staffEntriesState?.toJS());
  }, [staffEntriesState]);

  const entries = useCallback(getEntries(entriesState), [entriesState]);
  if (loadingEntries) return (<Loader />);

  return (
    <>
      {entries.length > 0 && <h4 className="mt3">Senaste 90 dagarna</h4>}
      {Object.entries(entries).map(([day, entriesInDay]) => (
        <EntriesBlock bgColor={colors.softGray20} key={day}>
          {entriesInDay.map(entry => (
            <StaffEntryRow
              key={entry.id}
              userId={userId}
              isAccessToChange
              {...entry}
            />
          ))}
        </EntriesBlock>
      ))}
    </>
  );
};

StaffUserDetailEntries.propTypes = {
  userId: PropTypes.number,
  loadingEntries: PropTypes.bool,
  fetchStaffEntriesAction: PropTypes.func
};

export default StaffUserDetailEntries;
