import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createGiftCard, validateGiftCardCode,
  getGiftCardConfig, fetchGiftCardById
} from '@State/gift-card-actions';
import { pos as posKeys } from '@Utils/preference-keys';
import { pauseScanner, resumeScanner } from '@Utils/barcode';
import { getGiftCardFromTemplate, getGiftCardPayload, getGiftCardWithDefaults } from '@Utils/gift-card-utils';
import useCustomer from '@Components/customers/useCustomer';
import { formatPno } from '@Utils/luhn-util';

import GiftCardSelectAmount from '@Components/pos/dialogs/gift-card-select-amount';
import GiftCardEnterCode from '@Components/pos/dialogs/gift-card-enter-code';
import GiftCardSummaryModal from '@Components/pos/dialogs/gift-card-summary-modal';
import GiftCardNotEnabled from '@Components/pos/dialogs/gift-card-not-enabled';

const DialogCreateGiftCard = ({
  posOrg, posUnit, posOrgPrefs, onSubmit, onClose, voucherTemplateId
}) => {
  const dispatch = useDispatch();
  const voucherTemplates = useSelector(state => state.voucherTemplates);
  const voucherTemplate = voucherTemplates?.find(t => t.get('id') === voucherTemplateId);
  const initialValue = voucherTemplate?.get('initialValue') || 0;

  const { selectedCustomer } = useCustomer();
  const hasSelectedCustomer = !!selectedCustomer?.id;

  const [step, setStep] = useState(initialValue > 0 ? 'code' : 'amount');
  const [amount, setAmount] = useState(initialValue);
  const [config, setConfig] = useState(null);
  const [voucherCode, setVoucherCode] = useState('');
  const [validation, setValidation] = useState();
  const [enabled, setEnabled] = useState(posOrgPrefs[posKeys.paymentMethodGiftCard]);

  const title = 'Nytt presentkort';
  const vunitId = posUnit && posUnit.get('vunitId');

  useEffect(() => {
    if (vunitId) {
      dispatch(getGiftCardConfig(vunitId))
        .then(config => setConfig(config));
    }
  }, [vunitId]);

  useEffect(() => {
    pauseScanner();

    return () => {
      resumeScanner();
    };
  }, []);

  const handleGiftCardEnabled = useCallback(() => {
    setEnabled(true);
    setStep('amount');
  }, []);

  const handleSelectAmount = useCallback((amount) => {
    setAmount(amount);
    setStep('code');
  }, []);

  const validateCode = async (code) => {
    if (!code || code.length <= 2) {
      return;
    }
    const validation = await dispatch(validateGiftCardCode(code));
    setValidation(validation);
  };

  const handleSubmitCode = useCallback(({ voucherCode }) => {
    setVoucherCode(voucherCode);
    setStep('confirm');
  }, []);

  const handleCreateGiftCard = useCallback((giftCard) => {
    if (validation?.voucherId) {
      return dispatch(fetchGiftCardById(validation?.voucherId))
        .then(response => onSubmit({ ...response, initialValue: amount }));
    }
    return dispatch(createGiftCard(vunitId, getGiftCardPayload(giftCard)))
      .then(giftCard => onSubmit(giftCard));
  }, [vunitId, amount, voucherCode]);

  if (!enabled) {
    return (
      <GiftCardNotEnabled
        posOrg={posOrg}
        posUnit={posUnit}
        posOrgPrefs={posOrgPrefs}
        onCreateGiftCard={handleGiftCardEnabled}
        onClose={onClose}
      />
    );
  }

  if (step === 'amount') {
    return (
      <GiftCardSelectAmount
        title="Välj belopp"
        amount={amount}
        onSubmit={handleSelectAmount}
        onClose={onClose}
      />
    );
  }
  if (step === 'code') {
    return (
      <GiftCardEnterCode
        title={title}
        validation={validation}
        onValidate={validateCode}
        onBack={!voucherTemplate ? () => setStep('amount') : null}
        onSubmit={handleSubmitCode}
        onClose={onClose}
      />
    );
  }

  const giftCard = voucherTemplate
    ? getGiftCardFromTemplate(voucherCode, amount, voucherTemplate.toJS())
    : getGiftCardWithDefaults(voucherCode, amount, config);

  const initialValues = hasSelectedCustomer ? {
    beneficiary: [
      selectedCustomer.name,
      formatPno(selectedCustomer.officialIdNo)
    ].filter(s => s).join(', ')
  } : null;

  return (
    <GiftCardSummaryModal
      title={title}
      submitText="Skapa presentkort"
      giftCard={giftCard}
      initialValues={initialValues}
      onSubmit={handleCreateGiftCard}
      onBack={() => setStep('code')}
      onClose={onClose}
    />
  );
};

export default DialogCreateGiftCard;
