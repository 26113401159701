import { defineMessages } from 'react-intl';

export default defineMessages({
  btnEdit: {
    id: 'customers.info.btnEdit',
    defaultMessage: 'Edit'
  },
  lblCustomerDetails: {
    id: 'customers.info.lblCustomerDetails',
    defaultMessage: 'Customer details'
  },
  lblCompany: {
    id: 'customers.info.lblCompany',
    defaultMessage: 'Company'
  },
  lblContactPerson: {
    id: 'customers.info.lblContactPerson',
    defaultMessage: 'Contact person'
  },
  lblInvoiceCustomer: {
    id: 'customers.info.lblInvoiceCustomer',
    defaultMessage: 'Invoice details'
  },
  lblName: {
    id: 'customers.info.lblName',
    defaultMessage: 'Name'
  },
  lblCompanyName: {
    id: 'customers.info.lblCompanyName',
    defaultMessage: 'Company name'
  },
  lblDescription: {
    id: 'customers.info.lblDescription',
    defaultMessage: 'Description'
  },
  lblPhoneNumber: {
    id: 'customers.info.lblPhoneNumber',
    defaultMessage: 'Phone number'
  },
  lblOtherPhoneNumber: {
    id: 'customers.info.lblOtherPhoneNumber',
    defaultMessage: 'Other phone number'
  },
  lblEmail: {
    id: 'customers.info.lblEmail',
    defaultMessage: 'E-mail'
  },
  lblPersonalIDNo: {
    id: 'customers.info.lblPersonalIDNo',
    defaultMessage: 'Personal ID number'
  },
  lblOrgNo: {
    id: 'customers.info.lblOrgNo',
    defaultMessage: 'Organisational number'
  },
  lblVehicleRegNo: {
    id: 'customers.info.lblVehicleRegNo',
    defaultMessage: 'Vehicle registration number'
  },
  lblGender: {
    id: 'customers.info.lblGender',
    defaultMessage: 'Gender'
  },
  lblGenderFemale: {
    id: 'customers.info.lblGenderFemale',
    defaultMessage: 'Female'
  },
  lblGenderMale: {
    id: 'customers.info.lblGenderMale',
    defaultMessage: 'Male'
  },
  lblAddress: {
    id: 'customers.info.lblAddress',
    defaultMessage: 'Address'
  },
  lblAddCustomerDetails: {
    id: 'customers.info.lblAddCustomerDetails',
    defaultMessage: 'Add customer details...'
  },
  lblAddInvoiceCustomer: {
    id: 'customers.info.lblAddInvoiceCustomer',
    defaultMessage: 'Add invoice details...'
  },
  lblAddToCompany: {
    id: 'customers.info.lblAddToCompany',
    defaultMessage: 'Add to company...'
  },
  lblAddContact: {
    id: 'customers.info.lblAddContact',
    defaultMessage: 'Add contact person...'
  },
  lblAddressLine1: {
    id: 'customers.info.lblAddressLine1',
    defaultMessage: 'Address line 1'
  },
  lblAddressLine2: {
    id: 'customers.info.lblAddressLine2',
    defaultMessage: 'Address line 2'
  },
  lblAddressLine3: {
    id: 'customers.info.lblAddressLine3',
    defaultMessage: 'Address line 3'
  },
  lblPostCode: {
    id: 'customers.info.lblPostCode',
    defaultMessage: 'Post code'
  },
  lblArea: {
    id: 'customers.info.lblArea',
    defaultMessage: 'City'
  },
  lblCountry: {
    id: 'customers.info.lblCountry',
    defaultMessage: 'Country'
  }
});
