import axios from 'axios';
import moment from 'moment';
import {
  axiosDefault, axiosErrorHandler, axiosGet, axiosPatch, axiosPost, axiosPut,
  prefixSearchUrl, prefixUrl
} from '@Utils/ajax-util';
import { getFeatures } from '@State/selectors';
import { getSaleBookingIds } from '@State/pos-selectors';
import { resourceFromColIdx } from '@Components/calendar/grid/grid-state-helper';
import { confirmMoveBooking } from '@Utils/booking-util';

export const REQUEST_BOOKINGS = 'REQUEST_BOOKINGS';
export const MOVE_BOOKING = 'MOVE_BOOKING';
export const REVERT_BOOKING = 'REVERT_BOOKING';
export const CHANGE_BOOKING = 'CHANGE_BOOKING';
export const CHANGE_CLASS_BOOKING = 'CHANGE_CLASS_BOOKING';
export const CONFIRM_BOOKING = 'CONFIRM_BOOKING';
export const ADD_BOOKING = 'ADD_BOOKING';
export const DELETE_BOOKING = 'DELETE_BOOKING';
export const CANCEL_BOOKING = 'CANCEL_BOOKING';
export const REFUND_BOOKING = 'REFUND_BOOKING';
export const CHANGE_BOOKING_STATUS = 'CHANGE_BOOKING_STATUS';
export const CHANGE_BOOKING_ATTRIBUTE = 'CHANGE_BOOKING_ATTRIBUTE';
export const CHANGE_BOOKING_TYPE = 'CHANGE_BOOKING_TYPE';
export const SET_UNDOABLE_BOOKING = 'SET_UNDOABLE_BOOKING';
export const SEARCH_BOOKINGS = 'SEARCH_BOOKINGS';
export const RESET_SEARCH = 'RESET_SEARCH';
export const SET_SEARCH_SCROLL_POS = 'SET_SEARCH_SCROLL_POS';
export const SHOW_SEARCH = 'SHOW_SEARCH';
export const CLEAR_BOOKINGS = 'CLEAR_BOOKINGS';
export const SET_BOOKINGS = 'SET_BOOKINGS';
export const IS_SEARCH_BOOKINGS = 'IS_SEARCH_BOOKINGS';
export const PRE_PAYMENT_ADDED = 'PRE_PAYMENT_ADDED';
export const POS_PRE_PAYMENTS_FETCHED = 'POS_PRE_PAYMENTS_FETCHED';

export function fetchBooking(id) {
  const url = prefixUrl(`/bookings/${id}`);

  return axiosGet(url, {
    onSuccess: res => res.data
  });
}

export function changeBookingAttribute(change) {
  const { bookingId, customerIds = [], attributes } = change;
  const url = prefixUrl(`/bookings/${bookingId}/attribs`);

  return dispatch => dispatch(axiosPatch(url, { customerIds, attributes }, {
    onSuccess: () => dispatch(bookingAttributeChanged(change)),
    onError: () => dispatch(revertBooking(bookingId))
  }));
}

export function bookingAttributeChanged(change) {
  return {
    type: CHANGE_BOOKING_ATTRIBUTE,
    change
  };
}

export function cancelBooking(data) {
  const { bookingId, customerIds, options } = data;
  const url = prefixUrl(`/bookings/${bookingId}/cancel`);

  return dispatch => dispatch(axiosPost(url, { customerIds, ...options }, {
    onSuccess: () => {
      if (options?.deleteBooking) {
        dispatch(bookingDeleted(bookingId));
      } else {
        const changes = {
          cancelledTs: moment(),
          cancelledChannel: 'Cal',
          status: 'Cancelled',
          cancelled: true
        };
        dispatch(bookingCancelled(bookingId, customerIds, changes));
      }
    },
    onError: () => dispatch(revertBooking(bookingId))
  }));
}

export function bookingCancelled(id, customerIds, changes) {
  return {
    type: CANCEL_BOOKING,
    id,
    customerIds,
    changes
  };
}

export function bookingDeleted(id) {
  return {
    type: DELETE_BOOKING,
    id
  };
}

export function refundBooking(id, customerBookingId) {
  const url = prefixUrl(`/bookings/${customerBookingId}/refund`);

  return dispatch => dispatch(axiosPost(url, null, {
    onSuccess: res => dispatch(bookingRefunded(id, res.data)),
    onError: () => dispatch(revertBooking(id))
  }));
}

export function bookingRefunded(id, refund) {
  return {
    type: REFUND_BOOKING,
    id,
    refund
  };
}

export function sendBookingConfirmation(data) {
  const { bookingId, customerIds, options } = data;
  const url = prefixUrl(`/bookings/${bookingId}/send-confirmation`);
  return axiosPost(url, { customerIds, ...options });
}

export function sendBookingReceipt({ paymentRef, toName, toEmail }) {
  const url = prefixUrl(`/receipts/${paymentRef}/email-copy`);
  return axiosPost(url, { toName, toEmail });
}

export function changeBookingStatus(change) {
  const { bookingId, customerIds = [], status } = change;
  const url = prefixUrl(`/bookings/${bookingId}/status/${status}`);

  return dispatch => dispatch(axiosPut(url, { customerIds }, {
    onSuccess: () => dispatch(bookingStatusChanged(change)),
    onError: () => dispatch(revertBooking(bookingId))
  }));
}

export function setClassBookingMaxSlots(bookingId, maxSlots) {
  const url = prefixUrl(`/bookings/${bookingId}/class-max-slots`);
  return dispatch => dispatch(axiosPut(url, { maxSlots }, {
    onSuccess: () => dispatch(classBookingChanged(bookingId, { maxSlots }))
  }));
}

export function setClassBookingClosed(bookingId, bookingClosed) {
  const url = prefixUrl(`/bookings/${bookingId}/class-booking-closed`);
  return dispatch => dispatch(axiosPut(url, { closed: bookingClosed }, {
    onSuccess: () => dispatch(classBookingChanged(bookingId, { bookingClosed }))
  }));
}

export function bookingStatusChanged(change) {
  return {
    type: CHANGE_BOOKING_STATUS,
    change
  };
}

export function bookingTypeChanged(change) {
  return {
    type: CHANGE_BOOKING_TYPE,
    change
  };
}

export function bookingChanged(id, booking) {
  return {
    type: CHANGE_BOOKING,
    id,
    booking
  };
}

export function classBookingChanged(id, changes) {
  return {
    type: CHANGE_CLASS_BOOKING,
    id,
    ...changes
  };
}

export function bookingConfirmed(id, booking, customer) {
  return {
    type: CONFIRM_BOOKING,
    id,
    booking,
    customer
  };
}

export function removeTempBooking(id) {
  return bookingDeleted(id);
}

export function addTempBooking(booking) {
  return {
    type: ADD_BOOKING,
    booking,
    hideBookingForm: false
  };
}

export function bookingAdded(booking) {
  return {
    type: ADD_BOOKING,
    booking
  };
}

export function setUndoableBooking(booking) {
  return {
    type: SET_UNDOABLE_BOOKING,
    booking
  };
}

export function undoMove(routeParams) {
  return (dispatch, getState) => {
    const undoState = getState().gridViewState.get('undoableBooking');
    if (undoState != null) {
      dispatch(setUndoableBooking(null));
      dispatch(moveBooking(undoState.toJS(), routeParams, true));
    }
  };
}

export function getUndoState(booking, move) {
  return {
    ...booking,
    sourceResourceId: move.targetResourceId,
    targetResourceId: move.sourceResourceId
  };
}

export function moveBooking(move, routeParams, isUndo = false) {
  return (dispatch, getState) => {
    const state = getState();
    const { bookingsById, locationConfig } = state;

    const booking = bookingsById.get(move.id);
    const sourceResourceId = resourceFromColIdx(state, routeParams, move.sourceColIdx)?.id || booking.resourceId;
    const targetResourceId = resourceFromColIdx(state, routeParams, move.colIdx)?.id || booking.resourceId;
    const isUndoable = !confirmMoveBooking(locationConfig, booking);

    if (sourceResourceId !== targetResourceId && booking.resources.some(r => r.id === targetResourceId)) {
      console.error('Cannot move to existing resource for booking');
      dispatch(revertBooking(move.id, booking));
      return;
    }

    const moveEvent = isUndo ? move : {
      ...move,
      sourceResourceId,
      targetResourceId
    };
    const undoState = isUndo ? null : getUndoState(booking, moveEvent);
    const confirmations = isUndoable ? {} : {
      sendSmsConfirmation: isUndo ? false : moveEvent.sendSmsConfirmation,
      sendEmailConfirmation: isUndo ? false : moveEvent.sendEmailConfirmation
    };

    const body = {
      ...confirmations,
      startTime: moveEvent.startTime.toISOString(),
      endTime: moveEvent.endTime.toISOString(),
      sourceResourceId: moveEvent.sourceResourceId,
      targetResourceId: moveEvent.targetResourceId
    };

    const url = prefixUrl(`/bookings/${moveEvent.id}/move`);
    return dispatch(axiosPost(url, body, {
      onSuccess: res => {
        dispatch(bookingMoved({ ...moveEvent, ...res.data }, isUndo));
        dispatch(setUndoableBooking(isUndoable ? undoState : null));
      },
      onError: () => dispatch(revertBooking(moveEvent.id))
    }));
  };
}

export function revertBooking(bookingId, booking) {
  return {
    type: REVERT_BOOKING,
    bookingId,
    booking
  };
}

export function bookingMoved(moveEvent, isUndo) {
  return {
    type: MOVE_BOOKING,
    booking: moveEvent,
    isUndo
  };
}

export function showSearch() {
  return {
    type: SHOW_SEARCH
  };
}

export function resetSearch() {
  return {
    type: RESET_SEARCH
  };
}

export function saveSearchScrollPos(scrollPos) {
  return {
    type: SET_SEARCH_SCROLL_POS,
    scrollPos
  };
}

export function isSearching(isSearching = false) {
  return {
    type: IS_SEARCH_BOOKINGS,
    isSearching
  };
}

export function searchBookings(query) {
  return (dispatch, getState) => {
    dispatch(isSearching(true));
    if (query && query.length > 0) {
      dispatch(showSearch());
    }

    if (!query || query.length <= 2) {
      dispatch({ type: SEARCH_BOOKINGS, query, bookings: [] });
      dispatch(isSearching());
      return Promise.resolve();
    }

    const url = `/search/bookings?query=${encodeURIComponent(query)}`;
    const prefixedUrl = prefixSearchUrl(url, getState());
    const config = axiosDefault();

    dispatch(isSearching(true));
    return axios.get(prefixedUrl, config)
      .then(({ data }) => {
        dispatch(isSearching());
        dispatch({ type: SEARCH_BOOKINGS, query, bookings: data.result });
      })
      .catch(error => {
        dispatch(isSearching());
        axiosErrorHandler(error, dispatch);
      });
  };
}

export function clearBookings() {
  return {
    type: CLEAR_BOOKINGS
  };
}

export function resetBookings() {
  return (dispatch, getState) => {
    const { bookingsById } = getState();
    const bks = bookingsById.valueSeq().toJS();
    dispatch(clearBookings());
    setTimeout(() => dispatch(setBookings(bks)), 1);
  };
}
export function setBookings(bookings) {
  return {
    type: SET_BOOKINGS,
    bookings
  };
}

export function addExternalPayment(payment) {
  return (dispatch) => {
    const { posOrgId, bookingId, ...data } = payment;
    const url = prefixUrl(`/pos/sales/org/${posOrgId}/booking/${bookingId}/external-payment/`);

    return dispatch(axiosPost(url, data, {
      throwOnError: true,
      onSuccess: (res) => {
        const sale = res.data.saleId ? { ...res.data } : null;
        const newPayment = {
          amount: Number(data.paymentAmount),
          transactionDate: data.paymentDate,
          paymentType: 'PrePaid',
          source: data.source,
          providerRef: data.providerRef,
          paymentRef: res.data.paymentRef
        };
        dispatch({ type: PRE_PAYMENT_ADDED, bookingId, payment: newPayment, sale });
        return newPayment;
      }
    }));
  };
}

function fetchBookingPrePayments(bookingId) {
  return (dispatch) => {
    const url = prefixUrl(`/pos/sales/booking/${bookingId}/external-payment/`);

    return dispatch(axiosGet(url, {
      throwOnError: true,
      onSuccess: ({ payments }) => {
        dispatch({ type: POS_PRE_PAYMENTS_FETCHED, bookingId, prePayments: payments });
      }
    }));
  };
}

export function fetchSalePrePayments() {
  return (dispatch, getState) => {
    const state = getState();
    const bookingIds = getSaleBookingIds(state);
    const { EnablePrepaidBookings } = getFeatures(state);

    if (!EnablePrepaidBookings) {
      return;
    }

    return Promise.all(
      bookingIds.map(bookingId => {
        return dispatch(fetchBookingPrePayments(bookingId));
      })
    );
  };
}

export function completePrePayment({ paymentRef, email }) {
  return (dispatch) => {
    const receiptMethods = [];
    if (email) {
      receiptMethods.push(dispatch(sendBookingReceipt({ paymentRef, toEmail: email })));
    }
    return Promise.all(receiptMethods);
  };
}
