import React from 'react';
import { PanelWrapper } from '@Components/customers/customer-styles';
import { FormGroup } from '@Components/ui/styled/main';
import moment from 'moment';

const CustomerEmployeeDetails = ({
  officialIdNo, name, createdTs, lastBookingTs
}) => (
  <PanelWrapper defaultCursor>
    <FormGroup labelText="Namn" text={name || '-'} />
    <FormGroup labelText="Personummer" text={officialIdNo || '-'} />
    <FormGroup labelText="Tillagd" text={moment(createdTs).format('LLL')} />
    <FormGroup labelText="Senaste bokning" text={lastBookingTs ? moment(lastBookingTs).format('LLL') : '-'} />
  </PanelWrapper>
);

export default CustomerEmployeeDetails;
