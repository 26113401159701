import { colors } from '@Components/ui/styled/variables';
import { CustomerType } from './customer-util';
import { calendar } from './preference-keys';
import { txt } from './i18n-util';
import msg from './booking-util.msg';

export const NEW_BOOKING = 'DRAGGER';
export const isBookingNew = (props) => props.id === NEW_BOOKING;
export const isBookingCancelled = (props) => {
  const { attributes, status } = props;
  return (attributes?.status || status) === 'Cancelled';
};

const isType = ({ attributes, type }, value) => (attributes?.type || type) === value;
export const isSimpleBooking = (props) => isType(props, bookingTypes.SimpleBooking);
export const isClassBooking = (props) => isType(props, bookingTypes.ClassBooking);
export const isBookingReservation = (props) => isType(props, bookingTypes.Reservation);
export const isScheduleException = (props) => isType(props, bookingTypes.ScheduleException);
export const isClassWithBookings = (props) => isClassBooking(props) && props.bookedSlots > 0;

export function confirmMoveBooking(locationConfig, booking) {
  return locationConfig.get(calendar.confirmMoveEnabled)
    || booking.type === bookingTypes.ClassBooking;
}

export const bookingTypes = {
  SimpleBooking: 'SimpleBooking',
  ClassBooking: 'ClassBooking',
  Reservation: 'Reservation',
  ScheduleException: 'ScheduleException'
};

export const scheduleOpenTypes = {
  Normal: 'Normal',
  Overtime: 'Overtime'
};

export const scheduleTimeTypes = {
  Scheduled: 'Schemalagd tid',
  Journaled: 'Närvarotid',
  Overtime: 'Övertid',
  Qualifying: 'Karenstid',
  SickLeave80: 'Sjuk',
  SickChildLeave: 'VAB',
  Vacation: 'Semester',
  OnLeave: 'Tjänstledig',
  Furlough: 'Permission',
  WorkingHoursReduction: 'ATF',
  Absence: 'Frånvaro'
};

export function getDescription(description, services) {
  const hasServices = services && services.length > 0;
  const serviceDescriptions = hasServices ? services.map(s => s.name).join(', ') : '';
  return description || serviceDescriptions;
}

export function getPrimaryCustomer(props) {
  return isSimpleBooking(props)
    ? props.customers?.find(c => c.customerType === CustomerType.Person)
    : null;
}

export function getVehicleCustomer(props) {
  return isSimpleBooking(props)
    ? props.customers?.find(c => c.customerType === CustomerType.Vehicle)
    : null;
}

export function getCompanyCustomer(props) {
  return isSimpleBooking(props)
    ? props.customers?.find(c => c.customerType === CustomerType.Company)
    : null;
}

export function getClassStatus(bookedSlots = 0, maxSlots = 0, bookingClosed = false) {
  if (bookingClosed) {
    return {
      color: colors.darkGray40,
      description: txt(msg.bookingClosed),
      available: false
    };
  }
  if (bookedSlots === maxSlots) {
    return {
      color: colors.danger,
      description: txt(msg.classFull),
      available: false
    };
  }
  if (bookedSlots / maxSlots >= 0.7) {
    return {
      color: colors.warning,
      description: txt(msg.classAlmostFull),
      available: true
    };
  }
  return {
    color: colors.statusGreen,
    description: txt(msg.bookingOpen),
    available: true
  };
}
