import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { deployEnvironment } from '@Utils/config';
import { getLocationName, routeParams } from '@State/selectors';
import debounce from 'lodash/debounce';

import { updateViewDimensions } from '@State/view-actions';
import NetworkErrorAlertContainer from '@Components/dialogs/network-error-alert';
import VersionMismatchAlert from '@Components/dialogs/version-mismatch-alert';

import Nav from '@Components/nav/nav';
import Accounts from '@Components/sysadmin/accounts/accounts';
import NewAccount from '@Components/sysadmin/new-account/new-account';
import ReleaseNotes from '@Components/sysadmin/release-notes/release-notes';
import Tools from '@Components/sysadmin/tools/tools';

class SysAdmin extends Component {
  constructor(props) {
    super(props);
    this.setThemeFromProps(props);
    this.setPageTitleFromProps(props);
    this.updateDimensions = debounce(this.props.updateDimensions, 200);
  }

  handleResize = () => {
    this.updateDimensions();
  };

  handleOrientationChange = () => {
    setTimeout(() => {
      this.props.updateDimensions();
    }, 300);
  };

  UNSAFE_componentWillMount() {
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('orientationchange', this.handleOrientationChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('orientationchange', this.handleOrientationChange);
  }

  setPageTitleFromProps(props) {
    const title = 'Sysadmin';
    const env = deployEnvironment();
    let t;
    switch (env) {
      case 'Local':
        t = `🔷 ${title}`;
        break;
      case 'Preview':
        t = props.theme === 'yabie' ? title : `🔶 ${title}`;
        break;
      default:
        t = title;
    }
    document.title = t;
  }

  setThemeFromProps(props) {
    document.body.className = props.theme === 'yabie' ? 'yabie-theme' : null;
  }

  render() {
    const defaultUrl = '/sysadmin/a/accounts';
    return (
      <main className="main-container">
        <VersionMismatchAlert />
        <NetworkErrorAlertContainer />
        <Route path="/:org(sysadmin)/:loc/:section/:id?/:tabId?" component={Nav} />
        <section className="section-container sysadmin">
          <Switch>
            <Route exact path="/:org(sysadmin)/" render={() => <Redirect to={defaultUrl} />} />
            <Route path="/:org(sysadmin)/:loc/:section(accounts)/:id?/:tabId?" component={Accounts} />
            <Route path="/:org(sysadmin)/:loc/:section(new-account)" component={NewAccount} />
            <Route path="/:org(sysadmin)/:loc/:section(release-notes)/:id?" component={ReleaseNotes} />
            <Route path="/:org(sysadmin)/:loc/:section(tools)/" component={Tools} />
          </Switch>
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { appState, authState } = state;
  const username = authState.get('username');

  return {
    theme: appState.get('theme'),
    routeParams: routeParams(ownProps.match.params),
    locationName: getLocationName(state),
    username
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateDimensions: () => {
      dispatch(updateViewDimensions());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SysAdmin);
