import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import styled, { css } from 'styled-components';
import { colors, fs, fsw } from '@Components/ui/styled/variables';
import Button from './button';

const Wrapper = styled.div`
  display: flex;
  background-color: ${colors.softGray40};
  width: 100%;
  height: ${props => props.large ? '70px' : '56px'};
  padding: 6px 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  align-items: center;

  border: solid 2px ${colors.softGray40};
  ${p => p.align === 'center' && css`
    justify-content: center;
  `};
  ${p => p.align === 'below' && css`
    margin-top: auto;
    margin-bottom: 0;
  `};
  ${p => p.active && css`
    background-color: ${colors.softGray60};
    border: solid 2px ${colors.darkGray};
  `};
  ${p => p.noPanel && css`
    background-color: #fff;
    border: solid 2px #fff;
    padding: 2px;
  `};
`;

const CalcDisplayText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: flex-start;
  ${p => p.align === 'center' && css`
    align-items: center;
  `};
  ${p => p.isFullText && css`
    width: 100%;
  `};
`;

const CalcDisplayLabel = styled.div`
  font-size: 11px;
  font-weight: ${fsw.bold};
  color: ${colors.darkGray80};
  flex: 1;
  display: flex;
  align-items: flex-end;
  text-transform: uppercase;

  ${p => p.labelHighlight && css`
    color: ${colors.primaryLight};
  `};
  ${p => p.labelError && css`
    color: ${colors.danger};
  `};
`;

const CalcDisplayValue = styled.div`
  text-transform: none;
  font-size: 22px;
  line-height: ${props => props.large ? 'inherit' : '22px'};
  color: #000;
  margin: 0;
`;

const CalcDisplayButtonGroup = styled.div`
  margin-left: auto;
  text-align: right;
  height: 36px;
`;

const CalcDisplayButton = styled.button`
  color: ${colors.textMuted};
  margin: 0;
  width: 40px;
  height: 36px;
  outline: 0;
  background: ${colors.softGray80};
  border: 0;
  font-size: 16px;
  line-height: 16px;
  font-weight: ${fs.bold};
  border-radius: 6px 0 0 6px;

  &:last-child {
    border-left: 0;
    border-radius: 0 6px 6px 0;
  }

  &:disabled {
    opacity: 0.4;
    pointer-events: none !important;
  }

  &:active {
    background: white;
    color: ${colors.primaryDark};
    i {
      color: #fff;
    }
  }
  i.fa-minus {
    font-size: 11px;
  }
  i {
   font-size: 15px;
   color: ${colors.textMuted};
  }
  &.selected {
      background: ${colors.primaryDark};
      color: white;
      font-weight: 500;
      border: 1px solid ${colors.primaryDark};
    }
`;

const CalcDisplay = ({
  noPanel, onClick, active, align, large, label, labelHighlight, labelError,
  labelPosition = 'above', children, buttons, isFullText = false,
  presetButtons, onSelectPreset
}) => (
  <Wrapper noPanel={noPanel} active={active} align={align} large={large} onClick={onClick}>

    <CalcDisplayText align={align} isFullText={isFullText}>
      {labelPosition === 'above' && label && (
        <CalcDisplayLabel labelHighlight={labelHighlight} labelError={labelError}>{label}</CalcDisplayLabel>
      )}
      <CalcDisplayValue large={large}>{children}</CalcDisplayValue>
      {labelPosition === 'below' && label && (
        <CalcDisplayLabel labelHighlight={labelHighlight} labelError={labelError}>{label}</CalcDisplayLabel>
      )}
    </CalcDisplayText>
    {buttons
      && (
      <CalcDisplayButtonGroup>
        {buttons.map(({ text, icon, ...propsButton }, index) => (
          <CalcDisplayButton {...propsButton} key={index}>
            {text}
            {icon && <i className={icon} />}
          </CalcDisplayButton>
        ))}
      </CalcDisplayButtonGroup>
      )}
    {presetButtons
      && (
      <CalcDisplayButtonGroup>
        <ReactTooltip effect="solid" place="top" html />
        {presetButtons.map(({ label, tooltip, amount }, index) => (
          <Button tiny white key={index} onClick={() => onSelectPreset(amount)} data-tip={tooltip}>
            {label}
          </Button>
        ))}
      </CalcDisplayButtonGroup>
      )}
  </Wrapper>
);

CalcDisplay.propTypes = {
  label: PropTypes.string,
  align: PropTypes.string,
  labelPosition: PropTypes.string,
  labelHighlight: PropTypes.bool,
  large: PropTypes.bool,
  buttons: PropTypes.array
};

export default CalcDisplay;
