import { defineMessages } from 'react-intl';

export default defineMessages({
  noGiftCardsTitle: {
    id: 'customers.giftCards.noGiftCardsTitle',
    defaultMessage: 'No gift cards'
  },
  noGiftCardsText: {
    id: 'customers.giftCards.noGiftCardsText',
    defaultMessage: 'You can sell a gift card in the POS, or import a gift card here.'
  },
  lblDetails: {
    id: 'customers.giftCards.lblDetails',
    defaultMessage: 'Details'
  },
  btnImportGiftCard: {
    id: 'customers.giftCards.btnImportGiftCard',
    defaultMessage: 'Import gift card'
  }
});
