import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextEllipsis from '@Components/ui/styled/text-ellipsis';
import { fsw, colors } from '@Components/ui/styled/variables';

const LinkStyled = styled.a`
  font-size: 19px;
  color: ${colors.darkGray30};
  font-weight: ${fsw.book};
  display: flex;
  text-decoration: none;
  cursor: pointer;
  padding: 1px 0 2px 0;

  &:hover {
    text-decoration: none;
    color: ${colors.darkGray40};
  }
`;

const Text = styled(TextEllipsis)`
  font-weight: ${fsw.heavy};
  font-size: 17px;
  line-height: 30px;
  width: 300px;
  cursor: pointer;

`;

const InputStyled = styled.input`
  border-radius: 3px;
  box-shadow: none;
  font-size: 17px;
  font-weight: ${fsw.heavy};
  line-height: 30px;
  height: 30px;
  resize: none;
  display: block;
  width: 300px;
  background-color: #fff;
  border: 1px solid #3f709887;
  padding: 0 4px;
  margin: 0 -5px;
`;

const TextInputCustom = ({ value, onSave }) => {
  const inputRef = useRef(null);
  const [text, setText] = useState(value);
  const [isInputView, setIsInputView] = useState(false);

  const handleChangeText = (e) => {
    setText(e.target.value);
  };
  const handleShowInputView = () => {
    setIsInputView(true);
  };
  const handleESC = () => {
    setIsInputView(false);
    setText(value);

    setTimeout(() => inputRef.current?.blur(), 0);
  };

  const handleBlur = (e) => {
    handleSave();
    return e;
  };

  const handleSave = () => {
    if (isInputView) {
      if (text !== value) {
        onSave(text);
      }
      setIsInputView(false);
    }
  };

  const onKeyDown = (ev) => {
    if (ev.keyCode === 27) {
      handleESC();
    } else if (ev.keyCode === 13) {
      handleSave();
    }
  };

  useEffect(() => {
    if (value !== text) {
      setText(value || '');
      setIsInputView(false);
    }
  }, [value]);

  useEffect(() => {
    if (isInputView) {
      inputRef.current.selectionStart = inputRef.current.value.length;
      inputRef.current.selectionEnd = inputRef.current.value.length;
      inputRef.current.focus();
    }
  }, [isInputView]);

  if (!isInputView && !text) {
    return <LinkStyled onClick={handleShowInputView}>Ange namn...</LinkStyled>;
  }

  if (isInputView) {
    return (
      <InputStyled
        ref={inputRef}
        value={text}
        onChange={handleChangeText}
        onBlur={handleBlur}
        onKeyDown={onKeyDown}
        isFocus={isInputView}
      />
    );
  }

  return <Text width={200} onClick={handleShowInputView}>{text}</Text>;
};

TextInputCustom.propTypes = {
  value: PropTypes.string,
  onSave: PropTypes.func
};

export default TextInputCustom;
