import React from 'react';
import { Field, reduxForm } from 'redux-form';
import TextInput from '@Components/inputs/text-input';
import FormButtonsFooter from '@Components/ui/form-buttons-footer';
import SelectField from '@Components/inputs/select-field';
import { createValidator, required } from '@Utils/validation';
import { txt } from '@Utils/i18n-util';
import msg from './customer-information.msg';

const VehicleInformationForm = ({
  pristine, submitting, submitSucceeded, handleSubmit, onClose
}) => {
  return (
    <form autoComplete="off" className="dialog" onSubmit={handleSubmit}>
      <Field
        type="text"
        label={txt(msg.lblVehicleRegNo)}
        name="officialIdNo"
        component={TextInput}
      />
      <Field
        name="officialIdType"
        component={SelectField}
      >
        <option value="SE_VEHICLE">Svenskt registreringsnummer</option>
        <option value="OTHER">Annan typ av registreringsnummer</option>
      </Field>
      <Field
        type="text"
        label={txt(msg.lblDescription)}
        name="name"
        component={TextInput}
      />
      <FormButtonsFooter
        onClose={onClose}
        pristine={pristine}
        submitting={submitting}
        submitSucceeded={submitSucceeded}
        handleSubmit={handleSubmit}
      />
    </form>
  );
};

const validator = createValidator({
  name: [required],
  officialIdNo: [required]
});

export default reduxForm({
  form: 'customer-vehicle-info',
  validate: validator
})(VehicleInformationForm);
