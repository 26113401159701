import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { colors } from '@Components/ui/styled/variables';
import { fetchPrinters, fetchTerminalsByVunitId } from '@State/pos-config-actions';
import PosTerminalStatus from '@Components/admin/pos-config/pos-terminal-status';
import PosPrinterStatus from '@Components/admin/pos-config/pos-printer-status';
import { getPosUnitPrefs, getPrinterProgress, hasOpenPosUnit, hasPrinterError, hasTerminalError } from '@State/pos-selectors';
import { pos } from '@Utils/preference-keys';
import Popover from '@Components/ui/popover';

const StatusButton = styled.div`
  margin-top: 8px;
  cursor: pointer;
  padding: 4px;
  white-space: nowrap;
  color: ${props => props.warning ? colors.primaryDark : colors.primaryDark};

  i {
    margin-right: 6px;

    &.success {
      color: ${colors.success};
    }
  }
`;

const Label = styled.span`
  color: ${colors.darkGray80};
  border-radius: 5px;
`;

class PosDeviceStatus extends Component {
  constructor(props) {
    super(props);

    this.checkInterval = 10 * 1000;
    this.fetchThresholdSeconds = 60;

    this.state = {
      showPopover: false
    };
  }

  componentDidMount() {
    this.startTimers();

    if (this.props.vunitId) {
      this.fetchInitialData(this.props.vunitId);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.vunitId && nextProps.vunitId) {
      this.fetchInitialData(nextProps.vunitId);
    }
    if (this.props.posTerminals !== nextProps.posTerminals) {
      this.onTerminalUpdate();
    }
    if (this.props.printers !== nextProps.printers) {
      this.onPrinterUpdate();
    }
  }

  componentWillUnmount() {
    this.stopTimers();
  }

  fetchInitialData = (vunitId) => {
    if (vunitId) {
      this.props.fetchTerminals(vunitId);
    }
    this.props.fetchPrinters();
  };

  startTimers = () => {
    this.lastTerminalUpdate = moment();
    this.checkTerminalInterval = setInterval(this.checkTerminalTimeout, this.checkInterval);

    this.lastPrinterUpdate = moment();
    this.checkPrinterInterval = setInterval(this.checkPrinterTimeout, this.checkInterval);
  };

  stopTimers = () => {
    clearInterval(this.checkTerminalInterval);
    clearInterval(this.checkPrinterInterval);
  }

  onTerminalUpdate = () => {
    this.lastTerminalUpdate = moment();
  }

  onPrinterUpdate = () => {
    this.lastPrinterUpdate = moment();
  }

  hasPassedThreshold = (lastUpdate) => {
    return lastUpdate < moment().subtract(this.fetchThresholdSeconds, 'seconds');
  };

  checkTerminalTimeout = () => {
    const { vunitId } = this.props;
    if (vunitId && this.hasPassedThreshold(this.lastTerminalUpdate)) {
      this.props.fetchTerminals(vunitId).then(this.onTerminalUpdate);
    }
  };

  checkPrinterTimeout = () => {
    if (this.hasPassedThreshold(this.lastPrinterUpdate)) {
      this.props.fetchPrinters().then(this.onPrinterUpdate);
    }
  };

  popoverContent() {
    const {
      posTerminals, printers, posUnitPrefs, vunitId
    } = this.props;
    const printerId = posUnitPrefs && posUnitPrefs[pos.defaultPrinterId];
    const hasPrinter = printers && !printers.isEmpty() && printerId;
    const hasTerminal = posTerminals && !posTerminals.isEmpty() && vunitId;

    return (
      <div className="Popover-content-small">
        {hasTerminal && (
          <div>
            <Label>Terminal</Label>
            {posTerminals.map((terminal, index) => {
              return (
                <div key={index}>
                  <PosTerminalStatus small terminal={terminal} />
                  {terminal.get('name')}
                </div>
              );
            })}
          </div>
        )}
        {hasPrinter && (
          <div className={hasTerminal ? 'mt1' : ''}>
            <Label>Skrivare</Label>
            {printers.filter(p => p.get('id') === printerId).map((printer, index) => {
              return (
                <div key={index}>
                  <PosPrinterStatus small printer={printer} />
                  {printer.get('modelName') || 'Okänd skrivare'}
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }

  render() {
    const {
      hasOpenPosUnit, posUnitPrefs, terminals, terminalError, printerError
    } = this.props;

    const hasErrors = terminalError || printerError;
    const hasPrinter = posUnitPrefs && posUnitPrefs[pos.defaultPrinterId];
    const hasTerminal = terminals && !terminals.isEmpty();

    if (!hasOpenPosUnit || !hasPrinter && !hasTerminal) {
      return null;
    }

    return (
      <Popover
        preferPlace="below"
        isOpen={this.state.showPopover}
        body={this.popoverContent()}
        onOuterAction={() => this.setState({ showPopover: false })}
      >
        <StatusButton warning={hasErrors} onClick={() => this.setState({ showPopover: true })}>
          <i className={hasErrors ? 'fa fa-exclamation-triangle' : 'fa fa-plug success'} />
          Status
        </StatusButton>
      </Popover>
    );
  }
}

const mapStateToProps = (state) => {
  const { pos, posTerminals, printers } = state;
  const posUnit = pos.get('posUnit');

  return {
    hasOpenPosUnit: hasOpenPosUnit(state),
    terminalError: hasTerminalError(state),
    printerError: hasPrinterError(state),
    vunitId: posUnit && posUnit.get('vunitId'),
    printerProgress: getPrinterProgress(state),
    posUnitPrefs: getPosUnitPrefs(state),
    posTerminals,
    printers
  };
};

const mapDispatchToProps = dispatch => ({
  fetchTerminals: id => dispatch(fetchTerminalsByVunitId(id)),
  fetchPrinters: () => dispatch(fetchPrinters())
});

export default connect(mapStateToProps, mapDispatchToProps)(PosDeviceStatus);
