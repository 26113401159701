import React, { Component } from 'react';

function dispatchCustomEvent(event, detail) {
  document.dispatchEvent(new CustomEvent(event, { detail }));
}

export function pauseScanner() {
  dispatchCustomEvent('barcode:control', { pause: true });
}

export function resumeScanner() {
  dispatchCustomEvent('barcode:control', { pause: false });
}

export function triggerScan(barcode) {
  dispatchCustomEvent('barcode:scan', { barcode });
}

class BarcodeScanner extends Component {
  constructor(props) {
    super(props);

    this.buffer = null;
    this.timer = null;
    this.lastReadTime = 0;
    this.noInputTimeout = 500;
    this.interKeyDelay = 100;
    this.pauseScanning = false;
    this.lastInput = Date.now();
  }

  componentDidMount() {
    console.log('ADDING keypress listener for barcode scanner');
    window.addEventListener('keydown', this.keyPress);
    document.addEventListener('barcode:control', this.handleScanControl);
  }

  componentWillUnmount() {
    console.log('REMOVING keypress listener for barcode scanner');
    window.removeEventListener('keydown', this.keyPress);
    document.removeEventListener('barcode:control', this.handleScanControl);

    clearTimeout(this.timer);
  }

  handleScanControl = bcEvent => {
    this.pauseScanning = bcEvent.detail.pause;
  };

  keyPress = ev => {
    const x = ev.which || ev.keyCode;
    if (this.pauseScanning) {
      console.log('Scanning paused, ignoring key');
      return;
    }

    // Ignore SHIFT key
    if (x === 16) {
      return;
    }

    // Send buffer if end of line detected. Dont trigger if buffer is likely not a barcode or a gift card
    //
    if (x === 13 && this.buffer !== null && this.buffer.length > 5) {
      ev.preventDefault();
      const readTime = Date.now() - this.firstChar;
      const avgDelay = readTime / this.buffer.length;
      // console.log(`Total scan time: ${readTime}, maxDelay: ${this.lastReadTime}, avgDelay: ${avgDelay}`);
      this.execute();
    } else {
      this.addChar(String.fromCharCode(x));
    }
  };

  addChar = char => {
    const dSinceLastInput = Date.now() - this.lastInput;

    if (dSinceLastInput > this.lastReadTime) {
      this.lastReadTime = dSinceLastInput;
    }

    if (dSinceLastInput <= this.interKeyDelay) {
      clearTimeout(this.timer);
      if (this.lastReadTime > this.noInputTimeout) {
        this.lastReadTime = 0;
      }
      this.timer = setTimeout(() => { this.buffer = null; this.lastReadTime = 0; }, this.interKeyDelay);
    } else {
      this.timer = setTimeout(() => { this.buffer = null; this.lastReadTime = 0; }, this.noInputTimeout);
    }

    if (this.buffer === null) {
      this.buffer = '';
      this.firstChar = Date.now();
      this.lastReadTime = 0;
    }

    this.lastInput = Date.now();
    this.buffer = this.buffer.concat(char);
  };

  execute = () => {
    console.log('DISPATCHING barcode handler', this.buffer);
    triggerScan(this.buffer);
  };

  render() {
    return null;
  }
}

export default BarcodeScanner;
