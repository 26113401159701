import React from 'react';
import { PanelWrapper } from '@Components/customers/customer-styles';
import { FormGroup } from '@Components/ui/styled/main';
import moment from 'moment';

const CustomerVehicleDetails = ({
  officialIdNo, name, createdTs, lastBookingTs
}) => (
  <PanelWrapper defaultCursor>
    <FormGroup labelText="Reg.nummer" text={officialIdNo} />
    <FormGroup labelText="Beskrivning" text={name || '-'} />
    <FormGroup labelText="Tillagd" text={moment(createdTs).format('LLL')} />
    <FormGroup labelText="Senaste bokning" text={lastBookingTs ? moment(lastBookingTs).format('LLL') : '-'} />
  </PanelWrapper>
);

export default CustomerVehicleDetails;
