import React, { useState } from 'react';
import CustomerInformationForm from '@Components/customers/customer-detail/overview/customer-information-form';
import ModalDialog from '@Components/dialogs/modal-dialog';
import { isCustomerCompany, isCustomerPerson, isCustomerVehicle } from '@Utils/customer-util';
import { txt } from '@Utils/i18n-util';
import msg from './customer-edit-modal.msg';
import VehicleInformationForm from './vehicle-information-form';
import CompanyInformationForm from './company-information-form';

const CustomerEditModal = ({
  customer, hideModalChangeInfo, initialData, onSubmit
}) => {
  const [activeTab, setActiveTab] = useState('info');

  const handleTabClick = (e, tab) => {
    e.preventDefault();
    setActiveTab(tab);
  };

  const getClassTab = (tab) => activeTab === tab ? 'tabs__item tabs__item--active' : 'tabs__item';

  return (
    <ModalDialog
      onClose={hideModalChangeInfo}
    >
      {isCustomerPerson(customer) && (
        <ul className="tabs__list modal-margins">
          <li className={getClassTab('info')}>
            <a href="#" onClick={e => handleTabClick(e, 'info')}>{txt(msg.customerDetails)}</a>
          </li>
          <li className={getClassTab('address')}>
            <a href="#" onClick={e => handleTabClick(e, 'address')}>{txt(msg.address)}</a>
          </li>
        </ul>
      )}
      {isCustomerVehicle(customer) && (
        <VehicleInformationForm
          onClose={hideModalChangeInfo}
          onSubmit={onSubmit}
          initialValues={initialData}
        />
      )}
      {isCustomerCompany(customer) && (
        <CompanyInformationForm
          onClose={hideModalChangeInfo}
          onSubmit={onSubmit}
          initialValues={initialData}
        />
      )}
      {isCustomerPerson(customer) && (
        <CustomerInformationForm
          showInfo={activeTab === 'info'}
          onClose={hideModalChangeInfo}
          onSubmit={onSubmit}
          initialValues={initialData}
        />
      )}
    </ModalDialog>
  );
};

export default CustomerEditModal;
