import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { navigate } from '@Utils/navigate';
import Button from '@Components/ui/button';
import { authenticate } from './actions';

const styleImg = { width: 100 };

class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = this.makeInitialState(props);
  }

  makeInitialState(props) {
    return {
      username: '',
      password: '',
      authenticationFailed: false
    };
  }

   handleUsername = event => {
     this.setState({ username: event.target.value });
   };

   handlePassword = event => {
     this.setState({ password: event.target.value });
   };

   handleLogin = e => {
     e.preventDefault();

     const { dispatch } = this.props;
     const { username, password } = this.state;

     dispatch(authenticate({
       username,
       password
     })).then(() => navigate('/'));
   };

   UNSAFE_componentWillReceiveProps(nextProps) {
     if (nextProps.authFailed) {
       this.setState({ password: '' });
     }
     if (nextProps.authenticating) {
       this.setState({ authenticationFailed: false });
     }
     if (!nextProps.authenticating && (nextProps.authFailed || nextProps.networkFailed)) {
       this.setState({ authenticationFailed: true });
     }
   }

   componentDidUpdate(prevProps, prevState) {
     if (this.props.authFailed && !prevProps.authFailed) {
       this.passwordInput.focus();
     }
   }

   render() {
     const { theme } = this.props;
     const logo = theme === 'yabie' ? '/yabie-black.svg' : '/cliento-black.png';
     return (
       <div className="login-container">
         <div className="login">
           <p className="text-center">
             <img src={logo} alt="Cliento" style={styleImg} />
           </p><br />
           {this.loginForm()}
         </div>
       </div>
     );
   }

   loginForm() {
     const { authenticating, authFailed, networkFailed, lastTrymessage } = this.props;
     const { username, password, authenticationFailed } = this.state;

     const loginText = authenticating ? 'Loggar in...' : 'Logga in';
     const formFilled = username.length > 0 && password.length > 0;
     const formClassName = authenticationFailed ? 'form animated shake' : 'form';
     const disabled = (authenticating || !formFilled);

     let message;
     if (authenticationFailed && authFailed && !formFilled) {
       message = <p className="text-danger"><i className="fa fa-fw fa-exclamation-circle" /> Felaktigt användarnamn eller lösenord</p>;
     } else if (authenticationFailed && networkFailed) {
       message = <p className="text-danger"><i className="fa fa-fw fa-exclamation-circle" /> Problem att ansluta till servern {lastTrymessage}</p>;
     } else {
       message = '';
     }

     return (
       <div className={formClassName}>
         {message}<br />
         <form autoComplete="off" onSubmit={this.handleLogin}>
           <div className="form-group">
             <input
               type="email"
               className="form-control"
               placeholder="Mejladress"
               ref={(obj) => { this.usernameInput = obj; }}
               tabIndex="1"
               onChange={this.handleUsername}
               value={username}
             />
           </div>
           <div className="form-group">
             <input
               type="password"
               className="form-control"
               name="password"
               placeholder="Lösenord"
               ref={(obj) => { this.passwordInput = obj; }}
               tabIndex="2"
               onChange={this.handlePassword}
               value={password}
             />
           </div><br />
           <Button small primary type="submit" disabled={disabled} tabIndex="4">{loginText}</Button>
         </form>
       </div>
     );
   }
}

LoginForm.propTypes = {
  dispatch: PropTypes.func.isRequired
};

function propProvider(reduxState, props) {
  const { appState, authState } = reduxState;

  const asjs = authState.toJS();

  return {
    theme: appState.get('theme'),
    ...asjs
  };
}

export default connect(propProvider)(LoginForm);
