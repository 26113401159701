import React from 'react';
import PropTypes from 'prop-types';

import { Text } from '@Components/ui/styled/main';
import { HeaderTextEmpty } from '@Components/customers/customer-styles';

const CustomerEmptyTab = ({ title, text, children }) => (
  <HeaderTextEmpty>
    <Text dark center fs="15" fontWeight={600}>{title}</Text>
    <Text dark center fs="13" fontWeight={400}>{text}</Text>
    {children}
  </HeaderTextEmpty>
);

CustomerEmptyTab.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string
};

export default CustomerEmptyTab;
