import styled, { css } from 'styled-components';
import { colors, fsw } from '@Components/ui/styled/variables';

export const Text = styled.span`
  ${p => p.grayText && css`
    opacity: 0.54;
  `}
`;

export const PosContentWrap = styled.div`
`;

export const PosFlexWrapText = styled.div`
  flex: 1;
  ${p => p.displayFlex && css`
    display: flex;
  `}
  p {
    color: #000;
    flex:1;
    margin: 0;
  }
`;

export const PosOrgWrapItem = styled.div`
  display: flex;
  margin-bottom: 15px;
  ${p => p.lastChild && css`
    margin-bottom: 0;
  `}
`;

export const Title = styled.p`
  margin: 0 0 17px 0;
  font-size: 24px;
  color: #000;
`;

export const GrayLabelText = styled.div`
  font-weight: ${fsw.medium};
  color:${colors.textMuted};
  margin-bottom: 5px;
`;

export const FlexParagraph = styled.p`
  flex:1;
`;

export const POSTitle = styled.p`
  margin: 0 0 4px 0;
  font-weight: ${fsw.bold};
  ${p => p.alignItemsCenter && css`
    display: flex;
    align-items: center;
  `}

  small {
    color: ${colors.textMuted};
  }
`;

export const GrayText = styled.p`
  margin: 0;
  color: rgba(0, 0, 0, 0.5);
`;

export const ButtonWrap = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  ${p => p.topPadding && css`
    padding-top: 12px;
  `}
`;

export const PosConfigRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const PosConfigCell = styled.div`
  flex: 1;
`;

export const styleLabelInputNoBorder = { border: 'none', padding: '0' };
