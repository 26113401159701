import React, { PureComponent } from 'react';
import { HexColorPicker } from 'react-colorful';
import debounce from 'lodash/debounce';
import { range } from 'ramda';
import Popover from '@Components/ui/popover';
import { isDark } from '@Utils/colors';

class ColorPicker extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      color: props.value,
      showMore: props.colorPickerState,
      colors: [
        // '#F2A8A3', // Coral (Use for NoShow)
        '#F3B294', // Peach
        '#F9D98B', // Honey
        // '#FCEC8F', // Safron
        '#F6FA8D', // Pistachio
        '#D7F48F', // Lime
        '#B9F4A8', // Apple
        // '#BBFAD6', // Mint (Use for Show)
        '#B6EEF3', // Aquamarine
        // '#B2E2FC', // Heaven  (Använd som default)
        '#BCDAFC', // Jeans
        // '#BFCAFB', // Lavender
        '#C8BDFA', // Violet
        // '#DDBDFA', // Lilac
        '#F4C2F9', // Orchid
        '#F8D4E6', // Powder
        '#b99b7f', // Espresso
        '#DAC6B2', // Coffee
        '#F3DBC2', // Sand
        '#d6d6d7', // Grey
        '#9e9e9e' // Dark Grey
      ]
    };

    this.debounceOnChange = debounce(props.input.onChange, 200);
  }

  componentDidUpdate(prevProps) {
    if (this.props.colorPickerState !== prevProps.colorPickerState) {
      this.setStateFromProp();
    }
  }

  handleClosePopover = () => this.setState({ showMore: false });

  handleOpenPopover = () => this.setState({ showMore: !this.state.showMore });

  setStateFromProp = () => this.setState({ showMore: this.props.colorPickerState });

  handleChange = (color) => {
    this.setState({ color });
    this.debounceOnChange(color);
  };

  popoverContent = () => {
    const { color } = this.state;
    return (
      <div className="colorpicker__popover">
        <HexColorPicker color={color} onChange={this.handleChange} />
      </div>
    );
  };

  render() {
    const { showMore, colors } = this.state;
    const { input: { value, onChange } } = this.props;
    const customColor = value && !colors.includes(value);
    const customHighlight = customColor && isDark(value) ? '#fff' : null;

    return (
      <div className="colorpicker">
        <div
          className={
            !value
              ? 'colorpicker__swatch colorpicker__none colorpicker__swatch--selected'
              : 'colorpicker__swatch colorpicker__none'
          }
          onClick={() => onChange(null)}
        />
        {colors.map((color, i) => (
          <div
            key={`color_${i}`}
            className={
              color === value
                ? 'colorpicker__swatch colorpicker__swatch--selected'
                : 'colorpicker__swatch'
            }
            style={{ background: color }}
            onClick={() => onChange(color)}
          />
        ))}
        <Popover
          isOpen={showMore}
          body={this.popoverContent()}
          preferPlace="above"
          className="Popover-big-radius"
          onOuterAction={this.props.handleClosePopover || this.handleClosePopover}
        >
          <div
            className={
              customColor
                ? 'colorpicker__swatch colorpicker__swatch--selected'
                : 'colorpicker__swatch'
            }
            style={{
              background: customColor ? value : 'transparent',
              color: customHighlight
            }}
            onClick={this.props.handleOpenPopover || this.handleOpenPopover}
          >
            {!customColor && (
              <ul className="colorpicker__umbrella">
                {range(0, 12).map(i => (
                  <li className="colorpicker__color" key={`slice_${i}`} />
                ))}
              </ul>
            )}
          </div>
        </Popover>
      </div>
    );
  }
}

export default ColorPicker;
