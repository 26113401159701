import { defineMessages } from 'react-intl';

export default defineMessages({
  noVehicleInfoTitle: {
    id: 'customers.vehicleInfo.noVehicleInfoTitle',
    defaultMessage: 'No vehicle data'
  },
  noVehicleInfoText: {
    id: 'customers.vehicleInfo.noVehicleInfoText',
    defaultMessage: 'There are no additional data for this vehicle'
  },
  btnSearchVehicleInfo: {
    id: 'customers.vehicleInfo.btnGetVehicleInfo',
    defaultMessage: 'Get vehicle info'
  }
});
