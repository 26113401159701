import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, fs } from '@Components/ui/styled/variables';

const PaginationWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${colors.softGray60};
  padding: 10px;
`;

const PaginationLabel = styled.span`
  margin: 5px 10px;
  font-size: ${fs.s};
`;

const Pagination = ({ pageCount, handlePageClick, pageIndex, totalCount }) => (
  <PaginationWrap>
    <button
      className="btn btn-default"
      onClick={() => handlePageClick(pageIndex - 1)}
      disabled={pageIndex <= 0}
    >
      <i className="fa fa-chevron-left" />
    </button>
    <PaginationLabel>
      {totalCount} kunder,{' '}
      sida {pageIndex + 1} av {pageCount}
    </PaginationLabel>
    <button
      className="btn btn-default"
      onClick={() => handlePageClick(pageIndex + 1)}
      disabled={pageIndex + 1 >= pageCount}
    >
      <i className="fa fa-chevron-right" />
    </button>
  </PaginationWrap>
);

Pagination.propTypes = {
  pageCount: PropTypes.number,
  currentPage: PropTypes.number,
  handlePageClick: PropTypes.func
};

export default Pagination;
