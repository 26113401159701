import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import uniqBy from 'lodash/uniqBy';
import isEqual from 'lodash/isEqual';
import { useDispatch, useSelector } from 'react-redux';

import { setCustomerFormState } from '@State/cf-actions';
import { navigate, getCustomersUrl } from '@Utils/navigate';
import { CustomerType, getCustomerHeading, getCustomerTypeIcon } from '@Utils/customer-util';
import { customerClearForm } from '@State/customer-actions';
import ColumnListItem from '@Components/ui/column/column-list-item';
import { formatPhoneNumber } from '@Utils/phone-util';
import { txt } from '@Utils/i18n-util';
import msg from './customers-list.msg';

const CustomersList = ({ routeParams, selectedCustomerId, customerEntries }) => {
  const dispatch = useDispatch();
  const phoneMode = useSelector(state => state.mainViewState.get('phoneMode'));

  const onSelect = useCallback((customer) => {
    if (phoneMode) {
      dispatch(setCustomerFormState({ formVisible: true, customer }));
    } else if (+routeParams.id !== +customer.customerId) {
      dispatch(customerClearForm());
      navigate(getCustomersUrl(routeParams, customer.customerId, routeParams.tab));
    }
  }, [routeParams.id, routeParams.tab, phoneMode]);

  return (
    <>
      <div className="columns-list">
        {customerEntries && customerEntries.size > 0
          ? (
            <>
              {uniqBy(customerEntries.toJS(), 'customerId').map((item) => {
                const {
                  customerType, phoneNumber, otherPhoneNumber, email, name, officialIdNo, customerId
                } = item;
                const isSelectedItem = selectedCustomerId && +selectedCustomerId === +customerId;
                const phoneNumbers = [phoneNumber, otherPhoneNumber]
                  .filter(Boolean).map(formatPhoneNumber).join('/');

                return (
                  <ColumnListItem
                    onClick={() => onSelect(item)}
                    key={customerId}
                    header={getCustomerHeading(item)}
                    icon={getCustomerTypeIcon(customerType)}
                    selected={!phoneMode && isSelectedItem}
                    subText={(
                      <>
                        {phoneNumbers && <div>{phoneNumbers}</div>}
                        {email && <div>{email}</div>}
                        {customerType === CustomerType.Vehicle && officialIdNo && name && <div>{name}</div>}
                        {customerType === CustomerType.Company && officialIdNo && <div>{officialIdNo}</div>}
                      </>
                    )}
                  />
                );
              })}
            </>
          )
          : (
            <div>
              <div className="columns-list-help">
                <em>{txt(msg.txtNoCustomers)}</em>
              </div>
            </div>
          )}
      </div>
    </>
  );
};

CustomersList.propTypes = {
  onSelect: PropTypes.func
};

export default memo(
  CustomersList,
  (prevProps, nextProps) => !!isEqual(prevProps, nextProps)
);
