import React from 'react';
import { Field, reduxForm } from 'redux-form';
import TextInput from '@Components/inputs/text-input';
import SelectField from '@Components/inputs/select-field';
import FormButtonsFooter from '@Components/ui/form-buttons-footer';
import { createValidator, required } from '@Utils/validation';
import { txt } from '@Utils/i18n-util';
import msg from './customer-information.msg';

const CustomerInformationForm = ({
  showInfo = 'info', pristine, submitting, submitSucceeded, handleSubmit, onClose
}) => {
  return (
    <form autoComplete="off" className="dialog" onSubmit={handleSubmit}>
      {showInfo
        ? (
          <>
            <Field
              type="text"
              label={txt(msg.lblName)}
              name="name"
              component={TextInput}
            />
            <Field
              type="phone"
              label={txt(msg.lblPhoneNumber)}
              name="phoneNumber"
              component={TextInput}
            />
            <Field
              type="phone"
              label={txt(msg.lblOtherPhoneNumber)}
              name="otherPhoneNumber"
              component={TextInput}
            />
            <Field
              type="email"
              label={txt(msg.lblEmail)}
              name="email"
              component={TextInput}
            />
            <Field
              type="text"
              label={txt(msg.lblPersonalIDNo)}
              name="officialIdNo"
              component={TextInput}
            />
            <Field
              name="officialIdType"
              component={SelectField}
            >
              <option value="SE_PNO">Svenskt personummer</option>
              <option value="INTL_PNO">Internationellt personnummer</option>
              <option value="OTHER">Annan typ av personnummer</option>
            </Field>
            {/*
            <Field
              type="text"
              label={txt(msg.lblGender)}
              name="gender"
              component={SelectField}
            >
              <option value="None" />
              <option value="Male">{txt(msg.lblGenderMale)}</option>
              <option value="Female">{txt(msg.lblGenderFemale)}</option>
            </Field>
            */}
          </>
        )
        : (
          <>
            <Field
              type="text"
              label={txt(msg.lblAddressLine1)}
              name="addressLine1"
              component={TextInput}
            />
            <Field
              type="text"
              label={txt(msg.lblAddressLine2)}
              name="addressLine2"
              component={TextInput}
            />
            <Field
              type="text"
              label={txt(msg.lblAddressLine3)}
              name="addressLine3"
              component={TextInput}
            />
            <Field
              type="text"
              label={txt(msg.lblPostCode)}
              name="postCode"
              component={TextInput}
            />
            <Field
              type="text"
              label={txt(msg.lblArea)}
              name="area"
              component={TextInput}
            />
            <Field
              type="text"
              label={txt(msg.lblCountry)}
              name="country"
              component={TextInput}
            />
          </>
        )}
      <FormButtonsFooter
        onClose={onClose}
        pristine={pristine}
        submitting={submitting}
        submitSucceeded={submitSucceeded}
        handleSubmit={handleSubmit}
      />
    </form>
  );
};

const validator = createValidator({
  name: [required]
});

export default reduxForm({
  form: 'customer-detail-change-info',
  validate: validator
})(CustomerInformationForm);
