import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleBKFModal } from '@State/bkf/actions';
import { bkfModals } from '@State/bkf/constants';
import AddCustomerModal from '@Components/customers/add-customer-modal';

const BookingAddCustomerModal = ({ routeParams }) => {
  const dispatch = useDispatch();
  const props = useSelector(state => state.bkf.get('modalProps'));

  const onHideNewCustomerForm = () => {
    dispatch(toggleBKFModal(bkfModals.addCustomer, false));
  };

  return (
    <AddCustomerModal
      routeParams={routeParams}
      onClose={onHideNewCustomerForm}
      {...props}
    />
  );
};

export default BookingAddCustomerModal;
