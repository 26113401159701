import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { destroy, getFormValues } from 'redux-form';
import { createSelector } from 'reselect';
import { web } from '@Utils/preference-keys';
import { bookingTypes } from '@Utils/booking-util';
import { getChangeJsonPrefsPermission, getWebPaymentEnabled } from '@State/selectors';
import PrefsAttribsJsonEditor from '@Components/resources/services/prefs-attribs-json-editor';
import CustomFieldsEditor from '@Components/services/custom-fields-editor';
import { toggleServicesSortable } from '@State/admin-view-reducer';
import Loader from '@Components/ui/loader';
import { firstItemIdInGroup } from '@State/action-helpers';
import {
  fetchServices,
  fetchAllResourceMappings,
  fetchAllAddonMappings,
  updateService,
  deleteService,
  serviceMappingValues,
  isServiceMappingDiff,
  updateServicePrefs,
  updateServiceAttribs,
  updateServiceCustomFields
} from '@State/services-actions';
import { getFilteredServices, getIsAddonServicesSection } from '@Utils/filter-selectors';
import { fetchGroupsAndResources } from '@State/resource-actions';
import { getServiceConfigUrl } from '@Utils/navigate';
import NoContent from '@Components/ui/no-content';
import ServiceSubNav from './services-subnav';
import SortableListBanner from '../sortable-list/sortable-list-banner';
import ServiceListTools from './service-list-tools';
import ServiceList from './service-list';
import EditServiceForm from './edit-service-form';
import ServiceResourceMappings from './service-resource-mappings';
import ServiceAddonMappings from './service-addon-mappings';

class ServiceConfig extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      filter: '',
      addonFilter: ''
    };
  }

  componentDidMount() {
    this.props.loadData().then(() => this.setState({ loading: false }));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.serviceId !== this.props.serviceId) {
      this.props.resetForm();
    }
    if (nextProps.isAddonServices !== this.props.isAddonServices) {
      this.props.toggleSortable(false);
    }
  }

  componentWillUnmount() {
    if (this.props.sortable) {
      this.props.toggleSortable(false);
    }
  }

  filterChange = ev => {
    this.setState({ [this.props.filterKey]: ev.target.value });
  };

  handleToggleSorting = ev => {
    this.props.toggleSortable(!this.props.sortable);
  };

  render() {
    const { loading } = this.state;
    const {
      servicesById, serviceId, service, orderedServiceGroups, routeParams,
      hasCustomDiffs, sortable, showJsonPrefs, isAddonServices, filterKey
    } = this.props;

    if (loading && servicesById.isEmpty()) {
      return <Loader />;
    }

    if (!servicesById.isEmpty() && (!serviceId || (serviceId && !service))) {
      return (
        <Redirect
          to={getServiceConfigUrl(
            routeParams,
            firstItemIdInGroup(orderedServiceGroups, 'serviceIds', servicesById),
            'settings'
          )}
        />
      );
    }

    return (
      <div className="columns-container">
        <div className="columns-sidebar">
          <div className="columns-header">
            <h1>{isAddonServices ? 'Tilläggstjänster' : 'Tjänster'}</h1>
            {!isAddonServices && (
              <ServiceListTools routeParams={this.props.routeParams} />
            )}
          </div>

          {sortable ? (
            <SortableListBanner onToggleSorting={this.handleToggleSorting} />
          ) : (
            this.renderFilter()
          )}

          <ServiceList
            routeParams={routeParams}
            selectedId={serviceId}
            filter={this.state[filterKey]}
            sortable={sortable}
          />
        </div>

        <div className="columns-content">
          <div className="columns-content-container">
            <div className="columns-content-body">
              {service && (
                <ServiceSubNav
                  showJsonPrefs={showJsonPrefs}
                  routeParams={this.props.routeParams}
                />
              )}
              {this.renderSubsection()}
            </div>
            <div className="columns-content-sidebar">
              {service && (
                <ServiceResourceMappings
                  loaded={!loading}
                  hasCustomDiffs={hasCustomDiffs}
                  serviceId={serviceId}
                  service={service}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderSubsection() {
    const {
      service, resourceMappings, servicesById, serviceId, updateService, initialFormValues,
      updateServiceCustomFields, deleteService, updateServiceAttribs, updateServicePrefs,
      orderedServiceGroups, routeParams, hasCustomDiffs, isAddonServices
    } = this.props;

    const { tab, id } = routeParams;
    let customFields = [],
      attributes = {},
      prefs = {};

    if (servicesById && servicesById.size > 0 && servicesById.toJS()[id]) {
      const item = servicesById.toJS()[id];
      customFields = item?.customFields;
      attributes = item.attributes;
      prefs = item.prefs;
    }

    if (!service) {
      const serviceName = isAddonServices ? 'tilläggstjänst' : 'tjänst';
      return (
        <NoContent icon={isAddonServices ? 'fa fa-cart-plus' : 'fa fa-scissors'}>
          {orderedServiceGroups.isEmpty() ? (
            <>
              Det finns inga tjänster eller grupper.<br />
              Klicka på "Lägg till grupp" för att skapa en grupp.
            </>
          ) : (
            <>
              Det finns inga {serviceName}er.<br />
              Klicka på "Lägg till {serviceName}" för att skapa en {serviceName}.
            </>
          )}
        </NoContent>
      );
    }

    switch (tab) {
      case 'addons':
        return (
          <ServiceAddonMappings
            serviceId={serviceId}
            routeParams={routeParams}
          />
        );
      case 'custom-fields':
        return (
          <CustomFieldsEditor
            customFields={customFields}
            onUpdateCustomFields={updateServiceCustomFields}
            routeParams={routeParams}
          />
        );
      case 'prefs':
        return (
          <PrefsAttribsJsonEditor
            attributes={attributes}
            prefs={prefs}
            onUpdateAttr={updateServiceAttribs}
            onUpdatePrefs={updateServicePrefs}
            routeParams={routeParams}
          />
        );
      default: {
        return (
          <EditServiceForm
            onSubmit={updateService}
            onDelete={deleteService}
            resourceMappings={resourceMappings}
            serviceId={serviceId}
            isAddonService={service.addon}
            isClassBooking={service.bookingType === bookingTypes.ClassBooking}
            initialValues={initialFormValues}
            hasCustomDiffs={hasCustomDiffs}
          />
        );
      }
    }
  }

  renderFilter() {
    return (
      <div className="columns-header">
        <div className="columns-filter">
          <input
            type="text"
            className="form-control"
            placeholder="Filter"
            onChange={this.filterChange}
            value={this.state[this.props.filterKey]}
          />
        </div>
      </div>
    );
  }
}

const hasDiffs = (values, mappings) => {
  if (mappings.size === 0) {
    return false;
  }
  for (let i = 0; i < serviceMappingValues.length; i++) {
    if (isServiceMappingDiff(values, mappings, serviceMappingValues[i])) {
      return true;
    }
  }
};

const getClassPrefs = (prefs) => {
  return {
    webMinTimeBeforeBooking: prefs[web.minTimeBeforeBooking] || undefined
  };
};

const getPaymentPrefs = (prefs, enableVouchers) => {
  return {
    webPaymentRequired: prefs[web.paymentRequired] || undefined,
    webAllowPayOnSite: prefs[web.allowPayOnSite] || undefined,
    webAllowDiscountVoucher: enableVouchers ? prefs[web.allowDiscountVoucher] !== false : undefined,
    webAutoSelectFirstAvailableSlot: prefs[web.autoSelectFirstAvailableSlot] || undefined
  };
};

const getInitialFormValues = createSelector(
  state => state.servicesById,
  (state, props) => parseInt(props.match.params.id),
  state => getWebPaymentEnabled(state),
  state => state.locationFeatures.get('EnableDiscountVouchers'),
  (servicesById, serviceId, paymentEnabled, enableVouchers) => {
    const service = servicesById && serviceId && servicesById.get(serviceId);
    const { colorway } = service?.prefs || {};
    const paymentPrefs = service?.prefs && paymentEnabled
      ? getPaymentPrefs(service?.prefs, enableVouchers)
      : null;
    const classPrefs = service?.bookingType === bookingTypes.ClassBooking
      ? getClassPrefs(service?.prefs)
      : null;

    return service && {
      colorway,
      ...classPrefs,
      ...paymentPrefs,
      ...service
    };
  }
);

const mapStateToProps = (state, ownProps) => {
  const {
    servicesById,
    resourcesById,
    orderedServiceGroups,
    resourceServiceMappingsByCombinedId,
    adminViewState
  } = state;
  const routeParams = ownProps.match.params;
  const serviceId = parseInt(ownProps.match.params.id);
  const service = servicesById && servicesById.get(serviceId);

  const resourceMappings = resourceServiceMappingsByCombinedId.filter(
    (value, key) => {
      return key.endsWith(`:${serviceId}`);
    }
  );

  const formValues = getFormValues('edit-service-form')(state);
  const hasCustomDiffs = formValues && hasDiffs(formValues, resourceMappings);
  const isAddonServices = getIsAddonServicesSection(state, ownProps);
  const filterKey = isAddonServices ? 'addonFilter' : 'filter';

  return {
    showJsonPrefs: getChangeJsonPrefsPermission(state, ownProps),
    orderedServiceGroups,
    serviceId,
    routeParams,
    servicesById: getFilteredServices(state, ownProps),
    isAddonServices,
    filterKey,
    resourcesById,
    service,
    resourceMappings,
    hasCustomDiffs,
    formValues,
    sortable: adminViewState.get('servicesSortable'),
    initialFormValues: getInitialFormValues(state, ownProps)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadData: () => {
      return Promise.all([
        dispatch(fetchServices()),
        dispatch(fetchGroupsAndResources()),
        dispatch(fetchAllResourceMappings()),
        dispatch(fetchAllAddonMappings())
      ]);
    },
    updateService: service => {
      return dispatch(updateService(service));
    },
    deleteService: serviceId => {
      return dispatch(deleteService(serviceId));
    },
    resetForm: () => {
      dispatch(destroy('edit-service-form'));
    },
    toggleSortable: (sortable) => {
      return dispatch(toggleServicesSortable(sortable));
    },
    updateServicePrefs: (data) => {
      return dispatch(updateServicePrefs(ownProps.match.params.id, data));
    },
    updateServiceAttribs: (data) => {
      return dispatch(updateServiceAttribs(ownProps.match.params.id, data));
    },
    updateServiceCustomFields: (data) => {
      return dispatch(updateServiceCustomFields(ownProps.match.params.id, data));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceConfig);
