import moment from 'moment';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { sms } from '@Utils/preference-keys';
import { isBookingNew } from '@Utils/booking-util';
import { getSmsType, getSmsSent } from '@Utils/customer-util';
import { txt } from '@Utils/i18n-util';
import msg from './booking-details.msg';

const BookingDetail = ({ icon, className, children }) => (
  <div>
    <small className={className}>
      <i className={icon} /> {children}
    </small>
  </div>
);
class BookingDetails extends Component {
  render() {
    const {
      id, bookingRef, customer, createdTime, lastUpdateTime,
      payments, review, reminders, allReminders
    } = this.props;

    const filteredReminders = !allReminders
      ? reminders?.filter(({ type }) => type === 'PreReminder')
      : allReminders;

    const payment = payments?.find(p => p.paymentType === 'Online');
    const { lastReminderSentTs, reviewPostedTs } = review || {};
    const { paidTs, refundTs } = payment || {};
    const { name, bookedAs, cancelledTs } = customer || {};
    const isNew = isBookingNew(this.props);

    return (
      <div className="booking-form-details">
        {!isNew && (
          <BookingDetail className="text-muted" icon="far fa-info-circle">
            {txt(msg.bookingRef)}: <strong>{bookingRef}</strong> ({id})
          </BookingDetail>
        )}
        {bookedAs && bookedAs !== name && (
          <BookingDetail className="text-warning" icon="fa fa-user">
            {txt(msg.bookedAs)} {bookedAs}
          </BookingDetail>
        )}
        {createdTime && (
          <BookingDetail className="text-muted" icon={this.channelIcon()}>
            {txt(msg.created)} {createdTime.format('lll')} {this.channelText()}
          </BookingDetail>
        )}
        {lastUpdateTime && (
          <BookingDetail className="text-muted" icon="far fa-clock">
            {txt(msg.updated)} {lastUpdateTime.format('lll')}
          </BookingDetail>
        )}
        {filteredReminders && filteredReminders.map((reminder) => {
          const icon = getSmsSent(reminder.smsStatus) ? 'fa fa-comment-check' : 'far fa-comment-dots';
          return (
            <BookingDetail key={reminder.type} className="text-muted" icon={icon}>
              {this.smsStatus(reminder)}
            </BookingDetail>
          );
        })}
        {cancelledTs && (
          <BookingDetail className="text-danger" icon="fa fa-exclamation-circle">
            {txt(msg.cancelled)} {moment(cancelledTs).format('lll')} {this.cancelChannelText()}
          </BookingDetail>
        )}
        {paidTs && (
          <BookingDetail className="text-muted" icon="fa fa-badge-dollar">
            {txt(msg.paid)} {moment(paidTs).format('lll')}
          </BookingDetail>
        )}
        {refundTs && (
          <BookingDetail className="text-muted" icon="fa fa-arrow-circle-left">
            {txt(msg.refunded)} {moment(refundTs).format('lll')}
          </BookingDetail>
        )}
        {lastReminderSentTs && !reviewPostedTs && (
          <BookingDetail className="text-muted" icon="far fa-star">
            {txt(msg.reviewRequestSent)} {moment(lastReminderSentTs).format('lll')}
          </BookingDetail>
        )}
        {reviewPostedTs && (
          <BookingDetail className="text-muted" icon="far fa-star">
            {txt(msg.reviewPosted)} {moment(reviewPostedTs).format('lll')}
          </BookingDetail>
        )}
      </div>
    );
  }

  channelText() {
    switch (this.props.customer?.channel) {
      case 'Web':
        return txt(msg.viaWebText);
      case 'WebVacci':
        return txt(msg.viaWebVacci);
      default:
        return txt(msg.viaDefault);
    }
  }

  channelIcon() {
    switch (this.props.customer?.channel) {
      case 'Web':
        return 'fa fa-globe';
      case 'WebVacci':
        return 'fa fa-yin-yang';
      default:
        return 'far fa-clock';
    }
  }

  cancelChannelText() {
    switch (this.props.customer?.cancelledChannel) {
      case 'Web':
      case 'WebVacci':
        return txt(msg.cancelledViaWeb);
      case 'Cal':
        return txt(msg.cancelledViaCal);
      default:
        return '';
    }
  }

  smsStatus({
    type, status, statusTs, smsStatus, smsStatusTs, sendAfter,
    customerRemindersEnabled, resourceRemindersEnabled
  }) {
    const { attributes } = this.props;
    const smsType = getSmsType(type);
    const smsStatusDate = moment(statusTs || smsStatusTs).format('lll');
    const reminderDisabled = attributes && attributes[sms.reminderDisabled];
    const smsDisabled = !customerRemindersEnabled || !resourceRemindersEnabled || reminderDisabled;

    switch (status || smsStatus) {
      case 'PENDING':
        if (!customerRemindersEnabled) {
          return `${smsType} ${txt(msg.smsDisabledCustomer)}`;
        }
        if (!resourceRemindersEnabled) {
          return `${smsType} ${txt(msg.smsDisabledResource)}`;
        }
        if (reminderDisabled) {
          return `${smsType} ${txt(msg.smsDisabledBooking)}`;
        }
        if (moment().isAfter(sendAfter)) {
          return `${smsType} ${txt(msg.smsStatusNotSent)}`;
        }
        return `${smsType} ${txt(msg.smsStatusSendAt)} ${moment(sendAfter).format('lll')}`;
      case 'SENT':
        return `${smsType} ${txt(msg.smsStatusSentAt)} ${smsStatusDate}`;
      case 'DELIVERED':
        return `${smsType} ${txt(msg.smsStatusDeliveredAt)} ${smsStatusDate}`;
      default:
        return `${smsType} ${txt(smsDisabled ? msg.smsStatusNotSent : msg.smsStatusDefault)}`;
    }
  }
}

const mapStateToProps = (state) => {
  const { bkf } = state;

  return {
    id: bkf.get('id'),
    type: bkf.get('type'),
    status: bkf.get('status'),
    bookingRef: bkf.get('bookingRef'),
    customer: bkf.get('customer'),
    createdTime: bkf.get('createdTime'),
    lastUpdateTime: bkf.get('lastUpdateTime'),
    payments: bkf.get('payments'),
    reminders: bkf.get('reminders'),
    allReminders: bkf.get('allReminders'),
    review: bkf.get('review'),
    attributes: bkf.get('attributes')
  };
};

export default connect(mapStateToProps)(BookingDetails);
