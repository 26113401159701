import React, { Component } from 'react';
import styled from 'styled-components';
import { colors } from '@Components/ui/styled/variables';
import { getDiscountAmount, getItemDescription } from '@Utils/pos-utils';
import { pauseScanner, resumeScanner } from '@Utils/barcode';
import {
  maxDiscount, convertToFloat, convertToString, isValidInput
} from '@Utils/numpad-util';
import Numpad from '@Components/inputs/numpad';
import ModalDialog from '@Components/dialogs/modal-dialog';
import {
  DialogTitle, DialogTitleSaleItemBig, DialogTitleBtn, DialogButtonsVertical, DialogButton
} from '@Components/dialogs/dialog-styles';
import CurrencyUtil from '@Utils/currency-util';
import CalcDisplay from '@Components/ui/calc-display';
import DiscountCalcDisplay from '@Components/pos/sale/discount-calc-display';
import QuantityCalcDisplay from '@Components/pos/sale/quantity-calc-display';
import { Column } from '@Components/ui/styled/main';
import Row from '@Components/ui/styled/row';
import Loader from '@Components/ui/loader';
import AlertWithIcon from '@Components/ui/alert-with-icon';

const CashierContainer = styled.div`
  position: relative;

  &::after {
    font-family: 'Font Awesome 6 Pro';
    position: absolute;
    pointer-events: none;
    content: '\f078';
    font-size: 12px;
    width: 14px;
    height: 14px;
    right: 10px;
    top: 50%;
    margin-top: -7px;
    z-index: 1;
  }
`;

const CashierTitle = styled.div`
  position: absolute;
  pointer-events: none;
  padding: 10px 12px;
  inset: 0;
`;

const CashierSelect = styled.select`
  background: ${colors.softGray40};
  border: solid 2px transparent !important;
  color: transparent;
  appearance: none;
  border-radius: 8px;
  padding: 8px 10px;
  height: 38px;
  width: 100%;

  &:focus {
    border: solid 2px ${colors.darkGray} !important;
  }
`;

const CommentInput = styled.input`
  border-radius: 8px;
  padding: 6px 10px;
  width: 100%;
  height: 38px;
  background: ${colors.softGray40};
  border: solid 2px transparent !important;
  margin-bottom: 10px;

  &:focus {
    border: solid 2px ${colors.darkGray} !important;
  }
  &::placeholder {
    color: ${colors.darkGray80};
  }
`;

function getActiveType(showAmount, showDiscount, selectedType) {
  const defaultType = !showAmount && showDiscount ? 'discount' : 'quantity';
  return selectedType || defaultType;
}

function getActiveValue(activeType, discountValueString, amountValueString, quantityString) {
  switch (activeType) {
    case 'amount':
      return amountValueString;
    case 'discount':
      return discountValueString;
    default:
      return quantityString;
  }
}

class LargeNumpadModal extends Component {
  constructor(props) {
    super(props);
    this.commentInput = null;

    const { showAmount, showDiscount, selectedType } = this.props;
    const {
      discountType, discountValue, quantity, amount, comment, sellerId
    } = props.item || {};

    const discountValueString = discountValue ? convertToString(discountValue) : '';
    const amountValueString = amount ? convertToString(amount) : '';
    const quantityString = String(quantity || 1);

    const activeType = getActiveType(showAmount, showDiscount, selectedType);
    const activeValue = getActiveValue(activeType, discountValueString, amountValueString, quantityString);

    this.state = {
      activeType,
      activeValue,
      discountValue: discountValueString,
      discountType: discountType || 'Percentage',
      quantity: quantityString,
      amount: amountValueString,
      cashierId: sellerId,
      isPristine: true,
      comment: comment || '',
      progress: false
    };
  }

  componentDidMount() {
    pauseScanner();
    window.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    resumeScanner();
    window.removeEventListener('keydown', this.onKeyDown);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { amount } = nextProps.item || {};
    if (amount && amount !== this.props.item?.amount) {
      const amountValueString = amount ? convertToString(amount) : '';
      this.setState({ amount: amountValueString });
    }
  }

  onKeyDown = (ev) => {
    switch (ev.keyCode) {
      case 9: // Tab
        if (ev.shiftKey) {
          this.selectPrevType();
        } else {
          this.selectNextType();
        }
        ev.preventDefault();
        break;
    }
  };

  getCurrentTypes = () => {
    const { showQuantity, showAmount, showDiscount, showComment, showCashier } = this.props;
    return [
      showQuantity && 'quantity',
      showAmount && 'amount',
      showDiscount && 'discount',
      showComment && 'comment',
      showCashier && 'cashier'
    ].filter(t => t);
  };

  selectNextType = () => {
    const { activeType } = this.state;
    const types = this.getCurrentTypes();
    const currentIndex = types.indexOf(activeType);

    if (currentIndex + 1 < types.length) {
      this.selectType(types[currentIndex + 1]);
    }
  };

  selectPrevType = () => {
    const { activeType } = this.state;
    const types = this.getCurrentTypes();
    const currentIndex = types.indexOf(activeType);

    if (currentIndex > 0) {
      this.selectType(types[currentIndex - 1]);
    }
  };

  selectType = (type) => {
    switch (type) {
      case 'quantity':
        return this.selectQuantityInput();
      case 'amount':
        return this.selectAmountInput();
      case 'discount':
        return this.selectDiscountInput();
      case 'comment':
        return this.selectCommentInput();
      case 'cashier':
        return this.selectCashierInput();
    }
  };

  onChangeQuantity = (quantity) => {
    const value = String(quantity);
    this.setState({
      isPristine: false,
      activeType: 'quantity',
      activeValue: value,
      quantity: value
    });
  };

  onChangeDiscountType = (discountType) => {
    const { discountValue } = this.state;
    const resetValue = discountType === 'Percentage' && discountValue > maxDiscount;
    this.setState({
      isPristine: resetValue,
      activeType: 'discount',
      activeValue: resetValue ? '' : discountValue,
      discountValue: resetValue ? '' : discountValue,
      discountType
    });
  };

  onChangeValue = (value) => {
    const { discountType, activeType } = this.state;

    if (activeType === 'quantity') {
      if (isValidInput(value, 4)) {
        const isPristine = value < 1;
        const quantity = String(isPristine ? 1 : value);
        this.setState({ isPristine, activeValue: quantity, quantity });
      }
    } else if (activeType === 'amount') {
      if (isValidInput(value, 9)) {
        this.setState({ isPristine: false, activeValue: value, amount: value });
      }
    } else if (activeType === 'discount') {
      switch (discountType) {
        case 'Amount':
          if (isValidInput(value, 9)) {
            this.setState({ isPristine: false, activeValue: value, discountValue: value });
          }
          break;
        case 'Percentage':
          if (isValidInput(value, 5, maxDiscount)) {
            this.setState({ isPristine: false, activeValue: value, discountValue: value });
          }
          break;
      }
    }
  };

  onSetAmount = (value) => {
    this.setState({ isPristine: false, activeValue: value, amount: value });
  };

  onCommentChange = (ev) => {
    this.setState({ comment: ev.target.value });
  };

  onCashierChange = (ev) => {
    this.setState({ cashierId: ev.target.value });
  };

  onSave = () => {
    const {
      discountType, discountValue, quantity, amount, cashierId, comment
    } = this.state;

    this.setState({ progress: true });
    return this.props.onSave({
      discountType,
      discountValue: convertToFloat(discountValue),
      quantity: parseInt(quantity),
      amount: convertToFloat(amount),
      cashierId: cashierId ? parseInt(cashierId) : null,
      comment
    }).catch(() => this.setState({ progress: false }));
  };

  selectQuantityInput = (ev) => {
    ev?.stopPropagation();
    const { quantity } = this.state;
    this.commentInput?.blur();
    this.setState({
      activeValue: quantity,
      activeType: 'quantity',
      isPristine: true
    });
  }

  selectAmountInput = (ev) => {
    ev?.stopPropagation();
    const { amount } = this.state;
    this.commentInput?.blur();
    this.setState({
      activeValue: amount,
      activeType: 'amount',
      isPristine: true
    });
  }

  selectDiscountInput = (ev) => {
    ev?.stopPropagation();
    const { discountValue } = this.state;
    this.commentInput?.blur();
    this.setState({
      activeValue: discountValue,
      activeType: 'discount',
      isPristine: true
    });
  }

  selectCashierInput = () => {
    this.cashierInput?.focus();
    this.setState({
      activeValue: '',
      activeType: 'cashier'
    });
  }

  selectCommentInput = () => {
    this.commentInput?.focus();
    this.setState({
      activeValue: '',
      activeType: 'comment'
    });
  }

  onCommentInputClick = (ev) => {
    this.selectCommentInput();
    ev.stopPropagation();
  };

  onCashierSelectClick = (ev) => {
    this.selectCashierInput();
    ev.stopPropagation();
  };

  selectNoInput = () => {
    this.setState({
      activeValue: '',
      activeType: null
    });
  }

  render() {
    const {
      item, onClose, grossAmount, showAmount, showComment, showQuantity, showDiscount, subtitle,
      amountLabel, helpText, warningLabel, hintLabel, loading, commentPlaceholder, showCashier,
      cashiersById, amountPresets
    } = this.props;
    const {
      activeValue, activeType, discountType, discountValue, quantity, amount,
      isPristine, comment, progress, cashierId
    } = this.state;
    const { description, itemType } = item || {};

    const canSave = showQuantity || convertToFloat(discountValue) > 0;
    const title = this.props.title || (item ? getItemDescription(description, itemType) : 'Rabatt');
    const cashierName = cashierId ? cashiersById.get(+cashierId)?.get('name') : null;
    const amountValue = convertToFloat(amount);
    const totalValue = !item || itemType === 'Discount' ? grossAmount : amountValue * quantity;
    const discountAmount = getDiscountAmount(discountType, convertToFloat(discountValue), totalValue);
    const numPadDisabled = !activeType || activeType == 'comment' || loading;

    return (
      <ModalDialog onClose={onClose} contentSize="xl">
        {loading && <Loader />}
        <Row>
          <Column onClick={this.selectNoInput}>
            <DialogTitleSaleItemBig>
              {title}
              {subtitle && <small>{subtitle}</small>}
            </DialogTitleSaleItemBig>
            {showQuantity && (
              <QuantityCalcDisplay
                onClick={this.selectQuantityInput}
                active={activeType === 'quantity'}
                quantity={parseInt(quantity)}
                onChange={this.onChangeQuantity}
              />
            )}
            {showAmount && (
              <CalcDisplay
                onClick={this.selectAmountInput}
                active={activeType === 'amount'}
                label={amountLabel || 'Pris / st'}
                onSelectPreset={this.onSetAmount}
                presetButtons={amountPresets}
              >
                {loading ? '-' : CurrencyUtil.accountCurrency(amountValue, 2)}
              </CalcDisplay>
            )}
            {showDiscount && (
              <DiscountCalcDisplay
                active={activeType === 'discount'}
                onClick={this.selectDiscountInput}
                onChangeType={this.onChangeDiscountType}
                discountValue={discountValue}
                discountType={discountType}
              />
            )}
            {showComment && (
              <CommentInput
                type="text"
                value={comment}
                onChange={this.onCommentChange}
                className="form-control"
                placeholder={commentPlaceholder || 'Anteckning...'}
                ref={(ref) => { this.commentInput = ref; }}
                onClick={this.onCommentInputClick}
              />
            )}
            {showCashier && (
              <CashierContainer>
                <CashierTitle>Säljare: {cashierName}</CashierTitle>
                <CashierSelect
                  onChange={this.onCashierChange}
                  onClick={this.onCashierSelectClick}
                  ref={(ref) => { this.cashierInput = ref; }}
                  value={cashierId}
                >
                  <option disabled>Välj säljare</option>
                  {cashiersById.valueSeq().sortBy(u => u.get('name')).map((item) => {
                    const { id, name } = item.toObject();
                    return <option key={id} value={id}>{name}</option>;
                  })}
                </CashierSelect>
              </CashierContainer>
            )}
            {helpText && (
              <p className="text-muted mt1">{helpText}</p>
            )}
            {warningLabel && (
              <AlertWithIcon warning className="mt2">
                {warningLabel}
              </AlertWithIcon>
            )}
            {hintLabel && (
              <AlertWithIcon icon="fa fa-lightbulb" className="mt2">
                {hintLabel}
              </AlertWithIcon>
            )}
            {showDiscount && (
              <CalcDisplay
                align="below"
                labelPosition="above"
                label="Totalt"
                noPanel
              >
                {CurrencyUtil.accountCurrency(totalValue - discountAmount, 2)}
              </CalcDisplay>
            )}
          </Column>
          <Column className="sale-gray-bg">
            <DialogTitle>
              <DialogTitleBtn onClick={onClose}>Avbryt</DialogTitleBtn>
            </DialogTitle>
            <Numpad
              btnTransparent
              disabled={numPadDisabled}
              input={activeValue}
              onChange={this.onChangeValue}
              onEnter={canSave ? this.onSave : null}
              isPristine={isPristine}
            />
            <DialogButtonsVertical>
              <DialogButton
                onClick={this.onSave}
                disabled={!canSave || loading}
                loading={progress}
                primary
              >
                OK
              </DialogButton>
            </DialogButtonsVertical>
          </Column>
        </Row>
      </ModalDialog>
    );
  }
}

export default LargeNumpadModal;
