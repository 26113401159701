import React, { useState } from 'react';

const SetMaxSlots = ({ onCancel, onConfirm, maxSlots, progress }) => {
  const [formMaxSlots, setFormMaxSlots] = useState(maxSlots);

  return (
    <form autoComplete="off" onSubmit={onConfirm} className={progress ? 'disabled' : ''}>
      <div className="row tight">
        <div className="form-group col-xs-6">
          <label className="control-label">Antal platser</label>
          <input
            type="number"
            name="maxSlots"
            value={formMaxSlots}
            className="form-control"
            onChange={(ev) => setFormMaxSlots(ev.target.value)}
          />
        </div>
      </div>

      <div className="text-right">
        <button type="button" className="btn btn-default" tabIndex={2} onClick={onCancel}>Avbryt</button> &nbsp;
        <button type="submit" className="btn-save" tabIndex={1}>{progress ? 'Sparar...' : 'Spara'}</button>
      </div>
    </form>
  );
};

export default SetMaxSlots;
