import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';
import {
  isTheSameDay, getTimeStaffCreatedTime, getTimeStaffCreated, getTimeHours, getDate
} from '@Utils/time-util';

import StaffEditEntryPopup from '@Components/staff/staff-edit-entry-popup';
import { deleteEntry } from '@State/staff-actions';
import { colors, fsw, fs } from '@Components/ui/styled/variables';
import { Flex } from '@Components/ui/styled/main';
import { classes } from '@Components/ui/utils';

const EntryStyle = styled(Flex)`
  padding: 10px 0;
  cursor: ${p => p.deleted ? 'default' : 'pointer'};
  font-size: ${fs.s};
  font-weight: ${fsw.bold};
  div::first-letter{
    text-transform: capitalize;
  }
`;

const ManualStyle = styled.span`
  padding-right: 5px;
  ::after{
    content: 'M';
  }
`;

const TimeWrapper = styled(Flex)`
  i {
    margin: 0 10px;
    align-self: center;
  }
  span {
    min-width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
  }
  span:last-child{
    justify-content: flex-start;
  }
  span:first-child{
    justify-content: flex-end;
  }
`;

const StaffEntryRow = ({
  manualEntry, deleted = true, startTime, endTime, id, userId
}) => {
  const dispatch = useDispatch();
  const [openDialogChangeTime, setOpenDialogChangeTime] = useState(false);
  const isEntryHasSameDay = isTheSameDay(startTime, endTime);

  const toggleDialogTime = () => !deleted && setOpenDialogChangeTime(!openDialogChangeTime);

  const hideDialog = useCallback(() => {
    setOpenDialogChangeTime(false);
  }, []);

  const onDelete = useCallback(async (signature) => {
    try {
      await dispatch(deleteEntry({ entryId: id, signature }));
    } finally {
      hideDialog();
    }
  }, []);

  const date = getDate(startTime || endTime);

  const textClasses = classes({
    'text-through': deleted,
    'text-muted-staff': deleted
  });
  const iconClasses = classes({
    fal: true,
    'fa-long-arrow-right': true,
    'text-muted-staff': deleted
  });

  return (
    <StaffEditEntryPopup
      id={id}
      userId={userId}
      isHideDatePicker
      isEntryHasDifferentDay={!isEntryHasSameDay}
      start={startTime}
      end={endTime}
      date={date}
      isShowPopover={openDialogChangeTime}
      handleClose={hideDialog}
      handleDelete={onDelete}
      showDelete
    >
      <EntryStyle onClick={toggleDialogTime} vCenter fullWidth deleted={deleted}>
        <div className={textClasses}>
          {isEntryHasSameDay ? getTimeStaffCreated(startTime) : getTimeStaffCreatedTime(startTime) }
        </div>
        {!isEntryHasSameDay && (
        <span className={textClasses}> <i className="fal fa-long-arrow-right" />
        </span>
        )}
        <div>
          {isEntryHasSameDay
            ? (
              <TimeWrapper fullWidth>
                {manualEntry && <span className={textClasses}><ManualStyle /></span>}
                <span className={textClasses}>{startTime && getTimeHours(startTime)}</span>
                <i className={iconClasses} />
                <span className={textClasses}>{endTime && getTimeHours(endTime)}</span>
              </TimeWrapper>
            )
            : (
              <div className={textClasses}>
                {manualEntry && <ManualStyle />} {getTimeStaffCreatedTime(endTime)}
              </div>
            )}
        </div>
      </EntryStyle>
    </StaffEditEntryPopup>
  );
};

StaffEntryRow.propTypes = {
  deleted: PropTypes.bool,
  time: PropTypes.string,
  startTime: PropTypes.string,
  end: PropTypes.string
};

export default memo(StaffEntryRow, (prevProps, nextProps) => isEqual(prevProps, nextProps));
