import { defineMessages } from 'react-intl';

export default defineMessages({
  resource: {
    id: 'calendar.bkf.resource.resource',
    defaultMessage: 'Resource'
  },
  resources: {
    id: 'calendar.bkf.resource.resources',
    defaultMessage: 'Resources'
  },
  btnAdd: {
    id: 'calendar.bkf.resource.btnAdd',
    defaultMessage: 'Resources'
  },
  btnChange: {
    id: 'calendar.bkf.resource.btnChange',
    defaultMessage: 'Resources'
  }
});
