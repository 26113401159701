import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Autosuggest from 'react-autosuggest';
import CurrencyUtil from '@Utils/currency-util';
import { escapeRegexCharacters } from '@Components/ui/utils';
import NoContent from '@Components/ui/no-content';
import { triggerScan } from '@Utils/barcode';

class SearchProducts extends Component {
  static propTypes = {
    products: PropTypes.array.isRequired,
    onSelect: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      value: '',
      suggestions: this.getSuggestions('', this.getProducts(props))
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.products !== nextProps.products) {
      this.setState({
        suggestions: this.getSuggestions(this.state.value, this.getProducts(nextProps))
      });
    }
  }

  getProducts = (props) => props.products;

  getSuggestions(value, products) {
    const escapedValue = escapeRegexCharacters(value).trim();
    if (!value || escapedValue.length < 2) {
      return [];
    }

    const regex = new RegExp(`${escapedValue}`, 'i');
    const filteredProducts = products.filter(p => regex.test(p.name)).valueSeq().toArray();

    return [{ name: value }, ...filteredProducts];
  }

  onSuggestionsFetchRequested = ({ value, reason }) => {
    if (reason === 'suggestion-selected') {
      return;
    }

    this.setState({
      suggestions: this.getSuggestions(value, this.getProducts(this.props))
    });
  };

  onSuggestionSelected = (event, { suggestion }) => {
    event.preventDefault();
    event.stopPropagation();
    this.selectSuggestion(suggestion);
  };

  onSuggestionHighlighted = ({ suggestion }) => {
    this.highlightedSuggestion = suggestion;
  };

  onChange = (event, { newValue, method }) => {
    this.setState({ value: newValue });
  };

  onKeyPress = (ev) => {
    if (ev.shiftKey && ev.ctrlKey && ev.key === 'Enter') {
      triggerScan(this.state.value);
    }
  };

  handleCancel = (ev) => {
    ev.preventDefault();
    this.props.onCancel();
  };

  selectSuggestion = (suggestion) => {
    this.props.onSelect(suggestion);
  };

  renderInputComponent = (inputProps) => {
    return (
      <input type="text" {...inputProps} />
    );
  };

  renderCreateItem = () => {
    return (
      <div className="booking-form-suggestion new-item">
        <section>
          <i className="fa fa-fw fa-box text-muted" /> <strong>Lägg till produkt</strong>
        </section>
      </div>
    );
  };

  renderProduct = (item) => {
    return (
      <div>
        <strong>
          {item.brand && `${item.brand}, `}
          {item.name}
        </strong><br />
        {item.priceOut && (
          <span>
            {CurrencyUtil.accountCurrency(item.priceOut, 2)}
          </span>
        )}
      </div>
    );
  };

  renderSuggestion = (suggestion) => {
    return suggestion.id ? (
      <div className="booking-form-suggestion">
        {this.renderProduct(suggestion)}
      </div>
    ) : this.renderCreateItem();
  };

  render() {
    const { value, suggestions } = this.state;

    const inputProps = {
      value,
      autoFocus: true,
      placeholder: 'Sök produkt...',
      className: 'form-control',
      onChange: this.onChange,
      onBlur: () => {},
      onFocus: () => {},
      onKeyPress: this.onKeyPress
    };

    const theme = {
      suggestionsList: 'booking-form-suggestions',
      suggestionHighlighted: 'highlighted',
      suggestionsContainer: 'booking-form-suggestions-container'
    };

    return (
      <div className="autosuggest-modal-container">
        <Autosuggest
          theme={theme}
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionHighlighted={this.onSuggestionHighlighted}
          onSuggestionSelected={this.onSuggestionSelected}
          renderInputComponent={this.renderInputComponent}
          renderSuggestion={this.renderSuggestion}
          getSuggestionValue={() => value}
          alwaysRenderSuggestions
          shouldRenderSuggestions={() => true}
          focusInputOnSuggestionClick={false}
          inputProps={inputProps}
        />
        {suggestions.length === 0 && (
          <NoContent icon="fa fa-scanner-gun">
            Scanna en streckkod eller sök produkt
          </NoContent>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { products } = state;

  return {
    products
  };
};

export default connect(mapStateToProps)(SearchProducts);
