import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useKeyPress from '@Hooks/useKeyPress';
import { addBookingCustomer, fetchBookingReminders, toggleBKFModal } from '@State/bkf/actions';
import { bkfModals } from '@State/bkf/constants';
import { CustomerType } from '@Utils/customer-util';
import Modal from '@Components/ui/modal';
import { DialogTitleBtn } from '@Components/dialogs/dialog-styles';
import SearchCustomerModal from '@Components/pos/sale/search-customer-modal';
import SendSmsModal from '@Components/customers/customer-detail/send-sms/send-sms-modal';
import BookingClassCustomer from './booking-class-customer';
import BookingClassOptions from './booking-class-options';

const BookingClassModal = ({ routeParams }) => {
  const dispatch = useDispatch();
  const formVisible = useSelector(({ cf }) => cf.get('formVisible'));
  const bookingId = useSelector(state => state.bkf.get('id'));
  const services = useSelector(state => state.bkf.get('services'));
  const startTime = useSelector(state => state.bkf.get('startTime'));
  const customers = useSelector(state => state.bkf.get('customers'));
  const bookedSlots = useSelector(state => state.bkf.get('bookedSlots'));
  const maxSlots = useSelector(state => state.bkf.get('maxSlots'));

  const [selected, setSelected] = useState([]);
  const [showSendSms, setShowSendSms] = useState(false);
  const [showAddCustomer, setShowAddCustomer] = useState(false);

  // TODO: Add possibility to show cancelled customers
  const filteredCustomers = customers?.filter(c => c.status !== 'Cancelled');

  const hasCustomers = filteredCustomers?.size > 0;
  const noneSelected = selected.length === 0;
  const allSelected = !noneSelected && selected.length === filteredCustomers.size;

  useEffect(() => {
    dispatch(fetchBookingReminders(bookingId));

    document.body.classList.add('print-hidden');

    return () => {
      document.body.classList.remove('print-hidden');
    };
  }, []);

  const onSelect = (customerId) => {
    if (selected.includes(customerId)) {
      setSelected(selected.filter(s => s !== customerId));
    } else {
      setSelected([...selected, customerId]);
    }
  };

  const onSelectAll = (ev) => {
    ev.stopPropagation();
    setSelected(allSelected ? [] : filteredCustomers.keySeq().toArray());
  };

  const onCloseForm = () => dispatch(toggleBKFModal(bkfModals.class, false));

  const onAddCustomer = (ev) => {
    ev.preventDefault();
    ev.target.blur();
    setShowAddCustomer(true);
  };

  const onCustomerSelected = (customer) => {
    dispatch(addBookingCustomer(bookingId, customer));
    setShowAddCustomer(false);
  };

  useKeyPress(27, () => {
    if (!showSendSms && !formVisible) {
      onCloseForm();
    }
  });

  if (formVisible) {
    return null;
  }

  if (showSendSms) {
    const customer = filteredCustomers.first();
    return (
      <SendSmsModal
        customerId={customer.customerId}
        phoneNumber={customer.phoneNumber}
        onClose={() => setShowSendSms(false)}
      />
    );
  }

  if (showAddCustomer) {
    return (
      <SearchCustomerModal
        routeParams={routeParams}
        onSelected={onCustomerSelected}
        onCancel={() => setShowAddCustomer(false)}
        maxHeight={document.body.scrollHeight}
        height={400}
        scope={[CustomerType.Person]}
      />
    );
  }

  return (
    <Modal
      titleText="Deltagarlista"
      focusTrapPaused
      focusDialog
      underlayClickExits={false}
      mounted
      dialogClass="modal-dialog booking-class-modal-dialog"
    >
      <div className="modal-content">
        <div className="modal-body">
          <div className="pull-right">
            <DialogTitleBtn onClick={onCloseForm}>Stäng</DialogTitleBtn>
          </div>
          <div className="print-visible">
            <h4 className="mt0 mb1">
              Deltagarlista
              <span>({bookedSlots || 0}/{maxSlots})</span>
            </h4>
            <p className="mb0">{services.map(s => s.name).join(', ')}</p>
            <p className="text-muted">{startTime.format('LLLL')}</p>
          </div>
          <div className="booking-class-modal-content" tabIndex={0}>
            {hasCustomers ? (
              <div className="booking-class-customer-header">
                <button type="button" className="btn btn-default" onClick={onSelectAll}>
                  <div className="select">
                    <input type="checkbox" checked={allSelected} onChange={onSelectAll} />
                    Välj alla
                  </div>
                </button>
                <div className="options">
                  <button type="button" className="btn btn-default" onClick={onAddCustomer}>
                    <i className="fa fa-user-plus mr1" />Lägg till
                  </button>
                  <BookingClassOptions
                    bookingId={bookingId}
                    customerIds={selected}
                    disabled={noneSelected}
                    onSetCustomerIds={setSelected}
                    onShowSendSms={setShowSendSms}
                  />
                </div>
              </div>
            ) : (
              <button type="button" className="btn btn-default" onClick={onAddCustomer}>
                <i className="fa fa-user-plus mr1" />Lägg till
              </button>
            )}
            <div className="print-visible">
              {hasCustomers ? filteredCustomers.valueSeq().map(customer => {
                return (
                  <BookingClassCustomer
                    key={customer.customerId}
                    customer={customer}
                    bookingId={bookingId}
                    selected={selected.includes(customer.customerId)}
                    onSelect={() => onSelect(customer.customerId)}
                  />
                );
              }) : (
                <p className="text-center mt6 mb4">Inga deltagare</p>
              )}
            </div>
          </div>
          <div className="text-right">
            <button type="button" className="btn btn-default" onClick={onCloseForm}>Stäng</button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default BookingClassModal;
