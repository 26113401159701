import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { webBookingUrl } from '@Utils/config';
import { company, config } from '@Utils/preference-keys';
import SubmitButton from '@Components/ui/submit-button';
import AlertWithIcon from '@Components/ui/alert-with-icon';
import TextInputHorizontal from '../../inputs/text-input-horizontal';
import CompanyAddress from '../../inputs/company-address';

class CompanyInfoForm extends Component {
  render() {
    const { handleSubmit, enableMicrosite, locationConfig } = this.props;
    const bookingUrl = `${webBookingUrl()}${locationConfig.get(config.slug)}/`;

    return (
      <form autoComplete="off" className="form-horizontal" onSubmit={handleSubmit}>

        <div className="row">
          <div className="col-sm-offset-3 col-sm-9">
            <h3>Kontaktuppgifter</h3>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-offset-3 col-sm-9">
            <p className="text-muted">
              Informationen visas när kunder bokar online, och i bokningsbekräftelser via mejl och sms.
            </p>
          </div>
        </div>

        <br />

        <Field name={company.companyName} component={TextInputHorizontal} label="Företagsnamn" />
        <Field name={company.contactNumber} component={TextInputHorizontal} label="Telefon" />
        <Field name={company.contactEmail} component={TextInputHorizontal} label="E-post" />
        <Field name={company.contactWebsite} component={TextInputHorizontal} label="Webb" />

        <CompanyAddress disabled={enableMicrosite} />

        <div className="row">
          <div className="col-sm-offset-3 col-sm-5">
            <AlertWithIcon icon="fa fa-info-circle">
              För att ändra adressen behöver du gå till din <a href={bookingUrl} target="_blank">bokningssida</a>.
            </AlertWithIcon>
          </div>
        </div>
        <br />

        <div className="form-group">
          <div className="col-sm-offset-3 col-sm-6">
            <SubmitButton {...this.props} />
          </div>
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: 'company-info-form',
  enableReinitialize: true
})(CompanyInfoForm);
