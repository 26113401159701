export const colors = {
  primaryDark: 'var(--color-primary-dark)',
  primaryLight: 'var(--color-primary-light)',
  calendarToday: 'var(--color-calendar-today)',
  calendarTodayText: 'var(--color-calendar-today-text)',

  textColor: 'var(--color-text)',
  textMuted: 'var(--color-text-muted)',
  linkColor: 'var(--color-link)',

  darkGray: 'var(--color-dark-gray)',
  darkGray80: 'var(--color-dark-gray-80)',
  darkGray60: 'var(--color-dark-gray-60)',
  darkGray40: 'var(--color-dark-gray-40)',
  darkGray30: 'var(--color-dark-gray-30)',

  softGray: 'var(--color-soft-gray)',
  softGray80: 'var(--color-soft-gray-80)',
  softGray60: 'var(--color-soft-gray-60)',
  softGray40: 'var(--color-soft-gray-40)',
  softGray20: 'var(--color-soft-gray-20)',
  softGray10: 'var(--color-soft-gray-10)',

  success: 'var(--color-success)',
  danger: 'var(--color-danger)',
  warning: 'var(--color-warning)',
  warningBg: 'var(--color-warning-bg)',
  successBg: 'var(--color-success-bg)',
  statusGreen: 'var(--color-status-green)',
  statusBlue: 'var(--color-status-blue)',
  statusYellow: 'var(--color-status-yellow)',

  customerTitleRed: 'var(--color-customer-red)',
  customerTitleGreen: 'var(--color-customer-green)',
  customerNoteBg: 'var(--color-customer-note)',
  customerLogBg: 'var(--color-event-log)',
  customerBookingBg: 'var(--color-event-booking)',
  customerSaleBg: 'var(--color-event-sale)',
  customerSmsBg: 'var(--color-event-sms)',
  customerGiftcardBg: 'var(--color-event-giftcard)',

  labelRed: 'var(--color-label-red)',
  labelOrange: 'var(--color-label-orange)',
  labelBlue: 'var(--color-label-blue)',
  labelTurquoise: 'var(--color-label-turquoise)',
  labelGray: 'var(--color-label-gray)',
  labelGreen: 'var(--color-label-green)',
  labelNews: 'var(--color-label-news)'
};

export const fsw = {
  heavy: 700,
  bold: 600,
  medium: 500,
  book: 400,
  light: 300
};

export const fs = {
  s: '13px',
  l: '15px',
  xl: '17px'
};

export const screens = {
  xs: '600px',
  sm: '600px',
  phone: '767px',
  md: '1000px',
  lg: '1200px'
};

export default {
  cl: colors,
  fs,
  fsw
};
