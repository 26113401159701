import React, { useContext, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { fetchCustomerAssociations } from '@State/customer-actions';
import customerIdContext from '@Components/customers/customer-id-context';
import { getCustomerEmployeesUrl } from '@Utils/navigate';

import Row from '@Components/ui/styled/row';
import CustomerEmptyTab from '@Components/customers/customer-detail/customer-empty-tab';
import Loader from '@Components/ui/loader';
import { ColumnLeft, ColumnRight, ContentBlock, ScrollContent, ScrollWrapper, TitleBlock } from '@Components/customers/customer-styles';
import { txt } from '@Utils/i18n-util';
import CustomerEmployeeList from './customer-employee-list';
import msg from './customer-employees.msg';
import CustomerEmployeeDetails from './customer-employee-details';

const CustomerEmployees = ({ routeParams, tabId, onSelectEmployee }) => {
  const dispatch = useDispatch();
  const id = tabId || routeParams?.tabId;
  const customerId = +useContext(customerIdContext);
  const loading = useSelector((state) => state.customerAssociations.get('loading'));
  const associations = useSelector((state) => state.customerAssociations.get('associations'));
  const employees = associations.filter((a) => a.associationType === 'EmployeeOfCompany');
  const phoneMode = useSelector(state => state.mainViewState.get('phoneMode'));

  const selectedEmployee = useMemo(() => {
    if (employees.length === 0) return null;
    if (id) {
      const itemById = employees.find((g) => g.otherCustomerId === +id);
      if (itemById) return (itemById);
    } else {
      const first = employees[0];
      if (first) return (first);
    }
  }, [employees, id]);

  useEffect(() => {
    dispatch(fetchCustomerAssociations(customerId));
  }, [customerId]);

  const handlerOnClick = (item) => {
    onSelectEmployee(item.otherCustomerId, 'employees');
  };

  if (employees && employees.length > 0 && routeParams && !routeParams?.tabId && selectedEmployee) {
    return <Redirect to={getCustomerEmployeesUrl(routeParams, selectedEmployee?.otherCustomerId)} />;
  }

  if (loading) {
    return <Loader topPosition />;
  }

  if (employees.length === 0) {
    return (
      <CustomerEmptyTab
        title={txt(msg.noEmployeesTitle)}
        text={txt(msg.noEmployeesText)}
      />
    );
  }

  const renderEmployeeDetails = () => {
    return (
      <>
        <TitleBlock>{txt(msg.lblDetails)}</TitleBlock>
        <ContentBlock>
          <CustomerEmployeeDetails {...selectedEmployee} />
        </ContentBlock>
      </>
    );
  };

  const renderEmployees = () => (
    <CustomerEmployeeList
      isSelectable
      selectedEventId={selectedEmployee?.otherCustomerId}
      onSelectEvent={handlerOnClick}
      items={employees}
    />
  );

  if (phoneMode) {
    return (
      <Row scrollContainer>
        <ScrollWrapper>
          <ScrollContent>
            {renderEmployees()}
          </ScrollContent>
        </ScrollWrapper>
      </Row>
    );
  }

  return (
    <Row scrollContainer>
      <ColumnLeft>
        <ScrollWrapper>
          <ScrollContent>
            {renderEmployees()}
          </ScrollContent>
        </ScrollWrapper>
      </ColumnLeft>
      <ColumnRight>
        <ScrollWrapper>
          <ScrollContent>
            {renderEmployeeDetails()}
          </ScrollContent>
        </ScrollWrapper>
      </ColumnRight>
    </Row>
  );
};

export default CustomerEmployees;
