import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addCustomerAssociation, fetchCustomerAssociations } from '@State/customer-actions';
import { PanelWrapper, StyledLink, TitleBlock } from '@Components/customers/customer-styles';
import { txt } from '@Utils/i18n-util';
import ColumnListItem from '@Components/ui/column/column-list-item';
import { CustomerType, getCustomerTypeIcon } from '@Utils/customer-util';
import SearchCustomerModal from '@Components/pos/sale/search-customer-modal';
import msg from './customer-information.msg';

const CustomerCompany = ({ routeParams, selectedCustomer }) => {
  const dispatch = useDispatch();
  const { id, customerType } = selectedCustomer || {};
  const customerAssociations = useSelector((state) => state.customerAssociations);
  const loading = customerAssociations.get('loading');
  const associations = customerAssociations.get('associations');
  const associationType = customerType === CustomerType.Vehicle ? 'VehicleOfCompany' : 'EmployeeOfCompany';
  const company = associations.find((a) => a.associationType === associationType);

  useEffect(() => {
    if (id) {
      dispatch(fetchCustomerAssociations(id));
    }
  }, [id]);

  const [showCustomerCompanyModal, setShowCustomerCompanyModal] = useState(false);
  const hideCustomerCompanyModal = () => setShowCustomerCompanyModal(false);

  const handleSetCustomerCompany = ({ customerId }) => {
    return dispatch(addCustomerAssociation(id, customerId, associationType))
      .then(hideCustomerCompanyModal);
  };

  return (
    <>
      <TitleBlock>{txt(msg.lblCompany)}</TitleBlock>
      {company ? (
        <ColumnListItem
          onClick={() => setShowCustomerCompanyModal(true)}
          key={company.otherCustomerId}
          icon={getCustomerTypeIcon(CustomerType.Company)}
          header={company.name}
          subText={company.officialIdNo}
        />
      ) : (
        <PanelWrapper onClick={() => setShowCustomerCompanyModal(true)}>
          <StyledLink>{txt(msg.lblAddToCompany)}</StyledLink>
        </PanelWrapper>
      )}
      {showCustomerCompanyModal && (
        <SearchCustomerModal
          routeParams={routeParams}
          onSelected={handleSetCustomerCompany}
          onCancel={hideCustomerCompanyModal}
          maxHeight={document.body.scrollHeight}
          height={400}
          scope={[CustomerType.Company]}
        />
      )}
    </>
  );
};

export default CustomerCompany;
