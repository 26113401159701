import React, { useState, memo } from 'react';
import styled, { css } from 'styled-components';
import { navigateAsync } from '@Utils/navigate';
import { colors } from '@Components/ui/styled/variables';

const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
`;

const Ul = styled.ul`
    align-items: center;
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: center;
    list-style: none;
    border-bottom: none;
    padding:0;
    margin: 0;
`;

const Li = styled.li`
  display: block;
  min-width: 65px;

  a:active {
    background: ${colors.softGray10};
  }

  &+li {
    margin-left: -1px;
  }
  &:first-child a {
    border-radius: 4px 0 0 4px;
  }
  &:last-child a {
    border-radius: 0 4px 4px 0;
  }

  &.is-active {
    z-index: 1;
    a {
      background-color: ${colors.primaryDark};
      border-color: ${colors.primaryDark};
      color: #fff;
    }
  }

  ${p => p.disabled && css`
    a {
      pointer-events: none;
      cursor: default;
      opacity: 0.5;
    }
  `}
`;

const StyledLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: ${colors.darkGray};
  border-style: solid;
  border-width: 1px;
  border-color: ${colors.darkGray40};
  cursor: pointer !important;
  pointer-events: auto;
  min-height: 26px;
  min-width: ${props => props.phoneMode ? '50px' : '65px'};
  user-select: none;

  :hover, :active, :focus {
    text-decoration: none;
    color: ${colors.textColor};
  }

  ${props => props.large && css`
    padding: 5px 10px;
    min-width: 100px;
    min-height: 30px;
  `}
`;

const Navigate = (href, setClicked) => () => {
  setClicked(true);
  navigateAsync(href, () => setClicked(false));
};

export const SegmentItem = ({
  large, children, active, disabled, href, phoneMode
}) => {
  const [clicked, setClicked] = useState(false);
  const cls = clicked || active ? 'is-active' : '';

  return (
    <Li className={cls} disabled={disabled} phoneMode={phoneMode}>
      <StyledLink large={large} onClick={Navigate(href, setClicked)} phoneMode={phoneMode}><span>{children}</span></StyledLink>
    </Li>
  );
};

const Segment = ({ children, phoneMode }) => (
  <Wrapper phoneMode={phoneMode}>
    <Ul>
      {children}
    </Ul>
  </Wrapper>
);

export default memo(Segment);
