import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LabelSwitchInput from '@Components/ui/label-switch-input';
import { toggleMultiResourceMode } from '@State/view-actions';
import { getSingleResourceModeUrl } from '@State/calendar-selectors';
import { navigate } from '@Utils/navigate';
import InfoDialogButton from '@Components/dialogs/info-dialog-button';
import { txt } from '@Utils/i18n-util';
import msg from './multi-resource-toggle.msg';

const InfoDialogContent = () => (
  <>
    <p>{txt(msg.paragraph1)}</p>
    <p>{txt(msg.paragraph2)}</p>
    <p>{txt(msg.paragraph3)}</p>
  </>
);

const MultiResourceToggle = ({ routeParams }) => {
  const dispatch = useDispatch();
  const { viewMode, entityType } = routeParams;
  const multiResourceMode = useSelector(state => state.calendarViewState.get('multiResourceMode'));
  const singleResourceModeUrl = useSelector(state => getSingleResourceModeUrl(state, { routeParams }));

  useEffect(() => {
    if (viewMode === 'week' && entityType === 'group' && !multiResourceMode) {
      dispatch(toggleMultiResourceMode(true));
    }
  }, [viewMode, entityType]);

  const handleToggle = useCallback(() => {
    if (multiResourceMode && singleResourceModeUrl) {
      navigate(singleResourceModeUrl);
    }
    dispatch(toggleMultiResourceMode(!multiResourceMode));
  }, [multiResourceMode, singleResourceModeUrl]);

  return (
    <div className="multi-resource-toggle">
      <LabelSwitchInput
        noBorder
        labelClass="text-muted"
        label={(
          <>
            {txt(msg.lblToggle)}
            <InfoDialogButton title={txt(msg.dialogTitle)}>
              <InfoDialogContent />
            </InfoDialogButton>
          </>
        )}
        id="multiResourceMode"
        isOn={multiResourceMode}
        handleToggle={handleToggle}
      />
    </div>
  );
};

export default MultiResourceToggle;
