import moment from 'moment';
import { navigateTo } from '@Utils/navigate';
import {
  bookingChanged, removeTempBooking, bookingAdded, bookingCancelled, bookingDeleted, bookingMoved, bookingAttributeChanged, bookingStatusChanged
} from '@State/booking-actions';
import {
  addToClipboard, bookingPasted, removeFromClipboard as _removeFromClipboard, removeClipboardDragger, removePasteDragger
} from '@State/clipboard-actions';
import { toggleScheduleEditMode } from '@State/view-actions';
import { getScheduleBlocksForUpdate, scheduleBlocksUpdated } from '@State/schedule-actions';
import { getScheduleBounds } from '@Utils/schedule-utils';
import { logMeOut } from '@Utils/account';
import { isEmbeddedOrWrapped } from './embedded-util';

let reduxStore;

export function getLocationConfig() {
  return reduxStore.getState().locationConfig.toJS();
}

export function createWebkitBridges(store, state) {
  window.reduxStore = reduxStore = store;

  if (isEmbeddedOrWrapped(state)) {
    window.print = () => {
      postWebkitMessage('print', {});
    };
  }

  window.Cliento = {
    navigateTo,
    addBooking,
    updateBooking,
    clearTempBooking,
    deleteBooking,
    cancelBooking,
    moveBooking,
    moveViaClipboard,
    copyViaClipboard,
    pasteBooking,
    removeFromClipboard,
    addAndRemoveFromClipboard,
    cancelPasteFromClipboard,
    changeBookingFlag,
    changeBookingStatus,
    setScheduleEditMode,
    getGridViewState,
    getScheduleStartAndEnd,
    getScheduleBlocks,
    updateScheduleBlocks,
    clearAccessToken
  };
}

function updateBooking(booking) {
  console.log('wk-bridges - updateBooking()', booking);
  reduxStore.dispatch(bookingChanged(booking.id, { ...booking }));
}

function moveBooking(moveEvent) {
  console.log('wk-bridges - moveBooking()', moveEvent);
  reduxStore.dispatch(bookingMoved(moveEvent, false /* isUndo */));
}

function clearTempBooking() {
  console.log('wk-bridges - clearTempBooking()');
  reduxStore.dispatch(removeTempBooking('DRAGGER'));
}

function addBooking(booking) {
  console.log('wk-bridges - addBooking()', booking);
  reduxStore.dispatch(bookingAdded(booking));
}

/*
  window.Cliento.deleteBooking(123);
*/
function deleteBooking(bookingId) {
  reduxStore.dispatch(bookingDeleted(parseInt(bookingId)));
}

function cancelBooking(bookingId) {
  const changes = {
    cancelledTs: moment(),
    cancelledChannel: 'Cal',
    status: 'Cancelled',
    cancelled: true
  };
  reduxStore.dispatch(bookingCancelled(parseInt(bookingId), changes));
}

function moveViaClipboard(booking) {
  const { id, sourceResourceId } = booking;
  reduxStore.dispatch(addToClipboard(parseInt(id), false, sourceResourceId));
}

function copyViaClipboard(booking) {
  const { id, sourceResourceId } = booking;
  reduxStore.dispatch(addToClipboard(parseInt(id), true, sourceResourceId));
}

function pasteBooking(booking) {
  reduxStore.dispatch(bookingPasted(booking));
}

function removeFromClipboard(bkId) {
  reduxStore.dispatch(_removeFromClipboard(parseInt(bkId)));
  reduxStore.dispatch(removeClipboardDragger());
  reduxStore.dispatch(removePasteDragger());
}

function addAndRemoveFromClipboard(booking) {
  reduxStore.dispatch(bookingAdded(booking));
  reduxStore.dispatch(_removeFromClipboard(booking.id));
  reduxStore.dispatch(removeClipboardDragger());
  reduxStore.dispatch(removePasteDragger());
}

function cancelPasteFromClipboard() {
  reduxStore.dispatch(removeClipboardDragger());
  reduxStore.dispatch(removePasteDragger());
}

function changeBookingFlag(change) {
  reduxStore.dispatch(bookingAttributeChanged(change));
}

function changeBookingStatus(change) {
  reduxStore.dispatch(bookingStatusChanged(change));
}

export function postWebkitMessage(handler, json) {
  console.log('postWebkitMessage', handler, JSON.stringify(json));
  if (typeof window.webkit !== 'undefined') {
    const handlers = window.webkit.messageHandlers || {};
    handlers[handler]?.postMessage(JSON.stringify(json));
  } else {
    // console.error('No window.webkit object defined');
  }
}

export function notifyClipboardState(state) {
  postWebkitMessage('clipboardState', state);
}

export function notifyColumnHeaderState(state) {
  postWebkitMessage('columnHeaderState', state);
}

function getGridViewState() {
  const state = reduxStore.getState().gridViewState.toJS();
  postWebkitMessage('gridViewState', state);
}

function setScheduleEditMode(state) {
  reduxStore.dispatch(toggleScheduleEditMode(state));
}

function getScheduleStartAndEnd({ resourceId, date }) {
  const { schedulesByResource } = reduxStore.getState();
  const times = getScheduleBounds(schedulesByResource, resourceId, date);
  postWebkitMessage('scheduleStartAndEnd', {
    resourceId,
    date,
    start: times ? times.startTime.format('HH:mm') : null,
    end: times ? times.endTime.format('HH:mm') : null
  });
}

function getScheduleBlocks(exception) {
  const blocks = reduxStore.dispatch(getScheduleBlocksForUpdate(exception));
  postWebkitMessage('scheduleBlocks', {
    exception,
    blocks
  });
}

function updateScheduleBlocks({ resourceId, blocks }) {
  reduxStore.dispatch(scheduleBlocksUpdated(resourceId, blocks));
}

function clearAccessToken() {
  logMeOut(true);
}

// window.Cliento.navigateTo('2020-06-25', { org: 'cliento', loc: 'sthlm', viewMode: 'day', entityType: 'group', entityId: '2078' });
// window.Cliento.updateBooking({ id: 123, moreProps.... });
// window.Cliento.clearTempBooking();
// window.Cliento.cancelBooking(10972113);
// window.Cliento.deleteBooking(10972113);
// window.Cliento.copyViaClipboard(10972324);
// window.Cliento.moveViaClipboard(10972324);
// window.Cliento.pasteBooking({ id: 123, moreProps.... });
// window.Cliento.removeFromClipboard(10972113);
// window.Cliento.addAndRemoveFromClipboard({ id: 123, moreProps.... });
// window.Cliento.cancelPasteFromClipboard();
// window.Cliento.changeBookingFlag({ bookingId: 10972337, flags: {dropIn: true} });
// window.Cliento.changeBookingStatus({bookingId: 10972337, status: "Show"});
// window.Cliento.setScheduleEditMode(true);
// window.Cliento.getGridViewState();
// window.Cliento.clearAccessToken()
