import React from 'react';

import { colors } from '@Components/ui/styled/variables';
import EventCard from '@Components/ui/event-card';

const CustomerGiftCard = ({
  onSelectEvent, isSelectable = false, selectedEventId, isAddBorder = true, ...item
}) => {
  const { id, code, description } = item;
  const isSelected = selectedEventId === id;
  const handleSelectEvent = () => onSelectEvent && onSelectEvent(item);

  return (
    <EventCard
      isSelectable={isSelectable}
      isSelected={isSelected}
      borderColor={colors.customerGiftcardBg}
      handler={handleSelectEvent}
      icon="far fa-gift-card"
    >
      {description && (
        <strong>
          {description}
        </strong>
      )}
      <p className="text-muted">
        {code}
      </p>

    </EventCard>
  );
};

export default CustomerGiftCard;
