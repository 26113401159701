import React, { useEffect, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { fetchCustomerAssociations } from '@State/customer-actions';
import { getCustomerVehiclesUrl } from '@Utils/navigate';
import { CustomerType } from '@Utils/customer-util';
import useCustomer from '@Components/customers/useCustomer';

import Row from '@Components/ui/styled/row';
import CustomerEmptyTab from '@Components/customers/customer-detail/customer-empty-tab';
import Loader from '@Components/ui/loader';
import { ColumnLeft, ColumnRight, ContentBlock, ScrollContent, ScrollWrapper, TitleBlock } from '@Components/customers/customer-styles';
import { txt } from '@Utils/i18n-util';
import CustomerVehicleList from './customer-vehicle-list';
import CustomerVehicleDetails from './customer-vehicle-details';
import msg from './customer-vehicles.msg';

const CustomerVehicles = ({ routeParams, tabId, onSelectVehicle }) => {
  const dispatch = useDispatch();
  const id = tabId || routeParams?.tabId;
  const { customerId, selectedCustomer } = useCustomer();
  const { customerType } = selectedCustomer || {};
  const associationType = customerType === CustomerType.Company ? 'VehicleOfCompany' : 'VehicleContact';

  const loading = useSelector((state) => state.customerAssociations.get('loading'));
  const associations = useSelector((state) => state.customerAssociations.get('associations'));
  const vehicles = associations.filter((a) => a.associationType === associationType);
  const phoneMode = useSelector(state => state.mainViewState.get('phoneMode'));

  const selectedVehicle = useMemo(() => {
    if (vehicles.length === 0) return null;
    if (id) {
      const itemById = vehicles.find((g) => g.otherCustomerId === +id);
      if (itemById) return (itemById);
    } else {
      const first = vehicles[0];
      if (first) return (first);
    }
  }, [vehicles, id]);

  useEffect(() => {
    dispatch(fetchCustomerAssociations(customerId));
  }, [customerId]);

  const handlerOnClick = (item) => {
    onSelectVehicle(item.otherCustomerId, 'vehicles');
  };

  if (vehicles && vehicles.length > 0 && routeParams && !routeParams?.tabId && selectedVehicle) {
    return <Redirect to={getCustomerVehiclesUrl(routeParams, selectedVehicle?.otherCustomerId)} />;
  }

  if (loading) {
    return <Loader topPosition />;
  }

  if (vehicles.length === 0) {
    return (
      <CustomerEmptyTab
        title={txt(msg.noVehiclesTitle)}
        text={txt(msg.noVehiclesText)}
      />
    );
  }

  const renderVehicleDetails = () => {
    return (
      <>
        <TitleBlock>{txt(msg.lblDetails)}</TitleBlock>
        <ContentBlock>
          <CustomerVehicleDetails {...selectedVehicle} />
        </ContentBlock>
      </>
    );
  };

  const renderVehicles = () => (
    <CustomerVehicleList
      isSelectable
      selectedEventId={selectedVehicle?.otherCustomerId}
      onSelectEvent={handlerOnClick}
      items={vehicles}
    />
  );

  if (phoneMode) {
    return (
      <Row scrollContainer>
        <ScrollWrapper>
          <ScrollContent>
            {renderVehicles()}
          </ScrollContent>
        </ScrollWrapper>
      </Row>
    );
  }

  return (
    <Row scrollContainer>
      <ColumnLeft>
        <ScrollWrapper>
          <ScrollContent>
            {renderVehicles()}
          </ScrollContent>
        </ScrollWrapper>
      </ColumnLeft>
      <ColumnRight>
        <ScrollWrapper>
          <ScrollContent>
            {renderVehicleDetails()}
          </ScrollContent>
        </ScrollWrapper>
      </ColumnRight>
    </Row>
  );
};

export default CustomerVehicles;
