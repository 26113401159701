import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import CurrencyUtil from '@Utils/currency-util';
import { fetchInvoices, fetchInvoicesLedger, setInvoiceStatus } from '@State/invoice-actions';
import FilterContainer from '@Components/ui/filter-container';
import { getSysAdmin } from '@State/selectors';
import { Panel, Row } from '@Components/ui/styled/main';
import { FlexOne, ReportTextLabel } from '@Components/pos/style';
import { reportingCount, reportingFormat } from '@Utils/format';
import { getInvoicesUrl, navigate } from '@Utils/navigate';
import NavTabsLink from '@Components/ui/nav-tabs-link';
import InvoiceStatusModal from '@Components/pos/dialogs/invoice-status-modal';
import DatePickerCalendarInput from '@Components/ui/date-picker/date-picker-calendar-input';
import DateRangePicker from '@Components/reports/date-range-picker';
import { getInvoiceStatusLabel } from '@Utils/invoice-util';
import Loader from '@Components/ui/loader';
import PosInvoiceUpdateStatus from './pos-invoice-update-status';

const tabSections = [
  { navId: 'active', status: ['Unpaid', 'DebtCollection', 'Unknown'], name: 'Pågående' },
  { navId: 'paid', status: ['Paid', 'Credited'], name: 'Betalda' },
  { navId: 'annulled', status: ['Annulled'], name: 'Makulerade' },
  { navId: 'ledger', status: [''], name: 'Reskontra' }
];

class Invoices extends Component {
  state = {
    showConfirmStatus: null,
    ledgerDate: moment(),
    startDate: moment().subtract(2, 'months').startOf('month'),
    endDate: moment(),
    loading: false
  };

  hideConfirmStatus = () => this.setState({ showConfirmStatus: null });

  showConfirmStatus = (ev, invoice) => {
    ev.preventDefault();
    this.setState({ showConfirmStatus: invoice });
  };

  handleInvoiceStatus = (values) => {
    this.hideConfirmStatus();
    return this.props.setInvoiceStatus(this.props.posOrgId, values);
  };

  changePosOrgFilter = (ev) => {
    const { routeParams } = this.props;
    navigate(getInvoicesUrl(routeParams, ev.target.value, routeParams.tab));
  };

  handleChangeDate = (date) => {
    this.setState({ ledgerDate: moment(date) }, this.fetchInvoices);
  };

  handleChangeDates = ({ startDate, endDate }) => {
    this.setState({ startDate, endDate }, this.fetchInvoices);
  };

  fetchInvoices = () => {
    const { posOrgId, match } = this.props;
    const { startDate, endDate, ledgerDate } = this.state;
    const tabSection = tabSections.find(t => t.navId === match.params.tab) || tabSections[0];

    this.setState({ loading: true });
    if (tabSection.navId === 'ledger') {
      return this.props.fetchInvoicesLedger(posOrgId, ledgerDate.format('YYYY-MM-DD'))
        .finally(() => this.setState({ loading: false }));
    }

    const isActive = tabSection.navId === 'active';
    const start = isActive ? undefined : startDate.format('YYYY-MM-DD');
    const end = isActive ? undefined : endDate.format('YYYY-MM-DD');
    return this.props.fetchInvoices(posOrgId, start, end, tabSection.status.join(','))
      .finally(() => this.setState({ loading: false }));
  }

  componentDidMount() {
    if (this.props.posOrgId) {
      this.fetchInvoices();
    }
  }

  componentDidUpdate(prevProps) {
    const { posOrgId, match } = this.props;
    const posOrgChanged = posOrgId && posOrgId !== prevProps.posOrgId;
    const tabChanged = match.params.tab && match.params.tab !== prevProps.match.params.tab;

    if (posOrgChanged || tabChanged) {
      this.fetchInvoices();
    }
  }

  render() {
    const { showConfirmStatus, ledgerDate, startDate, endDate, loading } = this.state;
    const { routeParams, posOrgs, posOrgId, invoices, match } = this.props;

    const tabSection = tabSections.find(t => t.navId === match.params.tab) || tabSections[0];
    const isActive = tabSection.navId === 'active';
    const isLedger = tabSection.navId === 'ledger';

    const currentInvoices = invoices?.filter(i => {
      if (isLedger) {
        const invoiceDate = moment(i.get('invoiceDate'));
        const paidDate = i.get('paidDate') && moment(i.get('paidDate'));

        return i.get('status') !== 'Annulled'
          && invoiceDate.isBefore(ledgerDate.endOf('d'))
          && (!paidDate || paidDate.isAfter(ledgerDate.endOf('d')));
      }
      return tabSection.status.includes(i.get('status'));
    });

    if (!posOrgId && !posOrgs.isEmpty()) {
      const posOrg = posOrgs.sortBy(o => o.get('companyName')).first();
      return (
        <Redirect to={getInvoicesUrl(routeParams, posOrg.get('id'))} />
      );
    }

    const currentCount = currentInvoices?.size || 0;
    const currentValue = currentInvoices?.reduce((a, b) => a + b.get('incVatAmount'), 0) || 0;

    return (
      <div className="columns-container">
        <div className="columns-content">
          <div className="columns-content-container width-large">
            {loading && <Loader />}
            <div className="columns-content-body with-padding">
              <NavTabsLink
                bottomMargin
                subUrl="admin/invoices"
                routeParams={routeParams}
                sections={tabSections}
              />
              <FilterContainer>
                {isLedger && (
                  <div className="form-group">
                    <label>Reskontra per datum</label>
                    <DatePickerCalendarInput
                      date={ledgerDate}
                      onChange={this.handleChangeDate}
                    />
                  </div>
                )}
                {!isActive && !isLedger && (
                  <div className="form-group">
                    <label>Fakturadatum</label>
                    <DateRangePicker
                      startDate={startDate}
                      endDate={endDate}
                      onDatesChange={this.handleChangeDates}
                    />
                  </div>
                )}
                {!posOrgs.isEmpty() && (
                  <div className="form-group">
                    <label>Företag</label>
                    <div className="select-container">
                      <select className="form-control" value={posOrgId} onChange={this.changePosOrgFilter}>
                        {posOrgs.sortBy(o => o.get('companyName')).map((item) => {
                          const { id, companyName } = item.toObject();
                          return <option key={id} value={id}>{companyName}</option>;
                        })}
                      </select>
                    </div>
                  </div>
                )}
              </FilterContainer>

              <Panel className="mt2">
                <Row>
                  <FlexOne>
                    <ReportTextLabel>
                      <span>{isLedger ? 'Obetalda' : tabSection.name} fakturor</span>
                      <p className="mb0">{reportingCount(currentCount)}</p>
                    </ReportTextLabel>
                  </FlexOne>
                  <FlexOne>
                    <ReportTextLabel>
                      <span>Totalt värde</span>
                      <p className="mb0">{reportingFormat(currentValue)}</p>
                    </ReportTextLabel>
                  </FlexOne>
                </Row>
              </Panel>
              <table className="table table-striped select-text">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Kund</th>
                    <th>Skickad till</th>
                    <th>Fakturadatum</th>
                    <th>Förfallodatum</th>
                    <th>Belopp</th>
                    <th>OCR</th>
                    <th>Status</th>
                    <th className="print-hidden" />
                  </tr>
                </thead>
                <tbody>
                  {currentInvoices?.map(invoice => {
                    const invoiceObject = invoice.toJS();
                    const {
                      id, invoiceNumber, ocrNumber, invoiceDate, invoiceDueDate, buyer, incVatAmount, providerId,
                      status, pdfUrl, emailSentTo, provider
                    } = invoiceObject;
                    const statusLabel = getInvoiceStatusLabel(invoiceObject);
                    const isDue = ['Unpaid', 'DebtCollection'].includes(status)
                      && moment().isAfter(invoiceDueDate);

                    return (
                      <tr key={id}>
                        <td className="nobr">
                          {provider === 'Fortnox' && (
                            <img src="/fortnox-icon.png" className="invoice-provider" />
                          )}
                          {providerId || invoiceNumber}
                        </td>
                        <td className="ellipsis">{buyer.customerName}</td>
                        <td className="ellipsis">{emailSentTo || '-'}</td>
                        <td className="nobr">{moment(invoiceDate).format('L')}</td>
                        <td className={isDue ? 'text-danger nobr' : 'nobr'}>
                          {moment(invoiceDueDate).format('L')}
                          {isDue && <i className="fa fa-circle-exclamation ml1" />}
                        </td>
                        <td className="nobr">{CurrencyUtil.accountCurrency(incVatAmount, 0)}</td>
                        <td className="nobr">{ocrNumber}</td>
                        <td className="nobr">
                          {provider === 'Fortnox' ? (
                            <>
                              <span>{statusLabel} </span>
                              <PosInvoiceUpdateStatus
                                posOrgId={posOrgId}
                                invoiceId={id}
                                className="ml1"
                              />
                            </>
                          ) : (
                            <a href="#" onClick={(ev) => this.showConfirmStatus(ev, invoice)}>
                              {statusLabel}
                            </a>
                          )}
                        </td>
                        <td className="text-right nobr print-hidden">
                          {pdfUrl && (
                            <a href={pdfUrl} target="_blank"><i className="fa fa-file-alt" /> Visa</a>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {showConfirmStatus && (
                <InvoiceStatusModal
                  invoice={showConfirmStatus}
                  onSubmit={this.handleInvoiceStatus}
                  onClose={this.hideConfirmStatus}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { posOrgs, invoices } = state;
  const posOrgId = props.match.params.id;
  const routeParams = {
    ...props.match.params,
    entityId: posOrgId
  };

  return {
    posOrgs,
    posOrgId,
    routeParams,
    invoices,
    isSysAdmin: getSysAdmin(state)
  };
};

const mapDispatchToProps = dispatch => ({
  fetchInvoices: (posOrgId, start, end, status) => dispatch(fetchInvoices(posOrgId, start, end, status)),
  fetchInvoicesLedger: (posOrgId, ledgerDate) => dispatch(fetchInvoicesLedger(posOrgId, ledgerDate)),
  setInvoiceStatus: (posOrgId, invoiceId, status) => dispatch(setInvoiceStatus(posOrgId, invoiceId, status))
});

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);
