import React from 'react';
import { useSelector } from 'react-redux';
import { getSmsEnabled } from '@State/selectors';
import { sms, email as emailKeys } from '@Utils/preference-keys';
import PhoneUtil from '@Utils/phone-util';
import { txt } from '@Utils/i18n-util';
import msg from './send-confirmation.msg';

const SendConfirmation = ({
  onCancel, onConfirm, progress, customer, customerIds
}) => {
  const smsEnabled = useSelector(getSmsEnabled);
  const locationConfig = useSelector(state => state.locationConfig);

  const { phoneNumber, email } = customer || {};
  const isMultipleCustomers = customerIds?.length > 0;
  const canSendConfirmation = (phoneNumber && smsEnabled) || email || customerIds?.length > 0;
  const defaultOptionSmsConfirm = locationConfig.get(sms.defaultOptionSmsConfirm);
  const defaultOptionEmailConfirm = locationConfig.get(emailKeys.defaultOptionEmailConfirm);

  return (
    <form autoComplete="off" onSubmit={onConfirm} className={progress ? 'disabled' : ''}>
      {canSendConfirmation ? (
        <div>
          <p>
            <strong>{txt(msg.lblSendConfirmationTo)}</strong>
          </p>
          {(isMultipleCustomers || phoneNumber) && smsEnabled && (
            <div className="checkbox">
              <label>
                <input type="checkbox" name="sms" value="true" defaultChecked={defaultOptionSmsConfirm} /> {PhoneUtil.formatPhoneNumber(phoneNumber) || 'SMS'}
              </label>
            </div>
          )}
          {(isMultipleCustomers || email) && (
            <div className="checkbox">
              <label>
                <input type="checkbox" name="email" value="true" defaultChecked={defaultOptionEmailConfirm} /> {email || 'E-mail'}
                <br />
              </label>
            </div>
          )}
        </div>
      ) : (
        <p>
          {txt(msg.lblUnableToSend)}
        </p>
      )}
      <div className="text-right">
        <button type="button" className="btn btn-default" tabIndex="4" onClick={onCancel}>{txt(msg.btnCancel)}</button> &nbsp;
        <button type="submit" className="btn-save" tabIndex="3">{progress ? txt(msg.btnSendProgress) : txt(msg.btnSend)}</button>
      </div>
    </form>
  );
};

export default SendConfirmation;
