import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCustomer } from '@State/customer-actions';
import { getCurrentLocation } from '@State/selectors';
import { companyDefaultValues, CustomerType, personDefaultValues, vehicleDefaultValues } from '@Utils/customer-util';
import CustomerInformationForm from '@Components/customers/customer-detail/overview/customer-information-form';
import ModalDialog from '@Components/dialogs/modal-dialog';
import { formatPhoneNumberE164 } from '@Utils/phone-util';
import { txt } from '@Utils/i18n-util';
import msg from './customer-header.msg';
import VehicleInformationForm from './customer-detail/overview/vehicle-information-form';
import CompanyInformationForm from './customer-detail/overview/company-information-form';

const AddCustomerModal = ({ customerType = CustomerType.Person, routeParams, onClose, onCreated }) => {
  const dispatch = useDispatch();
  const currentLocation = useSelector(state => getCurrentLocation(state, { routeParams }));

  const onCreate = async (values) => {
    const customer = {
      ...values,
      customerType,
      locationId: currentLocation?.locationId,
      phoneNumber: values?.phoneNumber ? formatPhoneNumberE164(values?.phoneNumber) : null,
      otherPhoneNumber: values?.otherPhoneNumber ? formatPhoneNumberE164(values?.otherPhoneNumber) : null
    };
    const customerId = await dispatch(createCustomer(customer));
    onCreated({ customerId, ...customer });
  };

  return (
    <ModalDialog
      contentSize="medium"
      title={txt(msg.addDialogTitle)}
      onClose={onClose}
    >
      {customerType === CustomerType.Vehicle && (
        <VehicleInformationForm
          onClose={onClose}
          onSubmit={onCreate}
          initialValues={vehicleDefaultValues}
        />
      )}
      {customerType === CustomerType.Company && (
        <CompanyInformationForm
          onClose={onClose}
          onSubmit={onCreate}
          initialValues={companyDefaultValues}
        />
      )}
      {customerType === CustomerType.Person && (
        <CustomerInformationForm
          onClose={onClose}
          onSubmit={onCreate}
          initialValues={personDefaultValues}
        />
      )}
    </ModalDialog>
  );
};

export default AddCustomerModal;
