import { defineMessages } from 'react-intl';

export default defineMessages({
  SickLeave: {
    id: 'bookingUtil.reservationType.SickLeave',
    defaultMessage: 'Sick leave'
  },
  SickChildLeave: {
    id: 'bookingUtil.reservationType.SickChildLeave',
    defaultMessage: 'Sick child'
  },
  Vacation: {
    id: 'bookingUtil.reservationType.Vacation',
    defaultMessage: 'Vacation'
  },
  OnLeave: {
    id: 'bookingUtil.reservationType.OnLeave',
    defaultMessage: 'Leave of absence'
  },
  Furlough: {
    id: 'bookingUtil.reservationType.Furlough',
    defaultMessage: 'Furlough'
  },
  WorkingHoursReduction: {
    id: 'bookingUtil.reservationType.WorkingHoursReduction',
    defaultMessage: 'Reduced hours'
  },
  Absence: {
    id: 'bookingUtil.reservationType.Absence',
    defaultMessage: 'Absence'
  },
  Lunch: {
    id: 'bookingUtil.reservationType.Lunch',
    defaultMessage: 'Lunch'
  },
  Normal: {
    id: 'bookingUtil.exceptionType.Normal',
    defaultMessage: 'Normal'
  },
  Overtime: {
    id: 'bookingUtil.exceptionType.Overtime',
    defaultMessage: 'Overtime'
  },
  bookingClosed: {
    id: 'bookingUtil.classStatus.bookingClosed',
    defaultMessage: 'Booking closed'
  },
  classFull: {
    id: 'bookingUtil.classStatus.classFull',
    defaultMessage: 'Fully booked'
  },
  classAlmostFull: {
    id: 'bookingUtil.classStatus.classAlmostFull',
    defaultMessage: 'Few places left'
  },
  bookingOpen: {
    id: 'bookingUtil.classStatus.bookingOpen',
    defaultMessage: 'Open for booking'
  }
});
