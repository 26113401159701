import React from 'react';
import { useSelector } from 'react-redux';
import { getResourceEntityType, navigateAsync, navigateSearchAsync } from '@Utils/navigate';
import { getResourceIcon } from '@Utils/resource-util';
import { getSearchFromResourceIds } from '@Utils/url-util';
import { colors } from '@Components/ui/styled/variables';

const Navigate = (href, cb, scrollToFirstElement, resourceId) => ev => {
  ev.preventDefault();
  if (cb) cb();
  if (scrollToFirstElement) scrollToFirstElement(resourceId);
  navigateAsync(href);
};

const ResourceEntry = ({
  resource, group, routeParams, resourceTargetUrlResolver, resourcesInView,
  onOptionClick, scrollToFirstElement
}) => {
  const { id, name, resourceType } = resource;
  const { entityType, entityId } = routeParams;
  const multiResourceMode = useSelector(state => state.calendarViewState.get('multiResourceMode'));

  const resourceInView = resourcesInView.find(r => r.id === id);
  const resourceEntityType = getResourceEntityType(group.id);
  const active = multiResourceMode ? resourceInView : (entityType === resourceEntityType && id === entityId);
  const cls = active ? 'list-group-item resource-item active' : 'list-group-item resource-item';
  const linkTarget = resourceTargetUrlResolver(id, resourceEntityType);
  const color = null; // resourceInView?.prefs?.color;

  const handleMultiResourceClick = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    const resourceIdsInView = resourcesInView.map(r => r.id);
    if (active) {
      const newResourceIds = resourceIdsInView.filter(ri => ri !== id);
      navigateSearchAsync(getSearchFromResourceIds(newResourceIds));
    } else {
      const newResourceIds = [...resourceIdsInView, id];
      navigateSearchAsync(getSearchFromResourceIds(newResourceIds));
    }
  };

  return (
    <li>
      <a
        className={cls}
        href="#"
        onClick={multiResourceMode
          ? handleMultiResourceClick
          : Navigate(linkTarget, onOptionClick, scrollToFirstElement, id)}
      >
        {multiResourceMode ? (
          <div
            className="resource-checkbox"
            style={active ? { background: color || colors.primaryDark } : null}
          >
            {active && <i className="fa-solid fa-check" />}
          </div>
        ) : (
          <i className={getResourceIcon(resourceType)} />
        )}
        <span className="name">{name}</span>
      </a>
    </li>
  );
};
export default ResourceEntry;
