import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'customers.header.title',
    defaultMessage: 'Customers'
  },
  subtitle: {
    id: 'customers.header.subtitle',
    defaultMessage: '{count} total'
  },
  addBtnLabel: {
    id: 'customers.header.addBtnLabel',
    defaultMessage: 'Add customer'
  },
  downloadBtnLabel: {
    id: 'customers.header.downloadBtnLabel',
    defaultMessage: 'Download'
  },
  addDialogTitle: {
    id: 'customers.header.addDialogTitle',
    defaultMessage: 'Add new customer'
  }
});
