import { defineMessages } from 'react-intl';

export default defineMessages({
  classBooking: {
    id: 'calendar.bkf.class.classBooking',
    defaultMessage: 'Class booking'
  },
  maxSlots: {
    id: 'calendar.bkf.class.maxSlots',
    defaultMessage: 'Number of slots'
  },
  bookedSlots: {
    id: 'calendar.bkf.class.bookedSlots',
    defaultMessage: 'booked slots'
  },
  closeBtnLabel: {
    id: 'calendar.bkf.class.closeBtnLabel',
    defaultMessage: 'Close'
  },
  editBtnLabel: {
    id: 'calendar.bkf.class.editBtnLabel',
    defaultMessage: 'Change'
  },
  openBtnLabel: {
    id: 'calendar.bkf.class.openBtnLabel',
    defaultMessage: 'Show participants'
  }
});
