import React from 'react';
import TimeField from 'react-simple-timefield';

const style = { width: 60 };
const TimePickerField = ({ input: { value, onChange } }) => {
  return (
    <TimeField
      value={value}
      onChange={onChange}
      style={style}
      input={<input className="form-control" />}
    />
  );
};

export default TimePickerField;
