import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import CurrencyUtil from '@Utils/currency-util';
import {
  createValidator, required, number, minValue
} from '@Utils/validation';
import { serviceMappingValues } from '@State/services-actions';
import AlertWithIcon from '@Components/ui/alert-with-icon';
import TextInput from '../inputs/text-input';
import CheckBox from '../inputs/checkbox';

class CustomServiceSettingsForm extends Component {
  render() {
    const {
      handleSubmit, submitting, onClose, customValues, serviceFormValues,
      isAddonService, showInBooking, useDynamicPricing
    } = this.props;
    const { multiVat } = serviceFormValues || {};

    return (
      <form autoComplete="off" onSubmit={handleSubmit}>
        <hr />
        <div className="form-group">
          <div className="checkbox">
            <label>
              <Field component="input" type="checkbox" name="customValues" /> Använd egna inställningar
            </label>
          </div>
          <p className="text-muted">
            Med egna inställningar kan du ange egna värden för den här tjänsten. T.ex. pris, längd och inställningar för onlinebokning.
          </p>
        </div>
        <hr />

        {customValues && (
        <div>
          <Field name="serviceDuration" component={TextInput} label="Längd" width={120} unitLabel="min" helpText="Ange hur lång tid som ska reserveras vid bokning av den här tjänsten." />
          <Field name="afterTime" component={TextInput} label="Paus efter" width={120} unitLabel="min" helpText="Reservera extra tid efter bokningen, tex för städning" />
          {!multiVat && (
            <table className="table-form">
              <tbody>
                <tr>
                  <td className="width-md">
                    <Field
                      name="price"
                      component={TextInput}
                      label="Pris"
                      unitLabel={CurrencyUtil.currencySymbol()}
                      validate={[number, minValue(0)]}
                    />
                  </td>
                  <td className="width-md">
                    <Field
                      name="priceEvening"
                      component={TextInput}
                      label="Pris kväll"
                      disabled={!useDynamicPricing}
                      unitLabel={CurrencyUtil.currencySymbol()}
                      validate={[number, minValue(0)]}
                    />
                  </td>
                  <td className="width-md">
                    <Field
                      name="priceHoliday"
                      component={TextInput}
                      label="Pris helg"
                      disabled={!useDynamicPricing}
                      unitLabel={CurrencyUtil.currencySymbol()}
                      validate={[number, minValue(0)]}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          )}
          {!multiVat && <Field name="useDynamicPricing" component={CheckBox} label="Använd helg-/kvällspris" />}
          {!multiVat && <Field name="priceFrom" component={CheckBox} label="Visa som från-pris" />}

          <hr />

          <div className="form-group">
            <label className="control-label">Inställningar för onlinebokning</label>
            {showInBooking ? (
              <>
                {!isAddonService && <Field name="webAllowBooking" component={CheckBox} label="Tillåt bokning" disabled={!showInBooking} />}
                <Field name="webShowDuration" component={CheckBox} label="Visa längd" disabled={!showInBooking} />
                <Field name="webShowPrice" component={CheckBox} label="Visa pris" disabled={!showInBooking} />
              </>
            ) : (
              <AlertWithIcon icon="far fa-exclamation-circle">
                Tjänsten visas inte på webben
              </AlertWithIcon>
            )}
          </div>
          <hr />
        </div>
        )}

        <div className="row">
          <div className="col-sm-12">
            <div className="pull-right">
              <button type="button" className="btn-cancel" tabIndex={3} onClick={onClose}>Avbryt</button> &nbsp;
              <button type="submit" className="btn-save" tabIndex={2} disabled={submitting}>
                {submitting ? 'Sparar...' : 'Spara'}
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

const validator = createValidator({
  name: [required],
  serviceDuration: [required, number, minValue(5)],
  afterTime: [number, minValue(0)],
  price: [number, minValue(0)]
});

const warnings = (values, { serviceFormValues }) => {
  if (!serviceFormValues) {
    return null;
  }

  const warnings = {};
  const message = 'Tjänsten har ett annat värde';
  for (let i = 0; i < serviceMappingValues.length; i++) {
    const prop = serviceMappingValues[i];
    if (String(values[prop]) !== String(serviceFormValues[prop])) {
      warnings[prop] = message;
    }
  }
  return warnings;
};

const selector = formValueSelector('custom-service-settings-form');

const mapStateToProps = (state) => {
  return {
    customValues: selector(state, 'customValues'),
    useDynamicPricing: selector(state, 'useDynamicPricing')
  };
};

export default reduxForm({
  form: 'custom-service-settings-form',
  validate: validator,
  warn: warnings,
  shouldWarn: () => true
})(connect(mapStateToProps)(CustomServiceSettingsForm));
