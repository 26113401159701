import { defineMessages } from 'react-intl';

export default defineMessages({
  booking: {
    id: 'reports.nav.booking',
    defaultMessage: 'Booking'
  },
  bookingReport: {
    id: 'reports.nav.bookingReport',
    defaultMessage: 'Booking report'
  },
  bookingList: {
    id: 'reports.nav.bookingList',
    defaultMessage: 'Booking list'
  },
  sms: {
    id: 'reports.nav.sms',
    defaultMessage: 'SMS report'
  },
  pos: {
    id: 'reports.nav.pos',
    defaultMessage: 'POS'
  },
  sales: {
    id: 'reports.nav.sales',
    defaultMessage: 'Sales'
  },
  commission: {
    id: 'reports.nav.commission',
    defaultMessage: 'Commission'
  },
  prePayments: {
    id: 'reports.nav.prePayments',
    defaultMessage: 'Pre-paid bookings'
  },
  deferredSales: {
    id: 'reports.nav.deferredSales',
    defaultMessage: 'Deferred sales'
  },
  stock: {
    id: 'reports.nav.stock',
    defaultMessage: 'Stock'
  },
  giftCards: {
    id: 'reports.nav.giftCards',
    defaultMessage: 'Gift cards'
  },
  ledger: {
    id: 'reports.nav.ledger',
    defaultMessage: 'Ledger'
  },
  redeemed: {
    id: 'reports.nav.redeemed',
    defaultMessage: 'Redeemed'
  },
  staff: {
    id: 'reports.nav.staff',
    defaultMessage: 'Staff'
  },
  salary: {
    id: 'reports.nav.salary',
    defaultMessage: 'Salary basis'
  },
  export: {
    id: 'reports.nav.export',
    defaultMessage: 'Data export'
  },
  exportBookings: {
    id: 'reports.nav.exportBookings',
    defaultMessage: 'Export bookings'
  },
  exportCustomers: {
    id: 'reports.nav.exportCustomers',
    defaultMessage: 'Export customers'
  }
});
