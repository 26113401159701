import React from 'react';
import CustomerEmployee from '@Components/customers/customer-detail/employees/customer-employee';
import { TitleDate } from '@Components/customers/customer-styles';

const CustomerEmployeeList = ({
  items = [], onSelectEvent, selectedEventId, isSelectable = false
}) => {
  return (
    <div>
      <TitleDate>Anställda</TitleDate>
      {items.map((employee) => (
        <div key={employee.otherCustomerId}>
          <CustomerEmployee
            isSelectable={isSelectable}
            selectedEventId={selectedEventId}
            onSelectEvent={onSelectEvent}
            {...employee}
          />
        </div>
      ))}
    </div>
  );
};

export default CustomerEmployeeList;
