import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getAdminUrl, navigate } from '@Utils/navigate';
import { fetchPartners, replacePartnerCustomTexts, replacePartnerPreferences } from '@State/admin-config-actions';

import PartnerConfigList from '@Components/admin/partner-config/partner-config-list';
import PartnerConfigTabs from '@Components/admin/partner-config/partner-config-tabs';
import JSONEditorForm from '@Components/ui/jsoneditor/jsoneditor-form';
import Loader from '@Components/ui/loader';

const PartnerConfig = (props) => {
  const dispatch = useDispatch();
  const routeParams = props?.match?.params;
  const currentId = routeParams?.id;
  const currentTab = routeParams?.tab;

  const fetchData = async () => {
    await dispatch(fetchPartners());
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [loading, setLoading] = useState(true);
  const partnersById = useSelector(state => state.partnersById);
  const partner = partnersById.get(parseInt(currentId));

  const onItemClick = useCallback(
    id => navigate(getAdminUrl(routeParams, 'partners', id, currentTab)),
    [routeParams]
  );

  const onUpdatePrefs = useCallback((data) => {
    return dispatch(replacePartnerPreferences(currentId, data));
  }, [routeParams]);

  const onUpdateCustomTexts = useCallback((data) => {
    return dispatch(replacePartnerCustomTexts(currentId, data));
  }, [routeParams]);

  if (loading && partnersById.isEmpty()) {
    return <Loader />;
  }

  if (!partnersById.isEmpty() && !currentId) {
    const id = partnersById.first().get('id');
    return (
      <Redirect to={getAdminUrl(routeParams, 'partners', id, 'prefs')} />
    );
  }

  return (
    <div className="columns-container">
      <div className="columns-sidebar">
        <div className="columns-header">
          <h1>Partners</h1>
        </div>
        <div className="columns-list">
          <PartnerConfigList
            onItemClick={onItemClick}
            currentId={parseInt(currentId)}
            partnersById={partnersById}
          />
        </div>
      </div>
      <div className="columns-content">
        <div className="columns-content-container">
          <div className="columns-content-body">
            <div className="columns-content-header">
              <h3>{partner?.get('name')}</h3>
            </div>
            <PartnerConfigTabs routeParams={routeParams} />
            {currentTab === 'prefs' && (
              <JSONEditorForm
                id={currentId}
                title="Partner Prefs"
                json={partner?.get('prefs')}
                onSubmit={onUpdatePrefs}
              />
            )}
            {currentTab === 'custom-texts' && (
              <JSONEditorForm
                id={currentId}
                title="Custom Texts"
                json={partner?.get('customTexts')}
                onSubmit={onUpdateCustomTexts}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerConfig;
