import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';

import {
  navigate, getCalendarUrl, getGroupUrl, getResourceUrl
} from '@Utils/navigate';
import ResourceSelector from '@Components/calendar/resource-selector/resource-selector';
import DatePickerCalendar from '@Components/ui/date-picker/date-picker-calendar';
import shallowEqual from 'fbjs/lib/shallowEqual';
import ResourceSelectorToolbar from '@Components/calendar/resource-selector/resource-selector-toolbar';
import MultiResourceToggle from './resource-selector/multi-resource-toggle';

export default class CalendarSideBar extends Component {
  static propTypes = {
    routeParams: PropTypes.object.isRequired,
    permissions: PropTypes.array.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      filter: '',
      showDatePicker: true,
      selectedDate: CalendarSideBar.getDate(props.routeParams.viewMode, props.routeParams.viewDate)
    };
  }

  UNSAFE_componentWillReceiveProps({ routeParams, match }) {
    if (!shallowEqual(this.props.routeParams, routeParams)) {
      this.handleChangeDate(CalendarSideBar.getDate(match.params.viewMode, match.params.viewDate), routeParams, true);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.showDatePicker !== prevState.showDatePicker) {
      this.props.updateViewDimensions();
    }
  }

  static getDate(viewMode, viewDate) {
    if (viewDate === 'today') return moment().format('YYYY-MM-DD');
    return viewMode === 'week' ? moment(viewDate, 'gggg-ww') : moment(viewDate, 'YYYY-MM-DD');
  }

  filterChange = ev => {
    this.setState({ filter: ev ? ev.target.value : '' });
  };

  handleChangeDate = (newSelectedDate, routeParams = this.props.routeParams, noNavigate) => {
    const { viewDate, viewMode } = routeParams || {};
    const date = newSelectedDate || viewDate;
    let newDate;
    switch (viewMode) {
      case 'day':
        newDate = moment(date).format('YYYY-MM-DD');
        break;
      case 'week':
        newDate = moment(date).format('gggg-ww');
        break;
      default:
        console.error(`Unknown view mode: ${viewMode}`);
    }
    this.setState({ selectedDate: moment(date) });

    if (noNavigate) return;
    navigate(getCalendarUrl(newDate, routeParams, true));
  }

  getHighlightDays = (date, viewMode) => {
    if (viewMode === 'day') {
      return [date.toDate()];
    }

    const startOfWeek = moment(date).startOf('week');
    const endOfWeek = moment(date).endOf('week');
    const days = [];
    let day = startOfWeek;

    while (day <= endOfWeek) {
      days.push(day.toDate());
      day = day.clone().add(1, 'd');
    }
    return days;
  };

  renderResourceSelector() {
    const {
      routeParams, dpPosition, orderedGroups, toggleResourceListCollapsed,
      resourceListViewMode, setResourceListViewMode
    } = this.props;

    return (
      <>
        <ResourceSelectorToolbar
          dpPosition={dpPosition}
          filter={this.state.filter}
          onFilterChange={this.filterChange}
          groups={orderedGroups}
          resourceListViewMode={resourceListViewMode}
          onToggleCollapse={toggleResourceListCollapsed}
          onSetViewMode={setResourceListViewMode}
        />
        <MultiResourceToggle routeParams={routeParams} />
        <ResourceSelector
          routeParams={routeParams}
          resourceTargetUrlResolver={this.resolveResourceTargetUrl}
          groupTargetUrlResolver={this.resolveGroupTargetUrl}
          onToggleCollapse={toggleResourceListCollapsed}
          resourceListViewMode={resourceListViewMode}
          filter={this.state.filter}
          excludeEmptyGroups
          collapsibleGroups
        />
      </>
    );
  }

  renderDatePicker() {
    const { viewMode } = this.props.routeParams;
    const { showDatePicker, selectedDate } = this.state;
    const style = { display: showDatePicker ? 'block' : 'none' };

    return (
      <DatePickerCalendar
        openToDate={selectedDate.toDate()}
        onChangeDate={this.handleChangeDate}
        selected={moment().toDate()}
        style={style}
        highlightDates={this.getHighlightDays(selectedDate, viewMode)}
      />
    );
  }

  resolveResourceTargetUrl = (resourceId, resourceEntityType) => {
    return getResourceUrl(resourceId, resourceEntityType, 'week', this.props.routeParams);
  };

  resolveGroupTargetUrl = (groupId) => {
    return getGroupUrl(groupId, this.props.routeParams);
  };

  render() {
    const { dpPosition } = this.props;
    return (
      <div className="calendar-sidebar">
        {dpPosition === 'top' ? (
          <>
            {this.renderDatePicker()}
            {this.renderResourceSelector()}
          </>
        ) : (
          <>
            {this.renderResourceSelector()}
            {this.renderDatePicker()}
          </>
        )}
      </div>
    );
  }
}
