import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { txt } from '@Utils/i18n-util';
import { setCustomerFormState } from '@State/cf-actions';
import { isBookingCancelled } from '@Utils/booking-util';
import { styleHiddenInput } from './style';
import msg from './booking-company.msg';

class BookingCompany extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editCompany: props.company && !props.company.customerId
    };
  }

  componentDidMount() {
    if (this.state.editCompany) {
      this.focusEditField();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const validated = this.props.validationErrors !== nextProps.validationErrors;
    if (validated && nextProps.validationErrors && !this.state.editCompany) {
      this.setState({ editCompany: true });
    }
    if (this.props.company !== nextProps.company && nextProps.company.customerId) {
      this.setState({ editCompany: false });
    }
  }

  handleClick = (ev) => {
    ev.preventDefault();

    const hidden = ReactDOM.findDOMNode(this.hidden);
    hidden.style.visibility = 'visible';
    hidden.focus();
    hidden.style.visibility = 'hidden';

    this.setState({ editCompany: true }, () => this.focusEditField());
  };

  focusEditField = () => {
    if (this.orgName) {
      const input = ReactDOM.findDOMNode(this.orgName);
      input && input.focus();
    }
  };

  handleSearch = (ev) => {
    ev.preventDefault();
    this.props.onSearchStart();
  };

  handleClose = (ev) => {
    ev.preventDefault();
    this.setState({ editCompany: false });
  };

  handleClear = (ev) => {
    ev.preventDefault();
    this.props.onClearCompany();
  };

  handleOpen = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    this.props.onOpenCustomer(this.props.company);
  };

  renderHiddenInput() {
    return <input ref={(ref) => { this.hidden = ref; }} style={styleHiddenInput} />;
  }

  render() {
    const { editCompany } = this.state;
    const { company, customerId, officialIdNo, name, validationErrors } = this.props;
    const savedWithOrgNo = company && company.customerId && company.officialIdNo && company.officialIdNo.length > 0;
    const isOrgNoInvalid = validationErrors && name && !(officialIdNo?.length > 0);
    const onClickMethod = customerId ? this.handleOpen : this.handleClick;
    const isCancelled = isBookingCancelled(this.props);
    const orgNoClass = isOrgNoInvalid
      ? 'form-group col-xs-12 has-error'
      : 'form-group col-xs-12';

    if (isCancelled && !company) {
      return null;
    }

    if (editCompany) {
      return (
        <div className="booking-form-block form">
          <div className="row tight">
            <div className="form-group col-xs-12">
              <label className="control-label">{txt(msg.lblOrgName)}</label>
              <Field autoComplete="off" name="name" component="input" type="text" className="form-control" ref={(ref) => { this.orgName = ref; }} />
            </div>
          </div>
          <div className="row tight">
            <div className={orgNoClass}>
              <label className="control-label">
                {txt(msg.lblOrgNo)}
                {isOrgNoInvalid && <span> ({txt(msg.lblOrgNoInvalid)})</span>}
              </label>
              <Field autoComplete="off" name="officialIdNo" component="input" type="text" className="form-control" disabled={savedWithOrgNo} />
            </div>
          </div>
          <div className="text-right">
            <button className="btn-label" tabIndex={-1} onClick={this.handleClose}>{txt(msg.btnClose)}</button>
          </div>
        </div>
      );
    }

    if (!company) {
      return (
        <button className="booking-form-block button" onClick={this.handleSearch}>
          <i className="fa fa-building" /> {txt(msg.btnAddOrg)}
          {this.renderHiddenInput()}
        </button>
      );
    }

    return (
      <div className={isCancelled ? 'booking-form-block cancelled' : 'booking-form-block'} onClick={!isCancelled ? onClickMethod : null}>
        <div className="block-buttons" onClick={ev => ev.stopPropagation()}>
          {customerId && !company.deleted && <div><button className="btn-label" tabIndex={-1} onClick={this.handleOpen}>{txt(msg.btnOpen)}</button></div>}
          {!isCancelled && <button className="btn-label" tabIndex={-1} onClick={this.handleClear}>{txt(msg.btnClear)}</button>}
        </div>
        <h4>{name}</h4>
        {officialIdNo}
        {this.renderHiddenInput()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { bkf } = state;
  const company = bkf.get('company');

  return {
    company,
    initialValues: company,
    status: bkf.get('status'),
    ...getFormValues('bkf-company')(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onOpenCustomer: (customer) => {
      dispatch(setCustomerFormState({
        formVisible: true,
        customer
      }));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'bkf-company',
  destroyOnUnmount: false
})(BookingCompany));
