import { defineMessages } from 'react-intl';

export default defineMessages({
  noVehiclesTitle: {
    id: 'customers.vehicles.noVehiclesTitle',
    defaultMessage: 'No vehicles'
  },
  noVehiclesText: {
    id: 'customers.vehicles.noVehiclesText',
    defaultMessage: 'There are no vehicles for this customer'
  },
  lblDetails: {
    id: 'customers.vehicles.lblDetails',
    defaultMessage: 'Details'
  }
});
