import { defineMessages } from 'react-intl';

export default defineMessages({
  lblToggle: {
    id: 'calendar.resource.multi.lblToggle',
    defaultMessage: 'Select multiple'
  },
  dialogTitle: {
    id: 'calendar.resource.multi.dialogTitle',
    defaultMessage: 'Multi resource view'
  },
  paragraph1: {
    id: 'calendar.resource.multi.paragraph1',
    defaultMessage: 'Select multiple resources by pressing each resource, or press the group name to select all the resources in that group.'
  },
  paragraph2: {
    id: 'calendar.resource.multi.paragraph2',
    defaultMessage: 'In day view each resource is shown in a separate column, and in week view all of the bookings are shown in the same calendar.'
  },
  paragraph3: {
    id: 'calendar.resource.multi.paragraph3',
    defaultMessage: 'Hover the mouse over a booking to show which resource(s) it belongs to.'
  }
});
