import { Map, fromJS } from 'immutable';
import moment from 'moment';
import {
  CUSTOMER_NOTE_UPDATED,
  CUSTOMER_COMMENT_DELETED,
  CUSTOMER_COMMENT_SAVED,
  CUSTOMER_SEARCH_RESULT,
  CREATE_CUSTOMER,
  CUSTOMERS_FETCHED,
  CUSTOMER_LOG_FETCHED,
  CUSTOMER_UPDATED,
  CUSTOMER_DELETED,
  CUSTOMER_COMMENT_UPDATED,
  CUSTOMER_FETCHED,
  CUSTOMER_CLEAR_FORM,
  CUSTOMER_SALES_FETCHED,
  CUSTOMER_GIFT_CARDS_FETCHED,
  BOOKING_HISTORY_LOADED,
  BOOKING_HISTORY_UPDATED,
  CUSTOMER_INVOICE_CUSTOMER_UPDATED,
  CUSTOMER_ASSOCIATIONS_FETCHED
} from '@State/customer-actions';
import { CLEAR_LOCATION_STATE } from '@State/account-actions';

export function customerById(state = Map({}), action = null) {
  switch (action.type) {
    case CUSTOMER_FETCHED: {
      return fromJS({ ...action.customer, customerId: action.customer.id });
    }

    case CREATE_CUSTOMER:
    case CUSTOMER_CLEAR_FORM:
    case CUSTOMER_DELETED: {
      return state.clear();
    }

    case CUSTOMER_NOTE_UPDATED: {
      return state.set('notes', action.notes);
    }

    case CUSTOMER_INVOICE_CUSTOMER_UPDATED: {
      return state.set('invoiceCustomerId', action.invoiceCustomerId || null);
    }

    case CUSTOMER_UPDATED: {
      if (state.get('id') !== action.id) {
        return state;
      }
      const newCustomer = {
        ...state.toJS(),
        id: action.id,
        ...action.customer
      };
      return fromJS(newCustomer);
    }

    default:
      return state;
  }
}

export function customers(state = Map({}), action = null) {
  switch (action.type) {
    case CLEAR_LOCATION_STATE:
      return state.clear();

    case CUSTOMERS_FETCHED:
    case CUSTOMER_SEARCH_RESULT: {
      return fromJS(action.result);
    }

    case CREATE_CUSTOMER: {
      if (!state.get('customerEntries')) {
        return state;
      }

      const newCustomer = { ...action.customer, customerId: action.customer.id };
      return state.set('customerEntries', state.get('customerEntries').insert(0, fromJS(newCustomer)))
        .set('totalCount', state.get('totalCount') + 1);
    }

    case CUSTOMER_UPDATED: {
      const customerEntries = state.get('customerEntries');
      if (!customerEntries) {
        return state;
      }

      // merge customer to store
      const index = customerEntries.findIndex((c) => c.get('customerId') === action?.id);

      if (index === -1) {
        return state;
      }
      const newCustomer = {
        id: action.id,
        ...customerEntries.get(index).toJS(),
        ...action.customer
      };
      return state.setIn(['customerEntries', index], fromJS(newCustomer));
    }

    case CUSTOMER_DELETED: {
      if (!state.get('customerEntries')) {
        return state;
      }

      const index = state.get('customerEntries').findIndex((c) => c.get('customerId') === action.id);

      if (index === -1) {
        return state;
      }

      return state.deleteIn(['customerEntries', index]).setIn(['totalCount'], state.get('totalCount') - 1);
    }

    default:
      return state;
  }
}

export function customerLogEntries(state = Map({ logs: fromJS([]), loading: true }), action = null) {
  switch (action.type) {
    case CREATE_CUSTOMER:
    case CLEAR_LOCATION_STATE:
    case CUSTOMER_CLEAR_FORM:
      return state.set('logs', fromJS([])).set('loading', true);

    case CUSTOMER_LOG_FETCHED: {
      return state.set('logs', fromJS(action.logentries)).set('loading', false);
    }

    case CUSTOMER_COMMENT_SAVED: {
      return state.setIn(['logs'], state.get('logs').insert(0, fromJS(action.result)));
    }

    case CUSTOMER_COMMENT_UPDATED: {
      const comments = state.get('logs').toJS();
      const index = comments.findIndex((c) => c.id === action.commentId);
      if (index !== -1) {
        const { comment, files } = action;
        const newComment = {
          ...comments[index], lastUpdate: moment(), comment, files
        };
        return state.setIn(['logs', index], newComment);
      }
      return state;
    }

    case CUSTOMER_COMMENT_DELETED: {
      const comments = state.get('logs').toJS();
      const index = comments.findIndex((c) => c.id === action.commentId);
      return state.deleteIn(['logs', index]);
    }

    default:
      return state;
  }
}

export function customerBookings(state = Map({ bookings: fromJS([]), loading: true }), action = null) {
  switch (action.type) {
    case CUSTOMER_CLEAR_FORM:
    case CREATE_CUSTOMER:
      return state.set('bookings', fromJS([])).set('loading', true);
    case BOOKING_HISTORY_UPDATED: {
      const index = state.get('bookings').findIndex((c) => c.get('id') === action.booking.id) || 0;
      return state.setIn(['bookings', index], state.get('bookings').get(index).merge(action.booking));
    }
    case BOOKING_HISTORY_LOADED:
      return state.set('bookings', fromJS(action.response)).set('loading', false);
    default:
      return state;
  }
}

export function customerSales(state = Map({ sales: [], loading: true }), action = null) {
  switch (action.type) {
    case CREATE_CUSTOMER:
    case CUSTOMER_CLEAR_FORM:
      return state.set('sales', []).set('loading', true);

    case CUSTOMER_SALES_FETCHED:
      return state.set('loading', false).set('sales', action.sales);

    default:
      return state;
  }
}

export function customerGiftCards(state = Map({ giftCards: [], loading: true }), action = null) {
  switch (action.type) {
    case CREATE_CUSTOMER:
    case CUSTOMER_CLEAR_FORM:
      return state.set('giftCards', []).set('loading', true);

    case CUSTOMER_GIFT_CARDS_FETCHED:
      return action.saleId
        ? state
        : state.set('loading', false).set('giftCards', action.giftCards);

    default:
      return state;
  }
}

export function customerAssociations(state = Map({ associations: [], loading: true }), action = null) {
  switch (action.type) {
    case CREATE_CUSTOMER:
    case CUSTOMER_CLEAR_FORM:
      return state.set('associations', []).set('loading', true);

    case CUSTOMER_ASSOCIATIONS_FETCHED:
      return state.set('loading', false).set('associations', action.associations);

    default:
      return state;
  }
}
