import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import styled from 'styled-components';

import { config } from '@Utils/preference-keys';
import { postWebkitMessage } from '@Utils/wk-embed-bridges';
import { getSectionUrl, getOrganisationsUrl } from '@Utils/navigate';
import { hasPermission, CHANGE_LOCATION_SETTINGS } from '@Utils/permissions';
import { getCurrentLocation, getFeatures, getPermissions } from '@State/selectors';
import { isWrappedInApp } from '@Utils/embedded-util';
import UserMenuLocation from '@Components/nav/user-menu-location';
import DropDown, {
  DropdownHeader, DropDownDivider, DropDownTextInput, DropDownItem
} from '@Components/ui/drop-down/dropdown';
import { logMeOut } from '@Utils/account';
import { txt } from '@Utils/i18n-util';
import msg from './user-menu.msg';

const UserLabel = styled.div`
  padding: 0 0 0 20px;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow-x: auto;
`;
const styleUserMenu = { position: 'absolute', top: 32, right: 0 };

class UserMenu extends Component {
  state = {
    locationFilter: ''
  };

  filterLocations = event => {
    this.setState({ locationFilter: event.target.value.toLowerCase() });
  };

  UNSAFE_componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false);
    document.addEventListener('keydown', this.escFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
    document.removeEventListener('keydown', this.escFunction, false);
  }

  escFunction = (e) => {
    if (e.keyCode === 27) {
      this.props.onClose();
    }
  };

  handleClick = (e) => {
    if (this.node.contains(e.target) || e.target.className.includes('ui-avatar')) { // add class includes have one lister
      return;
    }
    this.props.onClose();
  }

  handleEmbeddedLogout = () => {
    logMeOut(true);
    postWebkitMessage('logMeOut', { foo: 'bar' });
  }

  handleLogout = () => {
    logMeOut();
  }

  render() {
    const {
      routeParams, currentLocation, sortedOptions, features, permissions,
      showLocationFilter, username, locationName, onClose, wrappedInApp,
      showNotification, hasReleaseNotes
    } = this.props;
    const { ShowPayments, ShowUserMenuSettings, ShowUserMenuLogout } = features || {};
    const { locationFilter } = this.state;

    const showSubscription = ShowPayments && hasPermission(permissions, CHANGE_LOCATION_SETTINGS);
    const filtered = locationFilter
      ? sortedOptions.filter(value => {
        return (
          value.orgName.toLowerCase().indexOf(locationFilter) !== -1
            || value.locName.toLowerCase().indexOf(locationFilter) !== -1
        );
      })
      : sortedOptions;

    return (
      <div ref={node => { this.node = node; }} style={styleUserMenu}>
        <DropDown light position="right">
          <DropdownHeader onClick={onClose}>{locationName}</DropdownHeader>
          <UserLabel>{username}</UserLabel>

          {hasReleaseNotes && (
            <>
              <DropDownDivider />
              <DropDownItem onClick={this.props.onShowReleaseNotes} icon="fa-cloud-bolt">
                {txt(msg.releaseNotes)}
                {showNotification && <span className="label-news">{txt(msg.news)}</span>}
              </DropDownItem>
            </>
          )}

          {ShowUserMenuSettings && (
            <>
              <DropDownDivider />
              <DropDownItem href={getSectionUrl(routeParams, 'user-config')} icon="fa-bells">{txt(msg.emailNotifications)}</DropDownItem>
              <DropDownItem href={getSectionUrl(routeParams, 'user-config')} icon="fa-key">{txt(msg.changePassword)}</DropDownItem>
            </>
          )}
          {showSubscription && (
            <>
              <DropDownDivider />
              <DropDownItem href={getOrganisationsUrl(routeParams)} icon="fa-cloud" noLink>{txt(msg.subscription)}</DropDownItem>
            </>
          )}

          <DropDownDivider />
          <DropdownHeader>{showLocationFilter ? txt(msg.accounts10plus) : txt(msg.accounts)}</DropdownHeader>

          {showLocationFilter && (
            <DropDownTextInput onChange={this.filterLocations} placeholder={txt(msg.searchAccountsPlaceholder)} />
          )}
          {(!showLocationFilter || locationFilter) && (filtered.map((location) => (
            <UserMenuLocation key={location.orgLoc} routeParams={routeParams} location={location} currentLocation={currentLocation} />
          )))}
          {ShowUserMenuLogout && (
            <>
              <DropDownDivider />

              {wrappedInApp ? (
                <DropDownItem onClick={this.handleEmbeddedLogout} icon="fa-sign-out">{txt(msg.logOut)}</DropDownItem>
              ) : (
                <DropDownItem onClick={this.handleLogout} icon="fa-sign-out" noLink>{txt(msg.logOut)}</DropDownItem>
              )}
            </>
          )}
        </DropDown>
      </div>
    );
  }
}

UserMenu.propTypes = {
  sortedOptions: PropTypes.object.isRequired,
  features: PropTypes.object.isRequired,
  routeParams: PropTypes.object.isRequired,
  currentLocation: PropTypes.object,
  username: PropTypes.string.isRequired
};

const getOptions = state => state.locationOptions;
const getRouteParams = (state, props) => props.routeParams;
const getShowFilter = state => {
  return state.locationOptions.size > 10;
};

const getSortedOptions = createSelector(
  getOptions,
  options => {
    return options.valueSeq().sort((a, b) => {
      const aStr = a.orgName + a.locName;
      const bStr = b.orgName + b.locName;
      return aStr.localeCompare(bStr, 'sv');
    });
  }
);

const mapStateToProps = (state, ownProps) => {
  const { mainViewState, authState, locationConfig } = state;
  const wrappedInApp = isWrappedInApp(state);

  return {
    sortedOptions: getSortedOptions(state, ownProps),
    features: getFeatures(state),
    permissions: getPermissions(state, ownProps),
    routeParams: getRouteParams(state, ownProps),
    currentLocation: getCurrentLocation(state, ownProps),
    showLocationFilter: getShowFilter(state),
    username: authState.get('username'),
    tabletMode: mainViewState.get('tabletMode'),
    hasReleaseNotes: !!locationConfig.get(config.releaseNote),
    wrappedInApp
  };
};

export default connect(mapStateToProps)(UserMenu);
