import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { bookingTypes } from '@Utils/booking-util';
import { txt } from '@Utils/i18n-util';
import msg from './booking-type.msg';

const BookingTypeItem = ({ currentType, type, setType, children }) => (
  <li className={currentType === type ? 'is-active' : ''}>
    <a href="#" onClick={ev => setType(ev, type)}>{children}</a>
  </li>
);

const BookingType = ({ currentType, onTypeChange }) => {
  const enableClassBooking = useSelector(state => state.locationFeatures.get('EnableClassBooking'));

  const setType = useCallback((ev, typeString) => {
    ev.target.blur();
    ev.preventDefault();
    onTypeChange(typeString);
  }, []);

  return (
    <div className="booking-form-segment">
      <ul>
        <BookingTypeItem currentType={currentType} setType={setType} type={bookingTypes.SimpleBooking}>
          {txt(msg.simpleBooking)}
        </BookingTypeItem>
        {enableClassBooking && (
          <BookingTypeItem currentType={currentType} setType={setType} type={bookingTypes.ClassBooking}>
            {txt(msg.classBooking)}
            <span className="label-news">NY</span>
          </BookingTypeItem>
        )}
        <BookingTypeItem currentType={currentType} setType={setType} type={bookingTypes.Reservation}>
          {txt(msg.reservation)}
        </BookingTypeItem>
      </ul>
    </div>
  );
};

export default BookingType;
