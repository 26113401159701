import React, { Component } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { getVatRates } from '@State/selectors';
import Popover from '@Components/ui/popover';
import SubmitNotice from '@Components/ui/submit-notice';
import CurrencyUtil from '@Utils/currency-util';
import { required, number } from '@Utils/validation';
import { pauseScanner, resumeScanner } from '@Utils/barcode';
import ProductAddBarcode from '@Components/inventory/products/product-add-barcode';
import { Panel } from '@Components/ui/styled/main';
import CheckBox from '@Components/inputs/checkbox';
import TextInput from '@Components/inputs/text-input';
import ColorPicker from '@Components/inputs/color-picker';
import NoContent from '@Components/ui/no-content';
import ProductStock from './product-stock';
import ProductBarcodeModal from './product-barcode-modal';

const styleVatPct = { width: '120px' };

const ReadOnlyField = ({ label, value }) => (
  <div className="form-group">
    <label className="control-label">{label}</label>
    <input type="text" className="form-control" value={value} disabled />
  </div>
);

const TagsField = ({ label, values, onClick }) => (
  <div className="form-group">
    <label className="control-label">{label}</label>
    <div className="select-items-form-control" onClick={onClick}>
      {values?.map(value => (
        <div key={value} className="item gray">{value}</div>
      ))}
      {values.length === 0 && (
        <div className="no-content">Ingen streckkod</div>
      )}
    </div>
  </div>
);

class ProductForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deleteProgress: false,
      showConfirmDelete: false,
      currentField: null,
      currentFieldValue: null,
      showAddBarcode: false
    };
  }

  componentDidUpdate(prevProps) {
    const hasSameId = this.props.initialValues.id === prevProps.initialValues.id;
    const colorChanged = this.props.color !== prevProps.color;
    const vatPctChanged = this.props.vatPct !== prevProps.vatPct;
    const notForSaleChanged = this.props.notForSale !== prevProps.notForSale;
    const showInPosChanged = this.props.showInPos !== prevProps.showInPos;
    const showInFavsChanged = this.props.showInFavs !== prevProps.showInFavs;

    if (hasSameId && (colorChanged || vatPctChanged || notForSaleChanged || showInPosChanged || showInFavsChanged)) {
      this.props.submit();
    }
  }

  focus = e => {
    this.setState({
      currentField: e.target.name,
      currentFieldValue: e.target.value
    });
  };

  blur = e => {
    const { currentField, currentFieldValue } = this.state;
    if (currentField === e.target.name && currentFieldValue !== e.target.value) {
      this.props.submit();
    }
  };

  showConfirmDelete = ev => {
    ev.preventDefault();
    this.setState({ showConfirmDelete: true, deleteProgress: false });
  };

  hideConfirmDelete = ev => {
    ev.preventDefault();
    this.setState({ showConfirmDelete: false });
  };

  onDelete = ev => {
    ev.preventDefault();
    this.setState({ deleteProgress: true });
    this.props
      .onDelete(this.props.productId)
      .catch(() => this.setState({ deleteProgress: false }));
  };

  showAddBarcode = ev => {
    ev.preventDefault();
    ev.target.blur();
    this.setState({ showAddBarcode: true });
    pauseScanner();
  };

  hideAddBarcode = ev => {
    ev?.preventDefault();
    this.setState({ showAddBarcode: false });
    resumeScanner();
  };

  saveBarcode = ({ barcode }) => {
    const { productId } = this.props;
    return this.props.onAddBarcode(productId, barcode);
  };

  showBarcodeModal = ev => {
    ev.preventDefault();
    ev.target.blur();
    this.setState({ showBarcodeModal: true });
    pauseScanner();
  };

  hideBarcodeModal = ev => {
    ev?.preventDefault();
    this.setState({ showBarcodeModal: false });
    resumeScanner();
  };

  deleteBarcode = (barcode) => {
    const { productId } = this.props;
    return this.props.onDeleteBarcode(productId, barcode);
  };

  exVatPrice = (price, vat) => price ? (price / `1.${vat}`).toFixed(2) : 0;

  render() {
    const {
      handleSubmit,
      priceOut,
      vatPct,
      submitting,
      submitSucceeded,
      vatRates,
      notForSale,
      productId,
      product,
      productGroups
    } = this.props;
    const { showAddBarcode, showBarcodeModal } = this.state;
    const { id, EAN, supplier, supplierBrand, supplierProductName } = product;

    if (!id) {
      return (
        <div className="columns-content-container">
          <div className="columns-content-body with-padding">
            <NoContent icon="fa fa-cubes">
              {productGroups.isEmpty() ? (
                <>
                  Det finns inga produkter eller grupper.<br />
                  Klicka på "Lägg till grupp" för att skapa en grupp.
                </>
              ) : (
                <>
                  Det finns inga tjänster.<br />
                  Klicka på "Lägg till tjänst" för att skapa en tjänst.
                </>
              )}
            </NoContent>
          </div>
        </div>
      );
    }

    return (
      <div className="columns-content-container">
        <div className="columns-content-body with-padding">
          <form
            autoComplete="off"
            key="form"
            onSubmit={handleSubmit}
          >
            {supplier && (
              <div className="pull-right text-muted">Leverantör: {supplier}</div>
            )}
            {supplierProductName ? (
              <ReadOnlyField label="Namn" value={supplierProductName} />
            ) : (
              <Field
                name="name"
                component={TextInput}
                label="Namn"
                onFocus={e => this.focus(e)}
                onBlur={e => this.blur(e)}
              />
            )}
            {supplierBrand ? (
              <ReadOnlyField label="Varumärke" value={supplierBrand} />
            ) : (
              <Field
                name="brand"
                component={TextInput}
                label="Varumärke"
                onFocus={e => this.focus(e)}
                onBlur={e => this.blur(e)}
              />
            )}
            <div>
              <div className="pull-right"><a href="#" onClick={this.showAddBarcode}>Lägg till streckkod</a></div>
              <TagsField
                label="Streckkoder"
                values={EAN}
                onClick={EAN.length > 0 ? this.showBarcodeModal : this.showAddBarcode}
              />
            </div>
            <Field
              name="priceOut"
              component={TextInput}
              width={120}
              type="number"
              label="Försäljningspris (inkl. moms)"
              helpText={`Pris exkl. moms: ${CurrencyUtil.accountCurrency(this.exVatPrice(priceOut, vatPct))}`}
              unitLabel={CurrencyUtil.currencySymbol()}
              validate={[number]}
              onFocus={e => this.focus(e)}
              onBlur={e => this.blur(e)}
            />
            <div className="form-group">
              <label htmlFor="vatPct" className="control-label">Moms</label>
              <div className="select-container" style={styleVatPct}>
                <Field
                  name="vatPct"
                  component="select"
                  id="vatPct"
                  className="form-control"
                  validate={[required]}
                  onFocus={e => this.focus(e)}
                >
                  {vatRates.map(vat => <option key={vat} value={vat}>{`${vat}%`}</option>)}
                </Field>
              </div>
            </div>

            <label className="control-label mt1">Färg i kassan</label>
            <Field component={ColorPicker} name="color" />

            <h5 className="mt3">Inställningar för kassa</h5>
            <Panel>
              <div className="form-group">
                <Field name="notForSale" component={CheckBox} label="Får inte säljas till kund" />
              </div>
              <div className="form-group">
                <Field name="showInPos" component={CheckBox} label="Visa i kassan" disabled={notForSale} />
                <Field name="showInFavs" component={CheckBox} label="Visa som favorit i kassan" disabled={notForSale} />
              </div>
            </Panel>

            <br />
            <div className="notice-container">
              <SubmitNotice
                submitting={submitting}
                submitSucceeded={submitSucceeded}
              />
              <Popover
                isOpen={this.state.showConfirmDelete}
                body={this.popoverContent()}
                onOuterAction={this.hideConfirmDelete}
              >
                <button
                  className="btn-delete"
                  tabIndex={4}
                  onClick={this.showConfirmDelete}
                >
                  Ta bort
                </button>
              </Popover>
            </div>
          </form>
          {showAddBarcode && (
            <ProductAddBarcode
              onSubmit={this.saveBarcode}
              onClose={this.hideAddBarcode}
            />
          )}
          {showBarcodeModal && !showAddBarcode && (
            <ProductBarcodeModal
              EANs={EAN}
              onDelete={this.deleteBarcode}
              onClose={this.hideBarcodeModal}
              onAddBarcode={this.showAddBarcode}
            />
          )}
        </div>
        <div className="columns-content-sidebar">
          <ProductStock productId={productId} />
        </div>
      </div>
    );
  }

  popoverContent() {
    return (
      <div className="Popover-content-small">
        <a href="#" onClick={this.hideConfirmDelete} className="Popover-close">
          <i className="fa fa-lg fa-times" />
        </a>
        Är du säker på att du vill ta bort produkten? Detta går inte att ångra!
        <br />
        <br />
        <button
          className="btn-delete btn-block"
          onClick={this.onDelete}
          disabled={this.state.deleteProgress}
        >
          Ta bort produkt
        </button>
      </div>
    );
  }
}

const selector = formValueSelector('product-form');

const mapStateToProps = state => ({
  productGroups: state.productGroups,
  vatRates: getVatRates(state),
  priceOut: selector(state, 'priceOut'),
  vatPct: selector(state, 'vatPct'),
  productType: selector(state, 'productType'),
  color: selector(state, 'color'),
  notForSale: selector(state, 'notForSale'),
  showInPos: selector(state, 'showInPos'),
  showInFavs: selector(state, 'showInFavs')
});

export default reduxForm({
  form: 'product-form'
})(connect(mapStateToProps)(ProductForm));
