import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isBookingNew, getClassStatus } from '@Utils/booking-util';

class BookingClassHeader extends Component {
  render() {
    const { bookedSlots, maxSlots, bookingClosed, services } = this.props;
    const hasServices = services && services.size > 0;
    const isNew = isBookingNew(this.props);
    const status = getClassStatus(bookedSlots, maxSlots, bookingClosed);

    if (isNew || !hasServices) {
      return null;
    }

    return (
      <div className="booking-form-block class-header nopointer">
        <h4>
          {services.map(s => s.name).join(', ')}
        </h4>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { bkf } = state;

  return {
    id: bkf.get('id'),
    bookedSlots: bkf.get('bookedSlots'),
    bookingClosed: bkf.get('bookingClosed'),
    maxSlots: bkf.get('maxSlots'),
    services: bkf.get('services'),
    service: bkf.get('service')
  };
};

export default connect(mapStateToProps)(BookingClassHeader);
