import { createSelector } from 'reselect';
import {
  hasPermission, CHANGE_LOCATION_SERVICES, CHANGE_RESOURCES, CHANGE_LOCATION_SETTINGS, POS_ADMIN, ADMINISTRATE_LOC_USERS
} from '@Utils/permissions';
import {
  getFeatures, getPermissions, getTrialStatus, getAccountStatus, getSysAdmin, getWebPaymentEnabled, getChangeJsonPrefsPermission
} from '@State/selectors';
import { actionRequired } from '@Utils/account';
import { txt } from '@Utils/i18n-util';
import msg from '@Components/admin/nav-selectors.msg';

export const getAdminNavSections = createSelector(
  getPermissions,
  getTrialStatus,
  getAccountStatus,
  getFeatures,
  getWebPaymentEnabled,
  getSysAdmin,
  getChangeJsonPrefsPermission,
  (permissions, trialStatus, accountStatus, features, paymentEnabled, isSysAdmin, changeJsonPrefs) => {
    const sections = [];
    const hasPosPermission = hasPermission(permissions, POS_ADMIN);
    const hasSettingsPermission = hasPermission(permissions, CHANGE_LOCATION_SETTINGS);

    if (features.ShowServicesSettings && hasPermission(permissions, CHANGE_LOCATION_SERVICES)) {
      sections.push({
        id: 'services', group: txt(msg.booking), name: txt(msg.services), icon: 'fa-cut', helpText: txt(msg.servicesHelp)
      });
      sections.push({
        id: 'addon-services', group: txt(msg.booking), name: txt(msg.addonServices), icon: 'fa-cart-plus'
      });
    }
    if (features.ShowResourcesSettings && hasPermission(permissions, CHANGE_RESOURCES)) {
      sections.push({
        id: 'resources', group: txt(msg.booking), name: txt(msg.resources), icon: 'fa-calendar-alt', helpText: txt(msg.resourcesHelp)
      });
    }
    if (features.ShowUserSettings && hasPermission(permissions, ADMINISTRATE_LOC_USERS)) {
      sections.push({
        id: 'users', group: txt(msg.booking), name: txt(msg.users), icon: 'fa-users', helpText: txt(msg.usersHelp)
      });
    }
    if (features.ShowResourcesSettings && hasPermission(permissions, CHANGE_RESOURCES)) {
      sections.push({
        id: 'schedules', group: txt(msg.booking), name: txt(msg.schedules), icon: 'fa-calendar-week'
      });
    }

    if (features.ShowBookingSettings && hasSettingsPermission) {
      sections.push({
        id: 'booking-info', group: txt(msg.online), name: txt(msg.bookingInfo), icon: 'fa-globe', news: true
      });
      sections.push({
        id: 'company-info', group: txt(msg.online), name: txt(msg.companyInfo), icon: 'fa-home'
      });
      sections.push({
        id: 'web-booking', group: txt(msg.online), name: txt(msg.webBooking), icon: 'fa-wrench'
      });
    }
    if (paymentEnabled && hasSettingsPermission) {
      sections.push({
        id: 'payment', group: txt(msg.online), name: txt(msg.payment), icon: 'fa-credit-card', helpText: txt(msg.paymentHelp)
      });
    }
    if (paymentEnabled && features.EnableDiscountVouchers && hasSettingsPermission) {
      sections.push({
        id: 'vouchers', group: txt(msg.online), name: txt(msg.vouchers), icon: 'fa-tag'
      });
    }

    if (features.ShowSMSSettings && hasSettingsPermission) {
      sections.push({
        id: 'sms', group: txt(msg.communication), name: txt(msg.sms), icon: 'fa-mobile-alt', helpText: txt(msg.smsHelp)
      });
    }
    if (features.ShowEmailSettings && hasSettingsPermission) {
      sections.push({
        id: 'email', group: txt(msg.communication), name: txt(msg.email), icon: 'fa-envelope'
      });
    }
    if (features.ShowNotificationSettings && hasSettingsPermission) {
      sections.push({
        id: 'notifications', group: txt(msg.communication), name: txt(msg.notifications), icon: 'fa-bell'
      });
    }

    if ((features.EnablePOS || paymentEnabled) && hasSettingsPermission) {
      sections.push({
        id: 'pos', group: txt(msg.pos), name: txt(msg.cashRegisters), icon: 'fa-cash-register'
      });
    }
    if (features.EnablePOS && hasSettingsPermission) {
      sections.push({
        id: 'pos-config', group: txt(msg.pos), name: txt(msg.posSettings), icon: 'fa-wrench'
      });
    }
    if (features.EnablePOS && hasPosPermission) {
      sections.push({
        id: 'gift-cards', group: txt(msg.pos), name: txt(msg.giftCards), icon: 'fa-gift'
      });
      sections.push({
        id: 'voucher-templates', group: txt(msg.pos), name: txt(msg.giftCardTemplates), icon: 'fa-gifts'
      });
      sections.push({
        id: 'invoices', group: txt(msg.pos), name: txt(msg.invoices), icon: 'fa-file-alt'
      });
      if (isSysAdmin) {
        sections.push({
          id: 'printers', group: txt(msg.pos), name: txt(msg.printers), icon: 'fa-print'
        });
        sections.push({
          id: 'terminals', group: txt(msg.pos), name: txt(msg.terminals), icon: 'fa-calculator'
        });
      }
      sections.push({
        id: 'products', group: txt(msg.stock), name: txt(msg.products), icon: 'fa-cubes'
      });
      sections.push({
        id: 'delivery', group: txt(msg.stock), name: txt(msg.delivery), icon: 'fa-truck'
      });
      sections.push({
        id: 'stock-taking', group: txt(msg.stock), name: txt(msg.stockTaking), icon: 'fa-box-open'
      });
    }

    if (features.ShowPayments && hasSettingsPermission) {
      const notification = actionRequired(trialStatus, accountStatus) ? '!' : null;
      sections.push({
        id: 'subscription', group: txt(msg.account), name: txt(msg.subscription), icon: 'fa-cloud', notification, helpText: txt(msg.subscriptionHelp)
      });
    }
    if (features.ShowAddons && hasSettingsPermission) {
      sections.push({
        id: 'features', group: txt(msg.account), name: txt(msg.features), icon: 'fa-puzzle-piece'
      });
    }
    if (features.ShowGeneralSettings && hasSettingsPermission) {
      sections.push({
        id: 'storage', group: txt(msg.account), name: txt(msg.storage), icon: 'fa-save', helpText: txt(msg.storageHelp)
      });
      sections.push({
        id: 'contacts', group: txt(msg.account), name: txt(msg.contacts), icon: 'fa-asterisk'
      });
    }

    if (changeJsonPrefs) {
      sections.push({
        id: 'json-prefs', group: txt(msg.sysadmin), name: txt(msg.advanced), icon: 'fa-brackets-curly', helpText: txt(msg.advancedHelp)
      });
    }

    if (isSysAdmin) {
      sections.push({
        id: 'partners', group: txt(msg.sysadmin), name: txt(msg.partners), icon: 'fa-hands-helping'
      });
    }

    return sections;
  }
);
