import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { CustomerType } from '@Utils/customer-util';
import { txt } from '@Utils/i18n-util';
import msg from '@Utils/customer-util.msg';

const CustomerTypeItem = ({ currentType, type, setType, children }) => (
  <li className={currentType === type ? 'is-active' : ''}>
    <a href="#" onClick={ev => setType(ev, type)}>{children}</a>
  </li>
);

const CustomerTypeSegment = ({ currentType, onTypeChange }) => {
  const enableCompanyBooking = useSelector(state => state.locationFeatures.get('EnableCompanyBooking'));
  const enableVehicleBooking = useSelector(state => state.locationFeatures.get('EnableVehicleBooking'));

  const setType = useCallback((ev, typeString) => {
    ev.target.blur();
    ev.preventDefault();
    onTypeChange(typeString);
  }, []);

  return (
    <div className="booking-form-segment">
      <ul>
        <CustomerTypeItem currentType={currentType} setType={setType} type={CustomerType.Person}>
          {txt(msg[CustomerType.Person])}
        </CustomerTypeItem>
        {enableCompanyBooking && (
          <CustomerTypeItem currentType={currentType} setType={setType} type={CustomerType.Company}>
            {txt(msg[CustomerType.Company])}
          </CustomerTypeItem>
        )}
        {enableVehicleBooking && (
          <CustomerTypeItem currentType={currentType} setType={setType} type={CustomerType.Vehicle}>
            {txt(msg[CustomerType.Vehicle])}
          </CustomerTypeItem>
        )}
      </ul>
    </div>
  );
};

export default CustomerTypeSegment;
