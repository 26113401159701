import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { openCustomerTab } from '@State/cf-actions';
import CustomerDetailOverview from '@Components/customers/customer-detail/overview/customer-detail-overview';
import CustomerBookings from '@Components/customers/customer-detail/bookings/customer-bookings';
import CustomerDetailLogs from '@Components/customers/customer-detail/logs/customer-detail-logs';
import CustomerDetailSales from '@Components/customers/customer-detail/sales/customer-detail-sales';
import CustomerGiftCards from '@Components/customers/customer-detail/gift-cards/customer-gift-cards';
import CustomerEmployees from '@Components/customers/customer-detail/employees/customer-employees';
import CustomerVehicles from '@Components/customers/customer-detail/vehicles/customer-vehicles';
import CustomerVehicleInfo from '@Components/customers/customer-detail/vehicle-info/customer-vehicle-info';

const CustomerModalBody = ({
  isLoading, customerId, selectedCustomer, selectedTab, tabId, routeParams
}) => {
  const dispatch = useDispatch();
  const onSelectTabId = (tabId, tab) => {
    dispatch(openCustomerTab({ tab, tabId }));
  };
  const renderBody = (tab) => {
    switch (tab) {
      case 'bookings':
        return <CustomerBookings tabId={tabId} onSelectBooking={onSelectTabId} />;
      case 'logs':
        return <CustomerDetailLogs tabId={tabId} />;
      case 'sales':
        return <CustomerDetailSales tabId={tabId} onSelectSales={onSelectTabId} />;
      case 'gift-cards':
        return (
          <CustomerGiftCards
            isModal
            tabId={tabId}
            routeParams={routeParams}
            onSelectGiftCard={onSelectTabId}
          />
        );
      case 'employees':
        return <CustomerEmployees tabId={tabId} onSelectEmployee={onSelectTabId} />;
      case 'vehicles':
        return <CustomerVehicles tabId={tabId} onSelectVehicle={onSelectTabId} />;
      case 'vehicle-info':
        return <CustomerVehicleInfo />;
      default:
        return (
          <CustomerDetailOverview
            modalView
            routeParams={routeParams}
            isLoadingCustomer={isLoading}
            customerId={customerId}
            selectedCustomer={selectedCustomer}
            tabId={tabId}
          />
        );
    }
  };
  return (
    <div className="customer-tab-content" id={`#${selectedTab}`} key={selectedTab}>
      {renderBody(selectedTab)}
    </div>
  );
};

CustomerModalBody.propTypes = {
  selectedIndex: PropTypes.number,
  isModal: PropTypes.bool,
  component: PropTypes.node,
  styleBody: PropTypes.object
};

export default memo(CustomerModalBody);
