import { defineMessages } from 'react-intl';

export default defineMessages({
  lblOrgName: {
    id: 'calendar.bkf.company.lblOrgName',
    defaultMessage: 'Company'
  },
  lblOrgNo: {
    id: 'calendar.bkf.company.lblOrgNo',
    defaultMessage: 'Company no.'
  },
  lblOrgNoInvalid: {
    id: 'calendar.bkf.company.lblOrgNoInvalid',
    defaultMessage: 'mandatory'
  },
  btnOpen: {
    id: 'calendar.bkf.company.btnOpen',
    defaultMessage: 'Open'
  },
  btnClose: {
    id: 'calendar.bkf.company.btnClose',
    defaultMessage: 'Close'
  },
  btnClear: {
    id: 'calendar.bkf.company.btnClear',
    defaultMessage: 'Clear'
  },
  btnAddOrg: {
    id: 'calendar.bkf.company.btnAddOrg',
    defaultMessage: 'Add company...'
  }
});
