import axios from 'axios/index';

import {
  fetchGet,
  fetchPost,
  fetchPut,
  fetchDelete,
  checkStatus,
  prefixUrl,
  logError,
  fetchErrorHandler,
  axiosErrorHandler, axiosDefault, checkStatusAxios, prefixIcalUrl
} from '@Utils/ajax-util';
import { apiUrl } from '@Utils/config';
import { prefixApiUrl } from '@Utils/url-util';
import { networkFailure } from './network-actions';

export const RECEIVE_USE_LOCATION_SCHEDULE = 'RECEIVE_USE_LOCATION_SCHEDULE';
export const RECEIVE_GROUPS_AND_RESOURCES = 'RECEIVE_GROUPS_AND_RESOURCES';

export const SORT_GROUPS = 'SORT_GROUPS';
export const ADD_GROUP = 'ADD_GROUP';
export const DELETE_GROUP = 'DELETE_GROUP';
export const RENAME_GROUP = 'RENAME_GROUP';

export const ADD_RESOURCE = 'ADD_RESOURCE';
export const DELETE_RESOURCE = 'DELETE_RESOURCE';
export const UPDATE_RESOURCE = 'UPDATE_RESOURCE';

export const GROUP_MOVED = 'GROUP_MOVED';
export const RESOURCE_MOVED = 'RESOURCE_MOVED';

function receiveGroupsAndResources(json) {
  return {
    type: RECEIVE_GROUPS_AND_RESOURCES,
    ...json
  };
}

export function fetchGroupsAndResources(orgCtxName = null, locCtxName = null) {
  return (dispatch) => {
    const url = orgCtxName && locCtxName
      ? prefixApiUrl(`/locations/${orgCtxName}/${locCtxName}/resources/with-groups/`)
      : prefixUrl('/resources/with-groups/');

    return fetch(url, fetchGet())
      .then(res => dispatch(checkStatus(res)))
      .then(res => res.json())
      .then(json => dispatch(receiveGroupsAndResources(json)))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

function groupRenamed(group) {
  return {
    type: RENAME_GROUP,
    ...group
  };
}

export function renameGroup(group) {
  const url = prefixUrl(`/resourcegroups/${group.id}`);
  const data = { name: group.name };

  return (dispatch) => {
    return fetch(url, fetchPut(data))
      .then(res => dispatch(checkStatus(res)))
      .then(req => dispatch(groupRenamed(group)))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

function groupDeleted(groupId) {
  return {
    type: DELETE_GROUP,
    groupId
  };
}

export function deleteGroup(groupId) {
  const url = prefixUrl(`/resourcegroups/${groupId}`);

  return (dispatch) => {
    return fetch(url, fetchDelete())
      .then(res => dispatch(checkStatus(res)))
      .then(req => dispatch(groupDeleted(groupId)))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

function groupAdded(group) {
  return {
    type: ADD_GROUP,
    group
  };
}

export function addResourceGroup(group) {
  const { name } = group;

  const url = prefixUrl('/resourcegroups/');
  const data = { name };

  return (dispatch) => {
    return fetch(url, fetchPost(data))
      .then(res => dispatch(checkStatus(res)))
      .then(res => res.json())
      .then(json => dispatch(groupAdded(json)))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

function groupsSorted(groups) {
  return {
    type: SORT_GROUPS,
    groups
  };
}

export function saveCopyToGroup(resourceId, groupId, settings) {
  const url = prefixUrl(`/resources/${resourceId}/copy-to-group/${groupId}`);

  return (dispatch) => {
    return fetch(url, fetchPost(settings))
      .then(res => dispatch(checkStatus(res)))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}
export function saveCopyToResource(resourceId, targetResourceId, settings) {
  const url = prefixUrl(`/resources/${resourceId}/copy-to-resource/${targetResourceId}`);

  return (dispatch) => {
    return fetch(url, fetchPost(settings))
      .then(res => dispatch(checkStatus(res)))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function saveGroupOrder(sortedGroups) {
  const url = prefixUrl('/resources/sort');

  return (dispatch) => {
    return fetch(url, fetchPost(sortedGroups))
      .then(res => dispatch(checkStatus(res)))
      .then(req => dispatch(groupsSorted(sortedGroups)))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

function resourceDeleted(id) {
  return {
    type: DELETE_RESOURCE,
    id
  };
}

export function deleteResource(resourceId) {
  const url = prefixUrl(`/resources/${resourceId}/deleted`);
  const data = {
    cancelFutureBookings: false
  };

  return (dispatch, getState) => {
    return fetch(url, fetchPut(data))
      .then(res => dispatch(checkStatus(res)))
      .then(res => dispatch(resourceDeleted(resourceId)))
      .catch((error) => {
        logError(error);
        const title = error.response.status === 400 ? 'Framtida bokningar finns' : null;
        const message = error.response.status === 400
          ? 'Resursen kan inte tas bort eftersom det finns framtida bokningar för resursen. Flytta eller ta bort befintliga bokningar för att kunna ta bort resursen.'
          : null;
        dispatch(networkFailure(message, title));
      });
  };
}

export function resourceUpdated(resource) {
  return {
    type: UPDATE_RESOURCE,
    resource
  };
}

function resourceAdded(resource, groupId) {
  return {
    type: ADD_RESOURCE,
    groupId,
    resource
  };
}

export function resourceUseLocationSchedule(useLocationSchedule) {
  return {
    type: RECEIVE_USE_LOCATION_SCHEDULE,
    useLocationSchedule
  };
}

function addResourceError(error) {
  return (dispatch) => {
    logError(error);
    const title = error.response.status === 400 ? 'Uppgradering krävs' : null;
    const message = error.response.status === 400
      ? 'Kunde inte lägga till resurs. Max antal resurser har uppnåtts. För att lägga till fler behöver abonnemanget uppgraderas.'
      : null;
    dispatch(networkFailure(message, title));
  };
}

export function addResource(_resource, initialSetup = false) {
  const { groupId, name, webName, resourceType } = _resource;

  const resource = {
    groupId: parseInt(groupId),
    name,
    webName,
    resourceType
  };

  const url = initialSetup
    ? prefixUrl(`/resources/group/${groupId}/initialsetup`)
    : prefixUrl(`/resources/group/${groupId}`);

  return (dispatch) => {
    return fetch(url, fetchPost(resource))
      .then(res => dispatch(checkStatus(res)))
      .then(res => res.json())
      .then((resource) => {
        dispatch(resourceAdded(resource, parseInt(groupId)));
        return resource;
      })
      .catch((error) => {
        dispatch(addResourceError(error));
      });
  };
}

export function duplicateResource(resourceId, name) {
  const url = prefixUrl(`/resources/${resourceId}/duplicate`);

  return (dispatch, getState) => {
    const { resourcesById } = getState();
    const resource = resourcesById.get(resourceId);

    return fetch(url, fetchPost({ name }))
      .then(res => dispatch(checkStatus(res)))
      .then(res => res.json())
      .then((response) => {
        const { resourceId, groupId } = response;
        const newResource = { ...resource, id: resourceId, name };
        dispatch(resourceAdded(newResource, parseInt(groupId)));
        return newResource;
      })
      .catch((error) => {
        dispatch(addResourceError(error));
      });
  };
}

export function updateResource(id, resource) {
  const url = prefixUrl(`/resources/${id}`);

  return (dispatch) => {
    return fetch(url, fetchPut(resource))
      .then(res => dispatch(checkStatus(res)))
      .then(() => dispatch(resourceUpdated({ id, ...resource })))
      .catch(error => dispatch(fetchErrorHandler(error)));
  };
}

export function moveGroup(moveAction) {
  return (dispatch) => {
    const url = prefixUrl('/resourcegroups/move');
    const config = axiosDefault();

    // Apply the change locally first (will revert the change if a network error occurs
    //
    dispatch({ type: GROUP_MOVED, moveAction });

    const { groupId, srcPos: srcIdx, destPos: dstIdx } = moveAction;
    const body = {
      groupId, srcIdx, dstIdx
    };

    return axios.put(url, body, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .catch((error) => {
        // Revert the change
        //
        const { groupId, srcPos, destPos } = moveAction;
        const m = {
          srcPos: destPos,
          destPos: srcPos,
          groupId
        };

        dispatch({ type: GROUP_MOVED, m });
        axiosErrorHandler(error, dispatch);
      });
  };
}

export function moveResource(moveAction) {
  return (dispatch) => {
    const url = prefixUrl('/resources/move');
    const config = axiosDefault();

    // Apply the change locally first - as required by the dnd component
    // (will revert the change if a network error occurs)
    //
    dispatch({ type: RESOURCE_MOVED, moveAction });

    const {
      srcGrpId, destGrpId: dstGrpId, srcPos: srcIdx, destPos: dstIdx, itemId
    } = moveAction;
    const body = {
      resourceId: itemId, srcGrpId, dstGrpId, srcIdx, dstIdx
    };

    return axios.put(url, body, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .catch((error) => {
        // Revert the change
        //
        const {
          srcGrpId, destGrpId, srcPos, destPos, itemId
        } = moveAction;

        const m = {
          srcGrpId: destGrpId,
          destGrpId: srcGrpId,
          srcPos: destPos,
          destPos: srcPos,
          resourceId: itemId
        };

        dispatch({ type: RESOURCE_MOVED, m });
        axiosErrorHandler(error, dispatch);
      });
  };
}

export function updateResourceAttribs(resourceId, attributes) {
  return (dispatch, getState) => {
    const url = prefixUrl(`/resources/${resourceId}/attribs`);
    const config = axiosDefault();
    const res = getState().resourcesById.get(parseInt(resourceId));

    return axios.put(url, attributes, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(() => {
        dispatch({ type: UPDATE_RESOURCE, resource: { ...res, attributes } });
      })
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function createIcalToken(resourceId) {
  return (dispatch) => {
    const url = prefixIcalUrl(`/tokens/resource/${resourceId}/`);
    const config = axiosDefault();

    return axios.post(url, null, config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => res.data)
      .catch(error => axiosErrorHandler(error, dispatch));
  };
}

export function getIcalFeedUrl(token) {
  return `${apiUrl()}/api/icalendar/${token}/bookings.ics`;
}
