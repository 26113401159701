import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { destroy, initialize } from 'redux-form';
import PropTypes from 'prop-types';
import { company, config } from '@Utils/preference-keys';
import { getVatRegNo } from '@Utils/i18n-util';
import { fetchCompanyInfo } from '@State/preferences-actions';
import { normalizeOrgNoFI, normalizeOrgNoSE } from '@Utils/normalize';

import CreatePosOrgCompany from '@Components/admin/pos-config/create-pos-org-company';
import CreatePosOrgAddress from '@Components/admin/pos-config/create-pos-org-address';
import CreatePosOrgOwner from '@Components/admin/pos-config/create-pos-org-owner';
import CreatePosOrgConfirm from '@Components/admin/pos-config/create-pos-org-confirm';

const CreatePosOrg = ({ onCreatePosOrg, onHideDialogs }) => {
  const dispatch = useDispatch();
  const posOrgs = useSelector(state => state.posOrgs);
  const companyInfo = useSelector(state => state.preferencesViewState.get('companyInfo'));
  const contacts = useSelector(state => state.preferencesViewState.get('contacts'));
  const locationConfig = useSelector(state => state.locationConfig);
  const region = locationConfig.get(config.region);

  const [separateAddress, setSeparateAddress] = useState(false);
  const [showDialog, setShowDialog] = useState('Company');

  useEffect(() => {
    if (!companyInfo) {
      dispatch(fetchCompanyInfo());
    } else if (posOrgs.isEmpty()) {
      dispatch(initialize('create-pos-org', {
        companyName: companyInfo?.get('orgName'),
        companyOrgNo: region === 'FI'
          ? normalizeOrgNoFI(companyInfo?.get('companyRegNo'))
          : normalizeOrgNoSE(companyInfo?.get('companyRegNo')),
        companyAddressLine1: locationConfig.get(company.addressLine1),
        companyPostCode: locationConfig.get(company.postCode)?.replace(' ', ''),
        companyArea: locationConfig.get(company.area),
        contactName: contacts?.getIn(['Owner', 'name']),
        contactEmail: contacts?.getIn(['Owner', 'email'])
      }));
    }
  }, [posOrgs, companyInfo]);

  useEffect(() => {
    return () => {
      dispatch(destroy('create-pos-org'));
    };
  }, []);

  const hide = () => {
    onHideDialogs();
    setShowDialog('');
  };

  const onShowCompany = () => setShowDialog('Company');
  const onShowOwner = () => setShowDialog('Owner');
  const onShowConfirm = () => setShowDialog('Confirm');
  const onShowAddress = () => setShowDialog('Address');

  const onPrevOwner = () => separateAddress ? onShowAddress() : onShowCompany();
  const onPrevCompany = () => separateAddress ? onShowAddress() : onShowOwner();

  const onSubmit = ({
    companyOrgNo, companyAddressLine1, companyPostCode, companyArea,
    posAddressLine1, posPostCode, posArea, ...values
  }) => {
    const vatRegNo = getVatRegNo(companyOrgNo, region);

    const companyAddress = {
      addressLine1: companyAddressLine1,
      country: region,
      postCode: companyPostCode,
      area: companyArea
    };

    const posAddress = posAddressLine1 ? {
      addressLine1: posAddressLine1,
      country: region,
      postCode: posPostCode,
      area: posArea
    } : null;

    return onCreatePosOrg({
      ...values, companyAddress, posAddress, vatRegNo, companyOrgNo
    });
  };

  switch (showDialog) {
    case 'Company':
      return (
        <CreatePosOrgCompany
          region={region}
          setSeparateAddress={setSeparateAddress}
          separateAddress={separateAddress}
          onNext={onPrevCompany}
          onHide={hide}
        />
      );
    case 'Address':
      return <CreatePosOrgAddress onPrev={onShowCompany} onNext={onShowOwner} onHide={hide} />;
    case 'Owner':
      return <CreatePosOrgOwner onPrev={onPrevOwner} onNext={onShowConfirm} onHide={hide} />;
    case 'Confirm':
      return <CreatePosOrgConfirm onSubmit={onSubmit} onPrev={onShowOwner} onHide={hide} />;
    default: return null;
  }
};

CreatePosOrg.propTypes = {
  onCreatePosOrg: PropTypes.func,
  onHideDialogs: PropTypes.func
};

export default CreatePosOrg;
