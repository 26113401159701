import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import last from 'lodash/last';

import ModalDialog from '@Components/dialogs/modal-dialog';
import Numpad from '@Components/inputs/numpad';
import CalcDisplay from '@Components/ui/calc-display';
import { colors } from '@Components/ui/styled/variables';
import { getCurrentTime, getTimeISO, getDate } from '@Utils/time-util';
import { HOURS_MINUTES_SECONDS } from '@Utils/time-constants';
import useKeyPress from '@Hooks/useKeyPress';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  position: relative;
  i {
    color: ${colors.primaryLight};
    position: absolute;
    left: 18px;
    font-size: 33px;
    top: -4px;
  }
`;

const StaffEditStartEntryModal = ({ onClose, handleSubmit }) => {
  const currentTime = getCurrentTime();
  const [timeCheckIn, setTimeCheckIn] = useState(currentTime);
  const [prevTime, setPrevTime] = useState('');
  const [isCanDeleteAllTime, setIsCanDeleteAllTime] = useState(true);
  const [loading, setLoading] = useState(false);

  const onChangeTimeCheckIn = (value) => {
    if (value.length === 6 && isCanDeleteAllTime) {
      setIsCanDeleteAllTime(false);
      value = last(value);
    }

    setPrevTime(value);
    if (value.length > 5) return timeCheckIn;
    let time = value;
    const [firstHour, secondHour, firstMinute, secondMinute] = value.replace(':', '').split('');

    if (value.length === 3) {
      time = value.includes(':') ? `${firstHour}${secondHour}:` : `${firstHour}${secondHour}:${firstMinute}`;
    }
    if (value.length === 2) {
      time = prevTime.includes(':') ? `${firstHour}` : value;
    }

    if (+firstHour > 2) time = timeCheckIn;
    if ((+firstHour === 0 || +firstHour === 1) && +secondHour > 9) time = timeCheckIn;
    if (+firstHour === 2 && +secondHour > 3) time = timeCheckIn;
    if (+firstMinute > 5) time = timeCheckIn;
    if (+secondMinute > 9) time = timeCheckIn;

    if (value.length === 1 && prevTime.length === 0 && +firstHour > 2) {
      time = `0${firstHour}:`;
    }
    setTimeCheckIn(time);
  };

  const onSaveTime = async () => {
    const time = getCurrentTime() === timeCheckIn ? getCurrentTime(HOURS_MINUTES_SECONDS) : timeCheckIn;
    const timeISO = getTimeISO(`${getDate()} ${time}`);
    setLoading(true);
    try {
      await handleSubmit(timeISO);
    } finally {
      setLoading(false);
      onClose();
    }
  };

  const isValidValue = (timeCheckIn) => timeCheckIn.length === 5;

  const handleEnter = useCallback(() => {
    return isValidValue(timeCheckIn) && onSaveTime();
  }, [timeCheckIn]);

  const buttons = [{
    name: 'Checka in',
    primary: true,
    onClick: onSaveTime,
    disabled: timeCheckIn.length < 5 || loading,
    loading
  }];

  return (
    <ModalDialog
      onClose={onClose}
      buttons={buttons}
      title="Bekräfta starttid"
    >
      <CalcDisplay isFullText>
        <Wrapper>
          <i className="fal fa-stopwatch" />
          <span>
            {timeCheckIn}
          </span>
        </Wrapper>
      </CalcDisplay>
      <Numpad
        hideZeros
        input={timeCheckIn}
        onChange={onChangeTimeCheckIn}
        onEnter={handleEnter}
      />
    </ModalDialog>
  );
};

StaffEditStartEntryModal.propTypes = {
  onClose: PropTypes.func,
  handleSubmit: PropTypes.func
};

export default StaffEditStartEntryModal;
