import axios from 'axios';
import moment from 'moment';
import isRetryAllowed from 'is-retry-allowed';
import { versionMismatch, networkFailure } from '@State/network-actions';
import { changeAccountStatus } from '@State/account-actions';
import { getOrgWideCustomerDb } from '@State/selectors';
import { logoutOperator } from '@State/pos-actions';
import { getAccessToken, NO_AUTH_TOKEN } from '@Login/actions';
import { prefixAjaxUrl, prefixApiUrl, prefixWithOrgLoc } from './url-util';
import { apiUrl, clientBuildNumber } from './config';
import { getSessionOpToken } from './session';
import { isEmbeddedOrWrapped } from './embedded-util';
import { postWebkitMessage } from './wk-embed-bridges';

export function prefixUrl(url) {
  return prefixAjaxUrl(url);
}

export function prefixOrgUrl(url) {
  return prefixAjaxUrl(url, true);
}

export function prefixSearchUrl(url, state) {
  if (!state) {
    throw new Error('State is required');
  }
  return getOrgWideCustomerDb(state)
    ? prefixOrgUrl(url)
    : prefixUrl(url);
}

export function prefixCustomerUrl(url, state) {
  const { locationOptions, customerById } = state;

  if (getOrgWideCustomerDb(state)) {
    const locationId = customerById.get('locationId');
    const location = locationOptions.find(l => l.locationId === locationId);

    if (location) {
      const { orgCtxName, locCtxName } = location;
      return prefixApiUrl(prefixWithOrgLoc(url, orgCtxName, locCtxName));
    }
  }
  return prefixUrl(url);
}

export function prefixIcalUrl(url) {
  return prefixAjaxUrl(url, false, true);
}

export function prefixV2Url(url) {
  return prefixAjaxUrl(url, false, false, true);
}

export function logError(error) {
  console.error('Exception in fetch chain', error.message, error);
  Sentry.captureException(error);
}

export function fetchErrorHandler(error, callback) {
  if (error) logError(error);
  if (callback) callback();
  return networkFailure();
}

export function handleInvalidToken(hasAccessToken) {
  return (dispatch, getState) => {
    if (isEmbeddedOrWrapped(getState())) {
      postWebkitMessage(hasAccessToken ? 'invalidToken' : 'noAccessToken', {
        apiUrl: apiUrl(),
        token: getAccessToken()
      });
    } else {
      dispatch({ type: NO_AUTH_TOKEN });
    }
  };
}

export function axiosErrorHandler(error, dispatch, callback) {
  if (error.message === 'Operation canceled by the user.') {
    return;
  }
  if (error.response && error.response.status === 401) {
    dispatch(handleInvalidToken(true));
  } else if (error.response && error.response.status === 498) {
    dispatch(logoutOperator());
  } else {
    dispatch(fetchErrorHandler(error, callback));
  }
}

function checkVersion(response) {
  return (dispatch) => {
    const requiredVersion = response.headers.get('X-ClientoClientVersion');
    const currentVersion = clientBuildNumber();

    if (currentVersion) {
      if (currentVersion !== 'LOCAL' && requiredVersion !== currentVersion) {
        console.warn(`New client version available, refresh required. Current version: ${currentVersion}, new version: ${requiredVersion}`);
        dispatch(versionMismatch(currentVersion, requiredVersion));
      }
    }
  };
}

function checkVersionAxios(headers) {
  return (dispatch) => {
    const requiredVersion = headers['x-clientoclientversion'];
    const currentVersion = clientBuildNumber();

    if (currentVersion) {
      if (currentVersion !== 'LOCAL' && requiredVersion != currentVersion) {
        console.warn(`New client version available, refresh required. Current version: ${currentVersion}, new version: ${requiredVersion}`);
        dispatch(versionMismatch(currentVersion, requiredVersion));
      }
    }
  };
}

function checkAccountStatusAxios(headers) {
  return (dispatch) => {
    const features = headers['x-cliento-features'];
    const trialUntil = headers['x-cliento-trial-until'];
    const trialStatus = headers['x-cliento-trial-status']; // Tri/al, TrialExpired
    const accountStatus = headers['x-cliento-account-status']; // Active, ActivePaymentRequired, BlockedPaymentRequired, BlockedManual, Cancelled

    if (accountStatus) {
      const trialUntilMoment = trialUntil ? moment(trialUntil, 'YYYY-MM-DDTHH:mm:ssZ') : null;
      dispatch(changeAccountStatus(accountStatus, trialStatus, trialUntilMoment, features));
    }
  };
}

function handleResponseError(response) {
  return (dispatch) => {
    if (response.status === 401) {
      dispatch(handleInvalidToken(true));
    } else if (response.status === 498) {
      dispatch(logoutOperator());
    } else {
      const error = new Error(response.status);
      error.message = `Network request failed", type: ${response.type} status: ${response.status}, message: ${response.statusText}`;
      error.response = response;
      throw error;
    }
  };
}

export function checkStatusAxios(response) {
  return (dispatch) => {
    dispatch(checkAccountStatusAxios(response.headers));
    dispatch(checkVersionAxios(response.headers));

    if (response.status >= 200 && response.status < 300) {
      return response;
    }
    dispatch(handleResponseError(response));
    return Promise.reject();
  };
}

function checkAccountStatus(response) {
  return (dispatch) => {
    const features = response.headers.get('X-Cliento-Features');
    const trialUntil = response.headers.get('X-Cliento-Trial-Until');
    const trialStatus = response.headers.get('X-Cliento-Trial-Status'); // Trial, TrialExpired
    const accountStatus = response.headers.get('X-Cliento-Account-Status'); // Active, ActivePaymentRequired, BlockedPaymentRequired, BlockedManual, Cancelled

    if (accountStatus) {
      const trialUntilMoment = trialUntil ? moment(trialUntil, 'YYYY-MM-DDTHH:mm:ssZ') : null;
      dispatch(changeAccountStatus(accountStatus, trialStatus, trialUntilMoment, features));
    }
  };
}

export function checkStatus(response) {
  return (dispatch) => {
    dispatch(checkAccountStatus(response));
    dispatch(checkVersion(response));

    if (response.status >= 200 && response.status < 300) {
      return response;
    }
    dispatch(handleResponseError(response));
    return Promise.reject();
  };
}

function withDefaultParams(method, body, accept, contentType) {
  return {
    headers: getDefaultHeaders(accept, contentType),
    mode: 'cors',
    cache: 'default',
    credentials: 'include',
    method,
    ...body
  };
}

function encodeFormData(obj) {
  const formData = [];
  for (const key in obj) {
    formData.push(`${key}=${encodeURIComponent(obj[key])}`);
  }
  return formData.join('&');
}

function jsonRequest(method, body) {
  const json = body !== undefined ? { body: JSON.stringify(body) } : {};
  return withDefaultParams(method, json, 'application/json', 'application/json');
}

function formRequest(method, body) {
  const form = body !== undefined ? { body: encodeFormData(body) } : {};
  return withDefaultParams(method, form, '*/*', 'application/x-www-form-urlencoded');
}

export function fetchDelete() {
  return jsonRequest('DELETE');
}

export function fetchPut(body) {
  return jsonRequest('PUT', body);
}

export function fetchPatch(body) {
  return jsonRequest('PATCH', body);
}

export function fetchPost(body) {
  return jsonRequest('POST', body);
}

export function fetchPostForm(body) {
  return formRequest('POST', body);
}

export function fetchGet() {
  return jsonRequest('GET');
}

function getDefaultHeaders(accept, contentType) {
  const accessToken = localStorage.getItem('accessToken') || '';
  const operatorToken = getSessionOpToken() || '';
  const pusherSocketId = window.pusherSocketId || '';

  const headers = {
    Authorization: `Bearer ${accessToken}`,
    Accept: accept || 'application/json',
    'Content-Type': contentType || 'application/json'
  };
  if (pusherSocketId) {
    headers['X-Client-Id'] = pusherSocketId;
  }
  if (operatorToken) {
    headers['X-ClientoOpToken'] = operatorToken;
  }
  return headers;
}

export function axiosDefault(props = {}) {
  return {
    ...props,
    withCredentials: true,
    headers: getDefaultHeaders()
  };
}

export function axiosFormData() {
  return {
    withCredentials: true,
    headers: getDefaultHeaders(null, 'multipart/form-data')
  };
}

function axiosRequest(requestMethod, options = {}) {
  const { onSuccess = null, onError = null, throwOnError = false } = options;
  const config = axiosDefault();
  return (dispatch) => {
    return requestMethod(config)
      .then(res => dispatch(checkStatusAxios(res)))
      .then(res => {
        if (onSuccess) {
          return onSuccess(res);
        }
      })
      .catch(error => {
        axiosErrorHandler(error, dispatch, onError);
        if (throwOnError) {
          throw error;
        }
      });
  };
}

export function axiosGet(url, options) {
  const requestMethod = config => axios.get(url, config);
  return axiosRequest(requestMethod, options);
}

export function axiosPost(url, data, options) {
  const requestMethod = config => axios.post(url, data, config);
  return axiosRequest(requestMethod, options);
}

export function axiosPut(url, data, options) {
  const requestMethod = config => axios.put(url, data, config);
  return axiosRequest(requestMethod, options);
}

export function axiosPatch(url, data, options) {
  const requestMethod = config => axios.patch(url, data, config);
  return axiosRequest(requestMethod, options);
}

export function axiosDelete(url, options) {
  const requestMethod = config => axios.delete(url, config);
  return axiosRequest(requestMethod, options);
}

export function isNetworkError(error) {
  return !error.response
    && Boolean(error.code) // Prevents retrying cancelled requests
    && isRetryAllowed(error); // Prevents retrying unsafe errors
}

const SAFE_HTTP_METHODS = ['get', 'head', 'options'];
const IDEMPOTENT_HTTP_METHODS = SAFE_HTTP_METHODS.concat(['put', 'delete']);

export function isRetryableError(error) {
  return (!error.response || (error.response.status >= 501 && error.response.status <= 599));
}

export function isSafeRequestError(error) {
  if (!error.config) {
    // Cannot determine if the request can be retried
    return false;
  }

  return isRetryableError(error) && SAFE_HTTP_METHODS.indexOf(error.config.method) !== -1;
}

export function isIdempotentRequestError(error) {
  if (!error.config) {
    // Cannot determine if the request can be retried
    return false;
  }

  return isRetryableError(error) && IDEMPOTENT_HTTP_METHODS.indexOf(error.config.method) !== -1;
}

export function isNetworkOrIdempotentRequestError(error) {
  return isNetworkError(error) || isIdempotentRequestError(error);
}

export function isNetworkOrSafeRequestError(error) {
  return isNetworkError(error) || isSafeRequestError(error);
}
