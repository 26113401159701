import React from 'react';
import { useDispatch } from 'react-redux';
import { formatPhoneNumber } from '@Utils/phone-util';
import { setCustomerFormState } from '@State/cf-actions';
import { changeBookingStatus } from '@State/booking-actions';
import { setBKFCustomerStatus } from '@State/bkf/actions';

const BookingClassCustomer = ({ bookingId, customer, selected, onSelect }) => {
  const dispatch = useDispatch();
  const { name, officialIdNo, phoneNumber, otherPhoneNumber, status } = customer;
  const isPending = status === 'Pending';
  const isCancelled = status === 'Cancelled';
  const isLocked = isPending || isCancelled;
  const phoneNumbers = [phoneNumber, otherPhoneNumber].filter(p => p)
    .map(p => formatPhoneNumber(p));

  const onOpenCustomer = () => {
    dispatch(setCustomerFormState({
      formVisible: true,
      customer
    }));
  };

  const onSetShow = () => {
    const { customerId } = customer;
    const newStatus = status === 'Show' ? 'Booked' : 'Show';
    dispatch(setBKFCustomerStatus(customerId, newStatus));
    dispatch(changeBookingStatus({
      bookingId,
      customerIds: [customerId],
      status: newStatus
    }));
  };

  const onSetNoShow = () => {
    const { customerId } = customer;
    const newStatus = status === 'NoShow' ? 'Booked' : 'NoShow';
    dispatch(setBKFCustomerStatus(customerId, newStatus));
    dispatch(changeBookingStatus({
      bookingId,
      customerIds: [customerId],
      status: newStatus
    }));
  };

  const showClasses = `btn-label ${status === 'Show' ? 'label-status-show' : ''}`;
  const noShowClasses = `btn-label ${status === 'NoShow' ? 'label-status-no-show' : ''}`;

  return (
    <div className="booking-class-customer" onClick={isLocked ? null : onSelect}>
      {!isLocked && (
        <div className="buttons" onClick={ev => ev.stopPropagation()}>
          <button className="btn-label" tabIndex={-1} onClick={onOpenCustomer}>Öppna</button>
          <button className={showClasses} tabIndex={-1} onClick={onSetShow}>Show</button>
          <button className={noShowClasses} tabIndex={-1} onClick={onSetNoShow}>No show</button>
        </div>
      )}
      <div className={isCancelled ? 'content cancelled' : 'content'}>
        <aside><input type="checkbox" checked={selected} onChange={onSelect} disabled={isLocked} /></aside>
        {isPending ? (
          <section>
            <h5>Pågående onlinebokning</h5>
          </section>
        ) : (
          <section>
            <h5>{name}</h5>
          </section>
        )}
      </div>
    </div>
  );
};

export default BookingClassCustomer;
