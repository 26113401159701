import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import usePrevious from '@Hooks/usePrevious';
import NoContent from '@Components/ui/no-content';
import { ReportTitle, ReportTitleButtons } from '@Components/pos/style';
import PopoverDeleteButton from '@Components/ui/popover-delete-button';
import { cancelCampaign, deleteCampaign } from '@State/campaign-actions';
import { colors } from '@Components/ui/styled/variables';
import CurrencyUtil from '@Utils/currency-util';
import { Button, Flex, Panel } from '@Components/ui/styled/main';
import AlertWithIcon from '@Components/ui/alert-with-icon';
import DialogAlert from '@Components/dialogs/dialog-alert';
import Label from '@Components/ui/label';
import {
  campaignHasContent, getAudienceTypeName, getCampaignStatusIcon,
  getCampaignStatusLabel, getCampaignTitle, getCampaignTypeName,
  getDateFilterTypeName, getResourceFilterName
} from './campaign-helpers';
import CampaignStatusUpdate from './campaign-status-update';
import CampaignRecipientsModal from './campaign-recipients-modal';
import CampaignContentPanel from './campaign-content-panel';
import CampaignModal from './form/campaign-modal';
import LabelTextGroup from './label-text-group';

const getItemNameString = (ids, map) => (
  ids.map(id => map?.get(id)?.name || 'Borttagen').filter(x => x).join(', ')
);

const CampaignOverview = ({ routeParams }) => {
  const dispatch = useDispatch();
  const { id: routeId } = useParams();
  const campaign = useSelector(state => state?.campaignViewState?.get('campaigns')?.find(it => +it.get('id') === +routeId));
  const resourcesById = useSelector(state => state.resourcesById);
  const servicesById = useSelector(state => state.servicesById);

  const previousId = usePrevious(campaign?.get('id'));
  const previousTime = usePrevious(campaign?.get('deliveryTime'));
  const [campaignScheduled, setCampaignScheduled] = useState(false);
  const [showModalStep, setShowModalStep] = useState(null);
  const [showRecipients, setShowRecipients] = useState(false);

  useEffect(() => {
    const isSame = campaign?.get('id') === previousId;
    if (isSame && !previousTime && campaign?.get('deliveryTime')) {
      setCampaignScheduled(true);
    }
  }, [campaign]);

  const deleteCampaignCallback = useCallback(() => {
    return dispatch(deleteCampaign(routeId));
  }, [routeId]);

  const cancelCampaignCallback = useCallback(() => {
    return dispatch(cancelCampaign(routeId));
  }, [routeId]);

  const {
    id, name, deliveryTime, recipientCount, status, type, searchParams = {}
  } = campaign?.toJS() || {};
  const {
    audienceType, dateFilterType, fromDate, toDate, cost,
    resourceIds, resourceFilterType, serviceIds
  } = searchParams;

  const isSent = status === 'DONE';
  const isDraft = status === 'DRAFT';
  const isProcessing = status === 'PROCESSING';
  const isCancelled = status?.includes('CANCELLED');
  const notCompleted = !isSent && !isCancelled;
  const isEditable = notCompleted && !deliveryTime;
  const iconClass = getCampaignStatusIcon(status);
  const statusLabel = getCampaignStatusLabel(status);
  const date = deliveryTime && moment(deliveryTime).format('LLLL');
  const hasContent = campaign ? campaignHasContent(campaign.toJS()) : false;
  const hasRecipients = campaign && campaign.get('recipientCount') > 0;
  const checkStatus = notCompleted && deliveryTime && moment(deliveryTime).isBefore(moment());

  return (
    <>
      {!campaign ? (
        <div className="text-center">
          <NoContent icon="fa fa-paper-plane">
            Få fler bokningar, gör ditt första kampanjutskick idag!<br />
            Klicka på Nytt utskick för att komma igång.
          </NoContent>
        </div>
      ) : (
        <div>
          <ReportTitle>
            <div>
              <h3 className="mt0 mb2">
                {name || getCampaignTitle(campaign.toJS(), 40)}
              </h3>
              <Flex vCenter>
                <Label bgColor={type === 'Sms' ? colors.labelGreen : colors.labelTurquoise} className="mr1 mt0">
                  {getCampaignTypeName(type)}
                </Label>&nbsp;
                {!isDraft ? (
                  <span>
                    <i className={`${iconClass} mr1`} />
                    {statusLabel} {!isProcessing && date}
                  </span>
                ) : <span className="text-muted"><i>Utkast</i></span>}
              </Flex>
              {checkStatus && (
                <CampaignStatusUpdate campaignId={id} status={status} />
              )}
            </div>
            <ReportTitleButtons className="hidden-print">
              {isEditable && (
                <Button small primary disabled={!hasContent || !hasRecipients} onClick={() => setShowModalStep(3)}>
                  Skicka/schemalägg
                </Button>
              )}
              {isEditable && (
                <PopoverDeleteButton marginLeft onDelete={deleteCampaignCallback} />
              )}
              {notCompleted && deliveryTime && (
                <PopoverDeleteButton
                  onDelete={cancelCampaignCallback}
                  buttonText="Avbryt"
                  confirmText="Vill du avbryta utskicket?"
                  cancelText="Nej"
                />
              )}
            </ReportTitleButtons>
          </ReportTitle><br />
          {notCompleted && !hasContent && (
            <AlertWithIcon
              warning
              icon="fa fa-exclamation-triangle"
              button={(
                <Button small gray onClick={() => setShowModalStep(2)}>Lägg till innehåll</Button>
              )}
            >
              Du måste lägga till innehåll innan du kan skicka meddelandet.
            </AlertWithIcon>
          )}
          {audienceType && (
            <Panel>
              <div className="pull-right">
                {hasRecipients && (
                  <Button tiny white onClick={() => setShowRecipients(true)}>
                    Visa mottagare
                  </Button>
                )}
                {isEditable && (
                  <Button tiny white marginLeft onClick={() => setShowModalStep(1)}>Ändra</Button>
                )}
              </div>
              <LabelTextGroup label="Mottagare">
                {`${getAudienceTypeName(audienceType)} (${recipientCount} st)`}
              </LabelTextGroup>
              {fromDate && (
                <LabelTextGroup label={getDateFilterTypeName(dateFilterType) || 'Datum'}>
                  {`${moment(fromDate).format('L')} - ${moment(toDate).format('L')}`}
                </LabelTextGroup>
              )}
              {cost && (
                <LabelTextGroup label="Spenderat mer än">
                  {CurrencyUtil.accountCurrency(cost)}
                </LabelTextGroup>
              )}
              {resourceIds && (
                <LabelTextGroup label={getResourceFilterName(resourceFilterType) || 'Resurser'}>
                  {getItemNameString(resourceIds, resourcesById)}
                </LabelTextGroup>
              )}
              {serviceIds && (
                <LabelTextGroup label="Bokat tjänst">
                  {getItemNameString(serviceIds, servicesById)}
                </LabelTextGroup>
              )}
            </Panel>
          )}
          {hasContent && (
            <>
              <CampaignContentPanel campaign={campaign}>
                <div className="pull-right">
                  {isEditable && (
                    <Button tiny gray onClick={() => setShowModalStep(2)}>Ändra</Button>
                  )}
                </div>
              </CampaignContentPanel>
            </>
          )}
        </div>
      )}
      {campaignScheduled && (
        <DialogAlert
          success
          icon="fa fa-circle-check"
          text="Ditt utskick är nu schemalagt"
          onClose={() => setCampaignScheduled(false)}
        />
      )}
      {showModalStep && campaign && (
        <CampaignModal
          campaign={campaign}
          initialStep={showModalStep}
          onClose={() => setShowModalStep(null)}
          routeParams={routeParams}
        />
      )}
      {showRecipients && (
        <CampaignRecipientsModal
          type={type}
          campaignId={id}
          onClose={() => setShowRecipients(false)}
          showStatus={deliveryTime}
        />
      )}
    </>
  );
};

export default CampaignOverview;
