import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleResourceLocationSchedule } from '@State/schedule-actions';
import { fetchGroupsAndResources } from '@State/resource-actions';
import ScheduleContent from '@Components/schedule/schedule-content';
import ScheduleResourceList from '@Components/schedule/schedule-resource-list';
import ScheduleSubNav from '@Components/schedule/schedule-subnav';
import Loader from '@Components/ui/loader';
import Button from '@Components/ui/button';
import ConfirmPopover from '@Components/ui/confirm-popover';

const Schedules = ({ match }) => {
  const resourceId = parseInt(match.params.id);
  const selectedResource = useSelector(state => state.resourcesById.get(resourceId));
  const useLocationSchedule = useSelector(state => state.adminSchedules.get('useLocationSchedule'));
  const [isShowConfirmation, setIsShowConfirmation] = useState(false);

  const routeParams = {
    ...match.params,
    entityId: resourceId
  };
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(fetchGroupsAndResources())
      .then(() => setLoading(false));
  }, []);

  const toggleSchedule = () => {
    hideConfirmation();
    dispatch(toggleResourceLocationSchedule(resourceId, !useLocationSchedule));
  };

  const hideConfirmation = () => setIsShowConfirmation(false);
  const showConfirmation = () => setIsShowConfirmation(true);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="columns-container">
      <div className="columns-sidebar">
        <div className="columns-header">
          <h1>Scheman</h1>
        </div>
        <ScheduleResourceList routeParams={routeParams} />
      </div>
      <div className="columns-content">
        <div className="columns-content-container">
          <div className="columns-content-body">
            <div className="columns-content-header">
              {selectedResource && !useLocationSchedule && (
                <div className="pull-right">
                  <ConfirmPopover
                    text="Alla dina scheman kommer att raderas. Vill du fortsätta?"
                    confirmText="Stäng av"
                    onClose={hideConfirmation}
                    onConfirm={toggleSchedule}
                    isOpen={isShowConfirmation}
                  >
                    <Button small gray onClick={showConfirmation}>Stäng av individuellt schema</Button>
                  </ConfirmPopover>
                </div>
              )}
              <h3>{selectedResource ? selectedResource.name : 'Huvudschema'}</h3>
            </div>
            {!isNaN(resourceId) && <ScheduleSubNav routeParams={routeParams} />}
            <ScheduleContent routeParams={routeParams} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Schedules;
