import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { importGiftCards } from '@State/gift-card-actions';
import { fetchCustomerGiftCards } from '@State/customer-actions';
import GiftCardModal from '@Components/admin/pos-config/gift-cards/gift-card-modal';
import ModalDialog from '@Components/dialogs/modal-dialog';
import Button from '@Components/ui/button';

import { txt } from '@Utils/i18n-util';
import msg from './customer-gift-cards.msg';

const CustomerAddGiftCard = ({ customerId, onAdded }) => {
  const dispatch = useDispatch();
  const posOrgs = useSelector(state => state.posOrgs);

  const [posOrgId, setPosOrgId] = useState(null);
  const [showAddGiftCard, setShowAddGiftCard] = useState(false);

  useEffect(() => {
    if (!posOrgId && posOrgs.size === 1) {
      setPosOrgId(posOrgs.first().get('id'));
    }
  }, [posOrgs, posOrgId]);

  const onClose = () => {
    setPosOrgId(null);
    setShowAddGiftCard(false);
  };

  const onSubmit = (giftCards) => {
    return dispatch(importGiftCards(posOrgId, giftCards.map(g => ({ ...g, customerId }))))
      .then(({ added }) => {
        const addedIds = Object.values(added);
        dispatch(fetchCustomerGiftCards(customerId))
          .then(() => addedIds.length > 0 && onAdded(addedIds[0]));
      });
  };

  if (posOrgs.isEmpty()) {
    return null;
  }

  const posOrg = posOrgs.find(p => p.get('id') === posOrgId);

  return (
    <>
      <Button small gray onClick={() => setShowAddGiftCard(true)}>
        {txt(msg.btnImportGiftCard)}
      </Button>
      {showAddGiftCard && posOrgs.size > 1 && !posOrgId && (
        <ModalDialog
          contentSize="small"
          title="Välj företag"
          closeOnClickOutside={false}
          onClose={onClose}
          buttons={[
            ...posOrgs.map((posOrg) => ({
              gray: true,
              name: posOrg.get('companyName'),
              onClick: () => setPosOrgId(posOrg.get('id'))
            }))
          ]}
        />
      )}
      {showAddGiftCard && posOrg && (
        <GiftCardModal
          posOrgPrefs={posOrg.get('prefs')?.toJS()}
          onSubmit={onSubmit}
          onClose={onClose}
        />
      )}
    </>
  );
};

export default CustomerAddGiftCard;
